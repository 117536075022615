import Vue from 'vue'
import VueRouter from 'vue-router'
import SysUserList from '../views/system/user/SysUserList'
import sysdistribution from '../views/system/user/sysdistribution'
import DataDictionaries from '../views/system/dictionaries/DataDictionaries'
import addDic from '../views/system/dictionaries/addDic'
import addDictionaries from '../views/system/dictionaries/addDictionaries'
import Config from '../views/system/dictionaries/Config'
import sysuserDetails from '../views/system/user/sysuserDetails'
import SysRoleList from '../views/system/role/SysRoleList'
import addSysRoleList from '../views/system/role/addSysRoleList'
import editMenu from '../views/system/menu/editMenu'
import SysMenu from '../views/system/menu/SysMenu'
import menutree from '../views/menutree'
import login from '../views/system/home/login'
import groupManage from '../views/system/department/groupManage'
import SysAuditLogList from '../views/system/home/SysAuditLogList'
import SysPermission from '../views/system/permission/SysPermission'
import addUser from '../views/system/role/addUser'
import organization from '../views/system/organization/organization'
import channel from '../views/system/channel/channel'
import about from '../views/system/home/about'
import supplierManagementList from '../views/preloanmanagement/suppliermanagement/supplierManagementList'
import supplierManagementDetails from '../views/preloanmanagement/suppliermanagement/supplierManagementDetails'
import SysFundFlow from '../views/system/fund/SysFundFlow'
import SysFundAccount from '../views/system/fund/SysFundAccount'
import Withdrawal from '../views/system/fund/Withdrawal'
import WithdrawalSetting from '../views/system/fund/WithdrawalSetting'
import Detailed from '../views/system/fund/Detailed'
import orderpayment from '../views/system/fund/orderpayment'
import loanTrackingList from '../views/postloanmanagement/loantracking/loanTrackingList'
import loanTrackingDetails from '../views/postloanmanagement/loantracking/loanTrackingDetails'
import platformRepaymentList from '../views/postloanmanagement/loantracking/platformrepayment/platformRepaymentList'
import platformRepaymentDetails from '../views/postloanmanagement/loantracking/platformrepayment/platformRepaymentDetails'
import loanList from '../views/postloanmanagement/loanlist/loanList'
import loanListDetails from '../views/postloanmanagement/loanlist/loanListDetails'
import preliminaryReviewOfLoansList from '../views/postloanmanagement/preliminaryreviewofloans/preliminaryReviewOfLoansList'
import preliminaryReviewOfLoansDetails from '../views/postloanmanagement/preliminaryreviewofloans/preliminaryReviewOfLoansDetails'
import loanReviewList from '../views/postloanmanagement/loanreview/loanReviewList'
import loanReviewDetails from '../views/postloanmanagement/loanreview/loanReviewDetails'
import loanProcessingList from '../views/postloanmanagement/loanprocessing/loanProcessingList'
import loanProcessingDetails from '../views/postloanmanagement/loanprocessing/loanProcessingDetails'
import productManagementList from '../views/preloanmanagement/productmanagement/productManagementList'
import productManagementDetails from '../views/preloanmanagement/productmanagement/productManagementDetails'
import addProduct from '../views/preloanmanagement/productmanagement/addProduct'
import Setting from '../views/preloanmanagement/suppliermanagement/Setting';
import invoiceFindList from '../views/invoiceAdministration/index'
import invoiceDetails from '../views/invoiceAdministration/invoiceDetails'
import updateInvoice from '../views/invoiceAdministration/updateInvoice'
import platformPayList from '../views/platformPay/platformPayList'
import whiteList from '../views/preloanmanagement/whitelist/whiteList'
import whiteListDetails from '../views/preloanmanagement/whitelist/whiteListDetails'

import eliminate from '../views/system/eliminate/index'

import orderList from '../views/order/orderList'
import orderDetail from '../views/order/orderDetail'
import saveOrder from '../views/order/saveOrder'
import taskList from '../views/task/taskList'
import taskDetail from '../views/task/taskDetail'
import statementBillList from '../views/statementBill/statementBillList'
import statementBillDetail from '../views/statementBill/statementBillDetail'

import quotaRefresh from '../views/others/quotaRefresh/quotaRefresh'

Vue.use(VueRouter)
	//redirect : '/login/17744230bb704e05bde6a8b4fccc42da'
  const routes = [
    {
      path : '/',
      redirect : '/login/'
    },
    {
      path: `/login/:tenantId`,
      name: 'login',
      component: login
    },
    {
      path: '/menutree',
      name: 'menutree',
      component: menutree,
      redirect : '/SysAuditLogList',
      children : [
        { path:'/SysUserList' , component : SysUserList},
        { path:'/SysRoleList' , component : SysRoleList},
        { path:'/SysMenu' , component : SysMenu},
        { path:'/GroupManage' , component : groupManage},
        { path:'/SysAuditLogList' , component : SysAuditLogList},
        { path:'/SysPermission' , component : SysPermission},
        { path:'/DataDictionaries' , component : DataDictionaries},
        { path:'/AddDic' , component : addDic},
        { path:'/AddDictionaries' , component : addDictionaries},
        { path:'/Config' , component : Config},
        { path:'/Organization' , component : organization},
        { path:'/Channel' , component : channel},
        { path:'/supplierManagementList' , component : supplierManagementList},
        { path:'/SysFundFlow' , component : SysFundFlow},
        { path:'/SysFundAccount' , component : SysFundAccount},
        { path:'/loanTrackingList' ,name:"loanTrackingList", component : loanTrackingList},
        { path:'/loanTrackingDetails', name:"loanTrackingDetails" , component : loanTrackingDetails},
        { path:'/platformRepaymentList' , component : platformRepaymentList},
        {path: '/supplierManagementDetails',name: 'supplierManagementDetails',component: supplierManagementDetails},
        {path: '/Setting',name: 'Setting',component: Setting},
        { path:'/platformRepaymentDetails' ,name:"platformRepaymentDetails", component : platformRepaymentDetails},
        { path:'/loanList' , component : loanList},
        { path:'/loanListDetails' ,name:"loanListDetails", component : loanListDetails},
        { path:'/preliminaryReviewOfLoansList' ,name:"preliminaryReviewOfLoansList", component : preliminaryReviewOfLoansList},
        { path:'/preliminaryReviewOfLoansDetails' ,name:"preliminaryReviewOfLoansDetails", component : preliminaryReviewOfLoansDetails},
        { path:'/loanReviewList' ,name:"loanReviewList", component : loanReviewList},
        { path:'/loanReviewDetails' ,name:"loanReviewDetails", component : loanReviewDetails},
        { path:'/loanProcessingList' ,name:"loanProcessingList", component : loanProcessingList},
        { path:'/loanProcessingDetails' ,name:"loanProcessingDetails", component : loanProcessingDetails},
        { path: '/Detailed/:id',name: 'Detailed',component: Detailed},
        { path: '/orderpayment/:id',name: 'orderpayment',component: orderpayment},
        { path:'/productManagementList' ,name:"productManagementList", component : productManagementList},
        { path:'/productManagementDetails' ,name:"productManagementDetails", component : productManagementDetails},
        { path:'/addProduct' ,name:"addProduct", component : addProduct},
         { path: '/invoiceFindList', name: 'invoiceFindList', component: invoiceFindList },
      { path: '/invoiceDetails', name: 'invoiceDetails', component: invoiceDetails },
      { path: '/updateInvoice', name: 'updateInvoice', component: updateInvoice },
      { path: '/whiteList', name: 'whiteList', component: whiteList },
      { path: '/whiteListDetails', name: 'whiteListDetails', component: whiteListDetails },
      { path: '/platformPayList', name: 'platformPayList', component: platformPayList },
      { path: '/eliminate', name: 'eliminate', component: eliminate },
      { path: '/paymentPlanList', name: 'paymentPlanList', component: () => import(/* 应付帐查询 */ '../views/paymentPlan/') },
      { path: '/paymentPlanParticulars', name: 'paymentPlanParticulars', component: () => import(/* 应付帐查询 */ '../views/paymentPlan/paymentPlanParticulars') },
      { path: '/tenantList',name: 'tenantList',component: () => import(/* 租户列表 */ '../views/system/tenant/index.vue')},
      { path: '/addOrUpdateTenant/:type',name: 'addOrUpdateTenant',component: () => import(/* 添加修改租户 */ '../views/system/tenant/addOrUpdate.vue')},
      { path: '/orderList', name: 'orderList', component :orderList},
      { path: '/saveOrder', name: 'saveOrder', component :saveOrder},
      { path: '/orderDetail', name: 'orderDetail', component :orderDetail},
      { path: '/taskList', name: 'taskList', component :taskList},
      { path: '/taskDetail', name: 'taskDetail', component :taskDetail},
      { path: '/statementBillList', name: 'statementBillList', component :statementBillList},
      { path: '/statementBillDetail', name: 'statementBillDetail', component :statementBillDetail},
      {path: '/quotaRefresh',name: 'quotaRefresh',component: quotaRefresh},
      // 系统看板
      { path: '/systemBoard',name: 'systemBoard',component: () => import(/* 系统看板 */ '../views/system/systemBoard/index.vue')},
      ]
    },
    {path: '/DataDictionaries',name: 'DataDictionaries',component: DataDictionaries},
    {path: '/addDic',name: 'addDic',component: addDic},
    {path: '/addDictionaries',name: 'addDictionaries',component: addDictionaries},
    {path: '/Config',name: 'Config',component: Config},
    {path: '/sysdistribution',name: 'sysdistribution',component: sysdistribution},
     {path: '/sysuserDetails',name: 'sysuserDetails',component: sysuserDetails},
    {path: '/addUser',name: 'addUser',component: addUser},
    {path: '/SysPermission',name: 'SysPermission',component: SysPermission},
    {path: '/SysRoleList',name: 'SysRoleList',component: SysRoleList},
    {path: '/groupManage',name: 'groupManage',component: groupManage},
    {path: '/SysUserList',name: 'SysUserList',component: SysUserList,},
    {path: '/addSysRoleList',name: 'addSysRoleList',component: addSysRoleList},
    {path: '/editMenu',name: 'editMenu',component: editMenu},
    {path: '/SysMenu',name: 'SysMenu',component: SysMenu},
    {path: '/SysAuditLogList',name: 'SysAuditLogList',component: SysAuditLogList},
    {path: '/organization',name: 'organization',component: organization},
    {path: '/about',name: 'about',component: about},
    {path: '/supplierManagementList',name: 'supplierManagementList',component: supplierManagementList},
    
    {path: '/Withdrawal',name: 'Withdrawal',component: Withdrawal},
    {path: '/WithdrawalSetting',name: 'WithdrawalSetting',component: WithdrawalSetting},
    {path: '/Detailed/:id',name: 'Detailed',component: Detailed},
    {path: '/orderpayment/:id',name: 'orderpayment',component: orderpayment},
    {path: '/loanTrackingList',name: 'loanTrackingList',component: loanTrackingList},
    // {path: '/loanTrackingDetails',name: 'loanTrackingDetails',component: loanTrackingDetails},
    {path: '/platformRepaymentList',name: 'platformRepaymentList',component: platformRepaymentList},
    { path: '/invoiceFindList', name: 'invoiceFindList', component: invoiceFindList },
  { path: '/invoiceDetails', name: 'invoiceDetails', component: invoiceDetails },
  { path: '/updateInvoice', name: 'updateInvoice', component: updateInvoice },
  { path: '/platformPayList', name: 'platformPayList', component: platformPayList },
]

const router = new VueRouter({
  routes
})



export default router
