<template>
  <div id="addProduct">
    <div id="button">
      <el-row>
        <el-col :span="1">
          <el-button type="primary" align="left" icon="el-icon-plus" class="button_size"
            >产品信息</el-button
          >
        </el-col>
      </el-row>
    </div>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>基本信息</span>
        </el-col>
      </el-row>
      <el-divider class="el-divider"></el-divider>
    </div>

    <div id="essentialInformation">
      <el-form
        ref="essentialInformationForm"
        :model="essentialInformationForm"
        label-position="right"
        label-width="auto"
      >
        <el-row>
          <el-col :span="10">
            <el-form-item label="产品代码" label-width="208px" required>
              <el-input
                v-model="essentialInformationForm.code"
                style="width: 60%"
                
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="产品名称" label-width="208px" required>
              <el-input
                v-model="essentialInformationForm.name"
                style="width: 60%"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="产品资金方" label-width="208px" required>
              <el-checkbox-group v-model="essentialInformationForm.fundSide">
                <el-checkbox label="0" name="fundSide">自有资金</el-checkbox>
                <el-checkbox label="1" name="fundSide" disabled
                  >托管资金</el-checkbox
                >
                <el-checkbox label="2" name="fundSide" disabled
                  >第三方资金</el-checkbox
                >
              </el-checkbox-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="产品详述" label-width="208px">
              <el-input
                v-model="essentialInformationForm.detail"
                type="textarea"
                rows="2"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="10">
            <el-form-item label="生效日期" label-width="208px" required>
              <el-date-picker
                v-model="essentialInformationForm.effectiveDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="失效日期" label-width="208px" required>
              <el-date-picker
                v-model="essentialInformationForm.invalidDate"
                type="date"
                placeholder="选择日期"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div class="textTips">
      <el-row>
        <el-col :span="4" align="left">
          <span>业务相关</span>
        </el-col>
      </el-row>
      <el-divider class="el-divider"></el-divider>
    </div>

    <el-form
      ref="businessRelatedForm"
      :model="businessRelatedForm"
      label-position="right"
      label-width="auto"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item label="贷款期限" required>
            <el-checkbox-group
              v-model="businessRelatedForm.loanTerm.monthTerm"
              @change="hh"
            >
              <div
                v-for="i in businessRelatedForm.repaymentInterval * 12"
                :key="i"
                style="display: inline"
              >
                <el-checkbox
                  v-if="i % businessRelatedForm.repaymentInterval == 0"
                  :label="i"
                  :key="i"
                  name="monthTerm"
                  @change="monthly"
                  >{{ i }}个月</el-checkbox
                >
              </div>
              <el-checkbox :label="0" name="monthTerm" @change="byDay"
                >按天</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="还款间隔" required>
            <el-select
              v-model="businessRelatedForm.repaymentInterval"
              @change="repaymentIntervalChange"
              :disabled="businessRelatedFormRepaymentIntervalDisabled"
            >
              <el-option value="1" label="一个月">一个月</el-option>
              <el-option value="3" label="三个月">三个月</el-option>
              <el-option value="6" label="六个月">六个月</el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="宽限期天数" required>
            <el-select
              v-model="businessRelatedForm.graceTermDays"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in daysList"
                :key="item.value"
                :label="item.value + '天'"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="利率模式" required>
            <el-select
              v-model="businessRelatedForm.interestRateType"
              filterable
              placeholder="请选择"
            >
              <el-option
                  v-for="item in interestRateTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.datavalue"
                ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="罚息模式" required>
            <el-select
              v-model="businessRelatedForm.penaltyInterestType"
              filterable
              placeholder="请选择"
            >
              <el-option
                  v-for="item in penaltyInterestTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.datavalue"
                ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="手续费模式" required>
            <el-select
              v-model="businessRelatedForm.serviceChargeType"
              filterable
              placeholder="请选择"
            >
             <el-option
                  v-for="item in serviceChargeTypeList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.datavalue"
                ></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="授信额度占发票税前总金额比例" required>
            <el-input
              v-model.number="businessRelatedForm.invoiceOccupyRate"
              class="proportionOfTotalAmount_input"
              :disabled="invoiceOccupyRateDisabled"
            ></el-input>
            <span> %</span>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- <el-row>
          <el-col :span="6">
            <el-form-item label="基础授信额度" align="left">
              <el-input
                v-model="businessRelatedForm.initQuota"
                class="proportionOfTotalAmount_input"
              ></el-input>
              <span> 元</span>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="授信额度有效期" align="left">
              <el-input
                v-model="businessRelatedForm.creditValidDay"
                class="proportionOfTotalAmount_input"
              ></el-input>
              <span> 天</span>
            </el-form-item>
          </el-col>
        </el-row> -->
      <el-row>
        <el-col :span="10">
          <el-form-item label="单笔贷款最大金额" required>
            <el-input
              v-model.number="businessRelatedForm.maxLoanAmount"
              class="proportionOfTotalAmount_input"
            ></el-input>
            <span> 元</span>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="单笔贷款最小金额" required>
            <el-input
              v-model.number="businessRelatedForm.minLoanAmount"
              class="proportionOfTotalAmount_input"
            ></el-input>
            <span> 元</span>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="10">
          <el-form-item label="在贷最大借款次数" required>
            <el-select
              v-model="businessRelatedForm.maxLoanNum"
              filterable
              placeholder="请选择"
            >
              <el-option
                v-for="item in maximumNumberOfLoansOnLoanList"
                :key="item.value"
                :label="item.value + '次'"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="允许提前还款" required>
            <el-radio-group v-model="businessRelatedForm.advanceRepayment">
              <el-radio label="1">是</el-radio>
              <el-radio label="0">否</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div id="contractSetting">
      <div id="button">
        <el-row>
          <el-col :span="1">
            <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
              >合约设置</el-button
            >
          </el-col>
        </el-row>
      </div>

      <div class="textTips">
        <el-divider class="el-divider"></el-divider>
      </div>

      <el-form
        ref="contractSettingForm"
        :model="contractSettingForm"
        label-position="right"
        label-width="auto"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="合约设置" align="left" required>
              <el-input
                v-model="contractSettingForm.contract"
                type="textarea"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <div id="ratingSetting">
      <div id="button">
        <el-row>
          <el-col :span="1">
            <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
              >评级设置</el-button
            >
          </el-col>
        </el-row>
      </div>

      <div class="textTips">
        <el-divider class="el-divider"></el-divider>
      </div>

      <div class="pingji_seting">
        <el-form
          ref="ratingSettingForm"
          :model="ratingSettingForm"
          label-position="right"
          label-width="auto"
        >
          <div
            v-for="(item, index) in ratingSettingForm.inputList"
            :key="index"
          >
            <el-row>
              <el-col :span="4">
                <el-col :span="20">
                  <el-form-item label="评级设置" required>
                    <el-input v-model="item.grade"></el-input>
                  </el-form-item>
                </el-col>
              </el-col>

              <el-col :span="4">
                <el-col :span="20">
                  <el-form-item label="最大授信金额" required>
                    <el-input v-model.number="item.maxCreditAmount"></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span class="span_line_height">元</span>
                </el-col>
              </el-col>

              <el-col :span="4">
                <el-col :span="20">
                  <el-form-item label="授信利率" required>
                    <el-input
                      v-model="item.creditInterestRate"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span class="span_line_height">%</span>
                </el-col>
              </el-col>
              <el-col :span="4">
                <el-col :span="20">
                  <el-form-item label="手续费比例" required>
                    <el-input
                      v-model="item.serviceChargeRate"
                    ></el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span class="span_line_height">%</span>
                </el-col>
              </el-col>
              <el-col :span="4">
                <el-col :span="20">
                <el-form-item label="罚息利率" required>
                  <el-input
                    v-model="item.penaltyInterestRate"     
                  ></el-input>
                </el-form-item>
                </el-col>
                <el-col :span="4">
                  <span class="span_line_height">%</span>
                </el-col>
              </el-col>
              <el-col :span="4" align="center">
                <el-col :span="10">
                  <el-button
                    v-if="index + 1 == ratingSettingForm.inputList.length"
                    @click="addItem"
                    type="primary"
                    >增加</el-button
                  >
                </el-col>
                <el-col :span="10">
                  <el-button
                    @click="deleteItem(item, index)"
                    type="danger"
                    >删除</el-button
                  >
                </el-col>
              </el-col>
            </el-row>
          </div>
        </el-form>
      </div>
    </div>

    <div id="end">
      <el-button @click="cancel">取消</el-button>
      <el-button @click="update" v-if="this.state == 2" type="primary"
        >修改</el-button
      >
      <el-button @click="add" v-if="this.state == 1" type="primary"
        >添加</el-button
      >
    </div>
  </div>
</template>
<script>
import sysdistributionVue from "../../system/user/sysdistribution.vue";
import {accMul} from '../../../util/calculateUtils';
export default {
  data() {
    return {
      invoiceOccupyRateDisabled:false,
      businessRelatedFormRepaymentIntervalDisabled: false,
      gradeSettingIds: [],
      wallet: {
        id: "",
        contract: {
          type: -1,
          body: [],
        },
      },
      creditSetting: {
        id: "",
        blockId: "",
        product: "",
        invoiceOccupyRate: 0,
        groovyCode: "",
        type: -1,
      },
      product: {
        code: "",
        name: "",
        fundSide: [],
        detail: "",
        effectiveDate: "",
        invalidDate: "",
        loanTerm: {
          isDays: false,
          dayTerm: 0,
          monthTerm: [],
        },
        repaymentInterval: 0,
        graceTermDays: 0,
        interestRateType: -1,
        interestRate: 0,
        penaltyInterestType: -1,
        penaltyInterest: 0,
        serviceChargeType: -1,
        serviceChargeRate: 0,
        advanceRepayment: -1,
        initQuota: 0,
        creditValidDay: 0,
        maxLoanAmount: 0,
        minLoanAmount: 0,
        maxLoanNum: 0,
      },
      ratingSettingForm: {
        inputList: [
          {
            grade: "",
            maxCreditAmount: 0,
            creditInterestRate: 0,
            serviceChargeRate: 0,
            penaltyInterestRate: 0,
            creditSettingId: "",
            blockId: "",
            sort: 0,
          },
        ],
      },
      contractSettingForm: {
        id: "",
        contract: "",
      },
      essentialInformationForm: {
        code: "",
        name: "",
        fundSide: [],
        detail: "",
        effectiveDate: "",
        invalidDate: "",
      },
      businessRelatedForm: {
        loanTerm: {
          isDays: false,
          dayTerm: 0,
          monthTerm: [],
        },
        repaymentInterval: 1,
        graceTermDays: "",
        interestRateType: "1",
        penaltyInterestType: "1",
        serviceChargeType: "1",
        initQuota: 0,
        creditValidDay: 0,
        maxLoanAmount: 0,
        minLoanAmount: 0,
        maxLoanNum: 0,
        advanceRepayment: -1,
        days: 0,
        invoiceOccupyRate: 0,
      },
      daysList: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
        {
          value: 6,
        },
        {
          value: 7,
        },
        {
          value: 8,
        },
        {
          value: 9,
        },
        {
          value: 10,
        },
      ],
      maximumNumberOfLoansOnLoanList: [
        {
          value: 1,
        },
        {
          value: 2,
        },
        {
          value: 3,
        },
        {
          value: 4,
        },
        {
          value: 5,
        },
        {
          value: 6,
        },
        {
          value: 7,
        },
        {
          value: 8,
        },
        {
          value: 9,
        },
      ],
       interestRateType:{
        code:"interestRateType"
      },
      interestRateTypeList:[],
      penaltyInterestType:{
        code:"penaltyInterestType"
      },
      penaltyInterestTypeList:[],
      serviceChargeType:{
        code:"serviceChargeType"
      },
      serviceChargeTypeList:[],
      interestRateModelList: [
        {
          value: 1,
          lable: "等本等金",
        },
        {
          value: 2,
          lable: "日利息",
        },
      ],
      penaltyInterestModelList: [
        {
          value: 0,
          lable: "本金罚息",
        },
        {
          value: 1,
          lable: "全额罚息",
        },
      ],
      serviceChargeModeList: [
        {
          value: 0,
          lable: "现付现结",
        },
        {
          value: 1,
          lable: "现付后结",
        },
      ],
      state: 0,
    };
  },
  mounted: function () {
     this.interestRateTypes(this.interestRateType);
    this.penaltyInterestTypes(this.penaltyInterestType);
    this.serviceChargeTypes(this.serviceChargeType);
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收参数", param);
    this.productInfo(param.productId);
    this.findWalletById();
    this.state = param.state;
    
  },
  watch:{
    product(newVal,oldVal){
      console.log("进来了", newVal.id);
      this.findCreditSettingById(newVal.creditSettingId);
      this.gradeSettings(newVal.creditSettingId);
      this.findApplyByProduct(newVal.id)
    }
  },
  methods: {
    async interestRateTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("利率模式数据字典返回结果", response);
      if (response.data.data != null) {
        this.interestRateTypeList = response.data.data;
      }
      console.log("利率模式数据字典集合", this.interestRateTypeList);
    },
    async penaltyInterestTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("罚息模式数据字典返回结果", response);
      if (response.data.data != null) {
        this.penaltyInterestTypeList = response.data.data;
      }
      console.log("罚息模式数据字典集合", this.penaltyInterestTypeList);
    },
    async serviceChargeTypes(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("手续费模式数据字典返回结果", response);
      if (response.data.data != null) {
        this.serviceChargeTypeList = response.data.data;
      }
      console.log("手续费模式数据字典集合", this.serviceChargeTypeList);
    },
    //根据product查询apply
    async findApplyByProduct(productId) {
      var params = {
        product: productId,
      };
      let response = await this.api.preLoanManagementApi.findApplyByProduct(
        params
      );
      console.log("=========response", response.data.data);
      if(response.data.code == 200){
        if(response.data.data>0){
          console.log("大于0")
          this.invoiceOccupyRateDisabled = true
        }
      }
      
    },
    async updateCreditSetting() {
      var params = {
        id: this.creditSetting.id,
        invoiceOccupyRate: accMul(this.businessRelatedForm.invoiceOccupyRate, 10000),
      };
      let response = await this.api.preLoanManagementApi.updateCreditSettingBlockchain(
        params
      );
      console.log("=========response", response.data.data);
    },
    monthly() {
      console.log("进入按月");
      this.businessRelatedFormRepaymentIntervalDisabled = false;
      for (
        var i = 0;
        i < this.businessRelatedForm.loanTerm.monthTerm.length;
        i++
      ) {
        if (this.businessRelatedForm.loanTerm.monthTerm[i] == 0) {
          this.businessRelatedForm.loanTerm.monthTerm.splice(i, 1);
        }
      }
      console.log(
        "businessRelatedForm",
        this.businessRelatedForm.loanTerm.monthTerm
      );
    },
    byDay() {
      console.log("进入按天");
      if (
        this.businessRelatedForm.loanTerm.monthTerm == undefined ||
        this.businessRelatedForm.loanTerm.monthTerm.length <= 0
      ) {
        console.log("进入判断");
        this.businessRelatedFormRepaymentIntervalDisabled = false;
        return;
      }
      this.businessRelatedForm.loanTerm.monthTerm = [0];
      this.businessRelatedForm.repaymentInterval = "1";
      this.businessRelatedFormRepaymentIntervalDisabled = true;

      console.log(
        "businessRelatedForm",
        this.businessRelatedForm.loanTerm.monthTerm
      );
    },
    notEmpty() {
      let flag = true;
      for (let key in this.essentialInformationForm) {
        if (!this.essentialInformationForm[key]) {
          if (key == "detail") {
            console.log("详情空" + key);
            continue;
          }
          console.log("essentialInformationForm空" + key);
          return false;
        }
      }
      if (
        this.essentialInformationForm.fundSide == undefined ||
        this.essentialInformationForm.fundSide.length <= 0
      ) {
        console.log("产品资金方空");
        return false;
      }
      if (
        this.businessRelatedForm.loanTerm.monthTerm == undefined ||
        this.businessRelatedForm.loanTerm.monthTerm.length <= 0
      ) {
        console.log("贷款期限为空");
        return false;
      }

      for (let key in this.businessRelatedForm) {
        if (
          !this.businessRelatedForm[key] &&
          this.businessRelatedForm[key] !== 0
        ) {
          console.log("businessRelatedForm空" + key);
          return false;
        }
      }

      for (var i = 0; i < this.ratingSettingForm.inputList.length; i++) {
        for (let key in this.ratingSettingForm.inputList[i]) {
          if (key == "creditSettingId" || key == "blockId") {
            continue;
          }
          if (
            !this.ratingSettingForm.inputList[i][key] &&
            this.ratingSettingForm.inputList[i][key] !== 0
          ) {
            console.log("ratingSettingForm.inputList" + (i + 1) + "空" + key);
            return false;
          }
        }
      }

      console.log("不空", this.essentialInformationForm);
      return flag;
    },
    cancel() {
      console.log("进入取消");
      this.$router.push({ name: "productManagementList" });
    },
    async gradeSettings(creditSettingId) {
      console.log("gradeSettings");
      var params = {
        id: creditSettingId,
      };
      let response = await this.api.preLoanManagementApi.gradeSettingList(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data && response.data.data.length > 0) {
        this.ratingSettingForm.inputList = response.data.data;
        for (var i = 0; i < this.ratingSettingForm.inputList.length; i++) {
          this.ratingSettingForm.inputList[i].maxCreditAmount /= 100;
          this.ratingSettingForm.inputList[i].creditInterestRate /= 10000;
          this.ratingSettingForm.inputList[i].serviceChargeRate /= 10000;
          this.ratingSettingForm.inputList[i].penaltyInterestRate /= 10000;
        }
      }
      console.log(
        "=========产品信息inputList",
        this.ratingSettingForm.inputList
      );
    },
    inputListAddCreditSetting() {
      for (var i = 0; i < this.ratingSettingForm.inputList.length; i++) {
        this.ratingSettingForm.inputList[
          i
        ].creditSettingId = this.creditSetting.blockId;
      }
    },
    compareTime(date1, date2) {
      let oDate1 = new Date(date1);
      let oDate2 = new Date(date2);
      var flag = true;
      if (oDate1.getTime() > oDate2.getTime()) {
        return false;
      }
      return flag;
    },
    async update() {
      var notEmpty = this.notEmpty();
      console.log("notEmpty", notEmpty);
      if (!notEmpty) {
        this.$message.error("必填项为空");
        return;
      }
      var compareTime = this.compareTime(
        this.essentialInformationForm.effectiveDate,
        this.essentialInformationForm.invalidDate
      );
      if (!compareTime) {
        this.$message.error("生效时间不可大于失效时间");
        return;
      }

      console.log(
        "this.businessRelatedForm.loanTerm",
        this.businessRelatedForm.loanTerm
      );
      var isDays = false;
      for (
        var i = 0;
        i < this.businessRelatedForm.loanTerm.monthTerm.length;
        i++
      ) {
        if (this.businessRelatedForm.loanTerm.monthTerm[i] == 0) {
          isDays = true;
        }
      }
      this.businessRelatedForm.loanTerm.isDays = isDays;
      console.log(
        "this.businessRelatedForm.loanTerm",
        this.businessRelatedForm.loanTerm
      );
      var params = {
        id: this.product.id,
        code: this.essentialInformationForm.code,
        name: this.essentialInformationForm.name,
        fundSide: this.essentialInformationForm.fundSide.map(Number),
        detail: this.essentialInformationForm.detail,
        effectiveDate: this.essentialInformationForm.effectiveDate,
        invalidDate: this.essentialInformationForm.invalidDate,
        loanTerm: this.businessRelatedForm.loanTerm,
        repaymentInterval: parseInt(this.businessRelatedForm.repaymentInterval),
        graceTermDays: this.businessRelatedForm.graceTermDays,
        interestRateType: parseInt(this.businessRelatedForm.interestRateType),
        penaltyInterestType: parseInt(this.businessRelatedForm.penaltyInterestType),
        serviceChargeType: parseInt(this.businessRelatedForm.serviceChargeType),
        maxLoanAmount: accMul(this.businessRelatedForm.maxLoanAmount, 100),
        minLoanAmount: accMul(this.businessRelatedForm.minLoanAmount, 100),
        maxLoanNum: this.businessRelatedForm.maxLoanNum,
        advanceRepayment: parseInt(this.businessRelatedForm.advanceRepayment),
      };
      console.log("params", params);
      let response = await this.api.preLoanManagementApi.updateByIdProduct(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        console.log("修改产品成功");
      }
      this.updateCreditSetting();
      console.log("=========inputList", this.ratingSettingForm.inputList);
      // console.log(
      //   "=========JSON.parse(this.contractSettingForm.contract)",
      //   JSON.parse(this.contractSettingForm.contract)
      // );
      if(this.contractSettingForm.contract!== ""){
        let responseWallet = await this.api.preLoanManagementApi.createConstract({
          id: this.contractSettingForm.id,
          contract: JSON.parse(this.contractSettingForm.contract),
        });
        console.log("=========responseWallet", responseWallet.data.data);
        if (null != responseWallet.data.data) {
          console.log("修改wallet成功");
        }
      }
      var update = [];
      var add = [];
      var num = 1;
      for (var i = 0; i < this.ratingSettingForm.inputList.length; i++) {
        this.ratingSettingForm.inputList[
          i
        ].creditSettingId = this.creditSetting.id;
        this.ratingSettingForm.inputList[i].maxCreditAmount = accMul(this.ratingSettingForm.inputList[i].maxCreditAmount,100);
        this.ratingSettingForm.inputList[i].creditInterestRate = accMul(this.ratingSettingForm.inputList[i].creditInterestRate,10000);
        this.ratingSettingForm.inputList[i].serviceChargeRate = accMul(this.ratingSettingForm.inputList[i].serviceChargeRate,10000);
        this.ratingSettingForm.inputList[i].penaltyInterestRate = accMul(this.ratingSettingForm.inputList[i].penaltyInterestRate,10000);
        this.ratingSettingForm.inputList[i].sort = num;
        num++;
        if (this.ratingSettingForm.inputList[i].blockId != "") {
          update.push(this.ratingSettingForm.inputList[i]);
        } else {
          add.push(this.ratingSettingForm.inputList[i]);
        }
      }
      console.log("update", update);
      console.log("add", add);
      if (add.length > 0) {
        let responseAdd = await this.api.preLoanManagementApi.batchCreateGradeSettingAutoGenerateBlockId(
          add
        );
        console.log("=========responseAdd", responseAdd.data.data);
        if (null != responseAdd.data.data) {
          console.log("创建成功");
        }
      }
      if (update.length > 0) {
        let responseUpdate = await this.api.preLoanManagementApi.batchUpdateGradeSetting(
          update
        );
        console.log("=========responseUpdate", responseUpdate.data.data);
        if (null != responseUpdate.data.data) {
          console.log("修改成功");
        }
      }
      if (this.gradeSettingIds.length > 0) {
        let responseDelete = await this.api.preLoanManagementApi.batchDeleteGradeSetting(
          this.gradeSettingIds
        );
        console.log("=========responseDelete", responseDelete.data.data);
        if (null != responseDelete.data.data) {
          console.log("删除成功");
          this.$router.push({ name: "productManagementList" });
        }
      }
      this.$router.push({ name: "productManagementList" });
    },
    async add() {
      var notEmpty = this.notEmpty();
      console.log("notEmpty", notEmpty);
      if (!notEmpty) {
        this.$message.error("必填项为空");
        return;
      }
      var compareTime = this.compareTime(
        this.essentialInformationForm.effectiveDate,
        this.essentialInformationForm.invalidDate
      );
      if (!compareTime) {
        this.$message.error("生效时间不可大于失效时间");
        return;
      }

      console.log(
        "this.businessRelatedForm.loanTerm",
        this.businessRelatedForm.loanTerm
      );
      var isDays = false;
      for (
        var i = 0;
        i < this.businessRelatedForm.loanTerm.monthTerm.length;
        i++
      ) {
        if (this.businessRelatedForm.loanTerm.monthTerm[i] == 0) {
          isDays = true;
        }
      }
      this.businessRelatedForm.loanTerm.isDays = isDays;
      console.log(
        "this.businessRelatedForm.loanTerm",
        this.businessRelatedForm.loanTerm
      );
      console.log("inputList", this.ratingSettingForm.inputList);
      var params = {
        id: this.product.id,
        code: this.essentialInformationForm.code,
        name: this.essentialInformationForm.name,
        fundSide: this.essentialInformationForm.fundSide.map(Number),
        detail: this.essentialInformationForm.detail,
        effectiveDate: this.essentialInformationForm.effectiveDate,
        invalidDate: this.essentialInformationForm.invalidDate,
        loanTerm: this.businessRelatedForm.loanTerm,
        repaymentInterval: parseInt(this.businessRelatedForm.repaymentInterval),
        graceTermDays: this.businessRelatedForm.graceTermDays,
        interestRateType: parseInt(this.businessRelatedForm.interestRateType),
        penaltyInterestType: parseInt(this.businessRelatedForm.penaltyInterestType),
        serviceChargeType: parseInt(this.businessRelatedForm.serviceChargeType),
        maxLoanAmount: accMul(this.businessRelatedForm.maxLoanAmount, 100),
        minLoanAmount: accMul(this.businessRelatedForm.minLoanAmount, 100),
        maxLoanNum: this.businessRelatedForm.maxLoanNum,
        advanceRepayment: parseInt(this.businessRelatedForm.advanceRepayment),
        state: 1,
      };
      console.log("params", params);
      let response = await this.api.preLoanManagementApi.updateByIdProduct(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        console.log("修改产品成功");
      }
      this.inputListAddCreditSetting();
      this.updateCreditSetting();
      console.log("修改产品=========", params);
      console.log(
        "ratingSettingForm==========",
        JSON.stringify(this.ratingSettingForm.inputList)
      );
      if(this.contractSettingForm.contract!== ""){
        let responseWallet = await this.api.preLoanManagementApi.createConstract({
          id: this.contractSettingForm.id,
          contract: JSON.parse(this.contractSettingForm.contract),
        });
        console.log("=========responseWallet", responseWallet.data.data);
        if (null != responseWallet.data.data) {
          console.log("修改wallet成功");
        }
      }
      this.batchCreateGradeSettingAutoGenerateBlockId();
      this.$router.push({ name: "productManagementList" });
    },
    hh() {
      console.log(
        "essentialInformationForm",
        this.essentialInformationForm.fundSide
      );
      console.log(
        "businessRelatedForm",
        this.businessRelatedForm.loanTerm.monthTerm
      );
    },
    async productInfo(id) {
      var params = {
        id: id,
      };
      let response = await this.api.preLoanManagementApi.findByIdProduct(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.product = response.data.data;

        this.essentialInformationForm.code = this.product.code;
        this.essentialInformationForm.name = this.product.name;
        var fundSideArray = this.product.fundSide;
        this.essentialInformationForm.fundSide = fundSideArray.map(String);
        this.essentialInformationForm.detail = this.product.detail;
        this.essentialInformationForm.effectiveDate = this.product.effectiveDate;
        this.essentialInformationForm.invalidDate = this.product.invalidDate;

        this.businessRelatedForm.loanTerm = this.product.loanTerm;
        // var monthTermArray = this.product.loanTerm.monthTerm;
        // this.businessRelatedForm.loanTerm.monthTerm = monthTermArray.map(
        //   String
        // );
        this.businessRelatedForm.loanTerm.monthTerm = this.product.loanTerm.monthTerm;

        this.businessRelatedForm.repaymentInterval =
          this.product.repaymentInterval + "";
        this.businessRelatedForm.graceTermDays = this.product.graceTermDays;
        this.businessRelatedForm.interestRateType = this.product.interestRateType+'';
        this.businessRelatedForm.penaltyInterestType = this.product.penaltyInterestType+'';
        this.businessRelatedForm.serviceChargeType = this.product.serviceChargeType+'';
        this.businessRelatedForm.initQuota = this.product.initQuota / 100;
        this.businessRelatedForm.creditValidDay = this.product.creditValidDay;
        this.businessRelatedForm.maxLoanAmount =
          this.product.maxLoanAmount / 100;
        this.businessRelatedForm.minLoanAmount =
          this.product.minLoanAmount / 100;
        this.businessRelatedForm.maxLoanNum = this.product.maxLoanNum;
        this.businessRelatedForm.advanceRepayment =
          this.product.advanceRepayment + "";
      }
      if (this.businessRelatedForm.loanTerm.monthTerm.length > 0) {
        for (
          var i = 0;
          i < this.businessRelatedForm.loanTerm.monthTerm.length;
          i++
        ) {
          if (this.businessRelatedForm.loanTerm.monthTerm[i] == 0) {
            this.businessRelatedFormRepaymentIntervalDisabled = true;
          }
        }
      }
      // this.businessRelatedForm = this.product
      // this.essentialInformationForm = this.product
      console.log("=========产品信息", this.product);
      console.log("=========产品信息2", this.businessRelatedForm);
      console.log("=========产品信息3", this.essentialInformationForm);
    },
    addItem() {
      this.ratingSettingForm.inputList.push({
        grade: "",
        maxCreditAmount: 0,
        creditInterestRate: 0,
        serviceChargeRate: 0,
        penaltyInterestRate: 0,
        creditSettingId: "",
        blockId: "",
        sort: 0,
      });
    },
    deleteItem(item, index) {
      if(this.ratingSettingForm.inputList.length==1){
        this.$message.error("最少留一条")
        return
      }
      this.ratingSettingForm.inputList.splice(index, 1);
      console.log(this.ratingSettingForm.inputList, "删除");
      console.log("item", item);
      if (item.id != "") {
        this.gradeSettingIds.push(item.id);
      }
      console.log("gradeSettingIds", this.gradeSettingIds);
    },
    days() {
      // for(var i = i;i<10;i++){
      //   this.daysList[i].value
      // }
      console.log("天数===========", this.daysList[0].value);
    },
    async findCreditSettingById(creditSettingId) {
      console.log("findCreditSettingById", creditSettingId);
      var params = {
        id: creditSettingId,
      };
      let response = await this.api.preLoanManagementApi.findCreditSettingById(
        params
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.creditSetting = response.data.data;
        this.businessRelatedForm.invoiceOccupyRate =
          this.creditSetting.invoiceOccupyRate / 10000;
      }
      console.log("========= creditSetting", this.creditSetting);
    },
    async findWalletById() {
      let response = await this.api.preLoanManagementApi.findWalletById({});
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        this.wallet = response.data.data;
        this.contractSettingForm.id = this.wallet.id;
        this.contractSettingForm.contract = JSON.stringify(
          this.wallet.contract
        );
      }
      console.log("========= wallet", this.wallet);
    },
    async batchCreateGradeSettingAutoGenerateBlockId() {
      var num = 1;
      for (var i = 0; i < this.ratingSettingForm.inputList.length; i++) {
        this.ratingSettingForm.inputList[i].maxCreditAmount = accMul(this.ratingSettingForm.inputList[i].maxCreditAmount,100);
        this.ratingSettingForm.inputList[i].creditInterestRate = accMul(this.ratingSettingForm.inputList[i].creditInterestRate,10000);
        this.ratingSettingForm.inputList[i].serviceChargeRate = accMul(this.ratingSettingForm.inputList[i].serviceChargeRate,10000);
        this.ratingSettingForm.inputList[i].penaltyInterestRate = accMul(this.ratingSettingForm.inputList[i].penaltyInterestRate,10000);
        this.ratingSettingForm.inputList[i].sort = num;
        num++;
      }
      console.log(
        "ratingSettingForm2==========",
        JSON.stringify(this.ratingSettingForm.inputList)
      );
      let response = await this.api.preLoanManagementApi.batchCreateGradeSettingAutoGenerateBlockId(
        this.ratingSettingForm.inputList
      );
      console.log("=========response", response.data.data);
      if (null != response.data.data) {
        console.log("创建成功");
        this.$router.push({ name: "productManagementList" });
      }
    },
    repaymentIntervalChange() {
      this.businessRelatedForm.loanTerm.monthTerm = [];
      console.log(this.businessRelatedForm.repaymentInterval);
    },
  },
};
</script>
<style scoped>
#addProduct {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
  text-align: left;
}
.textTips {
  margin: 20px 0 0 0;
}
.el-divider {
  margin: 10px 0 20px 0;
}
#essentialInformation {
  align-content: center;
}
.proportionOfTotalAmount_input {
  width: 20%;
}
.ratingSettingForm_input {
  width: 90px;
}
.input_width {
  width: 80%;
}
.input_em {
  text-indent: 2em;
  text-align: left;
}
.el-form-item__label {
  text-indent: 2em;
}
#end {
  text-align: center;
  margin-top: 30px;
}
.pingji_seting {
  text-align: center;
}
.span_line_height {
  line-height: 40px;
}
.button_margin_top{
margin-top: 30px;
}
.button_size{
  margin-right: 15px;
  padding: 0px;
  width: 120px;
  height: 32px;
  border-radius: 5px;
}
</style>