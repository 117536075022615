<template>
  <div id="addUser">
    <el-form :inline="true" :model="formInline">
      <el-form-item label="账号:">
        <el-input
          v-model="formInline.account"
          placeholder="请输入账号"
        ></el-input>
      </el-form-item>
      <el-form-item label="用户名:">
        <el-input
          v-model="formInline.name"
          placeholder="请输入用户名"
        ></el-input>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" @click="queryRoleUser">查询</el-button>
        <el-button type="primary" @click="resetAddExistingUser">重置</el-button>
      </el-form-item>

      <el-table
        :data="userList"
        @select="a"
        @select-all="b"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column
          type="index"
          prop="id"
          label="编号"
          width="50px"
        ></el-table-column>
        <el-table-column prop="account" label="账号"></el-table-column>
        <el-table-column prop="name" label="用户名"></el-table-column>
        <el-table-column prop="gender" label="性别">
          <template slot-scope="scope">
              <span  v-if="scope.row != null">
                <span v-if="scope.row.gender == 1">男 </span>
                <span v-else-if="scope.row.gender == 0">女 </span>
              </span>
          </template>
        </el-table-column>
        <el-table-column prop="mobile" label="联系方式"></el-table-column>
        <el-table-column prop="flag" label="状态">
          <template slot-scope="scope">
            <span  v-if="scope.row != null">
                <span v-if="scope.row.flag == 0">正常 </span>
                <span v-if="scope.row.flag == 1">停用 </span>
              </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @size-change="userListHandleSizeChange"
          @current-change="userListHandleCurrentChange"
          :current-page="userListPageNo"
          :page-sizes="[10, 15, 20]"
          :page-size="userListPageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="userListTotal"
        >
        </el-pagination>
      </div>
    </el-form>
  </div>
</template>

<script>
export default {
  props: ["roleId", "groupId", "parentNode"],
  data() {
    return {
      userListPageNo: 1,
      userListPageSize: 10,
      userListTotal: 0,
      roleList: [],
      userList: [],
      user: {
        id: [],
      },

      formInline: {
        account: "",
        name: "",
        id: "",
      },
    };
  },

  mounted() {
    console.log(this.roleId);
    this.findByRoleUser();
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    ////添加已有用户:多选框按钮
    a(selection, row) {
      console.log(selection);
      console.log(row);
      console.log(row);
      this.user.id.push(row.id);
    },
    b(selection) {
      selection.forEach((element) => {
        this.user.id.push(element.id);
      });

      console.log(this.user.id);
    },
    //添加已有用户:重置
    resetAddExistingUser() {
      this.formInline.account = "";
      this.formInline.name = "";
      this.userListPageNo = 1;
      this.userListPageSize = 10;
      this.findByRoleUser();
    },

    queryRoleUser() {
      this.userListPageNo = 1;
      this.userListPageSize = 10;
      this.findByRoleUser();
    },

    //添加已有用户:查询
    async findByRoleUser() {
      var list = this;
      var params = {
        account: list.formInline.account,
        name: list.formInline.name,
        id: this.roleId,
        pageNo: this.userListPageNo,
        pageSize: this.userListPageSize,
      };
      await list.api.platformApi
        .findByRoleUser(params)
        .then(function (respose) {
          list.userList = respose.data.data.records;
          list.userListPageNo = respose.data.data.current;
          list.userListPageSize = respose.data.data.size;
          list.userListTotal = respose.data.data.total;

          list.popup.addaAreadyUsers = true;
        })
        .catch(function (error) {});
    },

    // 添加已有用户:确定
    async addaRoleUser() {
      var list = this;
      console.log("roleId", this.roleId);
      console.log("userid", this.user.id);

      var params = {
        roleId: this.roleId,
        userIds: this.user.id,
        groupId: this.groupId,
        sessionId: this.$getCookie("sessionId"),
      };

      await list.api.platformApi.addaRoleUser(params).then(function (respose) {
        if (respose.data) {
          list.parentNode.loadlistuser();
          list.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          list.$message.error("添加失败");
        }
      });
    },
    userListHandleSizeChange(val) {
      this.userListPageSize = val;
      this.userListPageNo = 1;
      this.findByRoleUser();
    },
    userListHandleCurrentChange(val) {
      this.userListPageNo = val;
      this.findByRoleUser();
    },
  },
};
</script>
<style scoped>
#addUser .el-button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#addUser .el-form {
  margin: 0 20px;
}

#addUser .block {
  margin: 10px;
  text-align: right;
}
</style>
