<template>
  <div id="supplierManagementList">
    <el-row :gutter="12">
      <el-col :pull="11">
        <span>基础信息</span>
      </el-col>
    </el-row>
    <div class="table wiCss">
      <table>
        <tr>
          <td>供应商名称</td>
          <td>{{ corp.name }}</td>
          <td>统一社会信用代码</td>
          <td>{{ corp.idno }}</td>
          <td>税务登记号</td>
          <td>{{ corp.taxRegistrationNo }}</td>
          <td>组织机构代码</td>
          <td></td>
        </tr>
        <tr>
          <td>营业执照注册号</td>
          <td>{{ corp.businessLicenseRegistrationNo }}</td>
          <!-- <td>营业执照期限</td>
          <td>{{ corp.termBusinessLicense }}</td> -->
          <td>证照类别</td>
          <td>
            <div v-for="(item, index) in itemList" v-bind:key="index" >
              <div v-if="item.datavalue == corp.licenseType">
                {{  item.name }}
              </div>
              
            </div>
          </td>
          <td>注册所在省</td>
          <td>{{ corp.provinceRegistration }}</td>
          <td>注册所在市</td>
          <td></td>
        </tr>
        <tr>
          <td>注册城市区</td>
          <td></td>
          <td>经营所在省</td>
          <td>{{ corp.provinceOperation }}</td>
          <td>经营所在市</td>
          <td>{{ corp.cityOperation }}</td>
          <td>经营城市区</td>
          <td>{{ corp.managingUrbanAreas }}</td>
        </tr>
        <tr>
          
          <td>经营地址</td>
          <td>{{ corp.businessAddress }}</td>
          <td>经营范围</td>
          <td>{{ corp.natureBusiness }}</td>
          <!-- <td>企业成立日期</td>
          <td>{{ corp.establishDate }}</td> -->
        </tr>
      </table>
    </div>
    <div class="marginCss">
      <el-row :gutter="12">
        <el-col :pull="11">
          <span>法人信息</span>
        </el-col>
      </el-row>

      <div class="table wiCss">
        <table>
          <tr>
            <td>法人姓名</td>
            <td>{{ person.name }}</td>
            <td>证件号码</td>
            <td>{{ person.idno}}</td>
            <td>法人手机号码</td>
            <td>{{ person.mobile }}</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="marginCss">
      <el-row :gutter="12">
        <el-col :pull="11">
          <span>银行卡信息</span>
        </el-col>
      </el-row>
      <div class="table wiCss">
        <table>
          <tr>
            <td>开户行银行账号</td>
            <td>{{ corp.bankcard }}</td>
            <td>开户银行名称</td>
            <td>{{ corp.branchName }}</td>
            <td>开户银行省</td>
            <td></td>
          </tr>
        </table>
      </div>
    </div>

    <div class="marginCss">
      <el-row :gutter="12">
        <el-col :pull="11">
          <span>所属平台</span>
        </el-col>
      </el-row>

      <div class="table">
        <table>
          <tr>
            <td>承运商名字</td>
            <td style="text-align: left">一站网</td>
          </tr>
        </table>
      </div>
    </div>

    <div class="marginCss">
      <el-row :gutter="30">
        <el-col :span="20">
          <div class="buttonCss">
            <el-button type="primary">修改</el-button>
            <el-button @click="close">关闭</el-button>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import { findCorpAndRisk } from "@/service/moudules/platformApi";
import { findPersonAndRisk } from "@/service/moudules/platformApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      riskReq: {
        id: "",
      },
      personReq: {
        id: "",
      },
      diction: {
        code: "licenseType",
      },
      corp: {},
      person: {},
      itemList: [],
    };
  },
  mounted: function () {
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收的参数:",param)
    this.riskReq.id = param.id;
    this.personReq.id = param.personId;
    this.findCorpAndRisk(this.riskReq);
    this.findPersonAndRisk(this.personReq);
    this.findDictionariesItemList(this.diction);
  },
  methods: {
    async findCorpAndRisk(param) {
      let response = await this.api.platformApi.findCorpAndRisk(param);
      console.log("公司信息:",response.data);
      this.corp = response.data.data;
    },

    async findPersonAndRisk(param) {
      let response = await this.api.platformApi.findPersonAndRisk(param);
      console.log("法人信息", response);
      this.person = response.data.data;
    },

    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.itemList = response.data.data;
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#supplierManagementList {
  background-color: white;
  padding: 15px 0 100px;
}
#supplierManagementList table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-left: 30px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td {
  background: #ffffff;
  text-align: center;
  font-size: 14px;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
}
#supplierManagementList .wiCss td:nth-child(even) {
  width: 10%;
}
#supplierManagementList .header {
  color: red;
  text-align: left !important ;
}
#supplierManagementList .marginCss {
  margin-top: 35px;
}
#supplierManagementList .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>