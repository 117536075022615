<template>
  <div id="loanProcessingDetails">
    <el-row :gutter="12">
      <el-col :pull="11">
        <span>发票信息</span>
      </el-col>
    </el-row>
    <div class="table padding_class">
      <table>
    
        <tr>
          <td>发票含税金额</td>
          <td>{{ invoice.amountWithTaxStr }}</td>
        </tr>
        <tr>
          <td>发票不含税金额</td>
          <td>{{ invoice.amountNotWithTaxStr }}</td>
        </tr>
        <tr>
          <td>发票税率</td>
          <td>{{ (invoice.taxRateStr) }}</td>
        </tr>
        <tr>
          <td>发票税额</td>
          <td>{{ invoice.taxAmountStr }}</td>
        </tr>
        <tr>
          <td>发票代码</td>
          <td>{{ invoice.stateInvoiceNo }}</td>
        </tr>
        <tr>
          <td>发票号码</td>
          <td>{{ invoice.stateInvoiceSerialNo  }}</td>
        </tr>
      </table>

      <div class="marginCss">
        <el-row :gutter="1">
          <el-col :pull="11">
            <span class="leftCss">发票结算单信息</span>
          </el-col>
        </el-row>
      </div>

      <el-table
        :data="list"
        id="personTable"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
       <el-table-column
          label="序号"
          type="index"
          align="center"
          width="50"
          :show-overflow-tooltip="true"
        />
        <el-table-column
          prop="payerName"
          label="付款方"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="payeeName"
          label="收款方"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="payAmount"
          label="本次结算金额"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template slot-scope="scope">
          <span>{{ scope.row.payAmount / 100 }}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="startTime"
          label="交易开始时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="closeTime"
          label="交易结束时间"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="status"
          label="支付状态"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template  slot-scope="scope">
          <span>{{ statusArr[scope.row.status].name }}</span>
        </template>
        </el-table-column>
        <el-table-column
          prop="state"
          label="业务状态"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template  slot-scope="scope">
          <span>{{ stateArr[scope.row.state].name }}</span>
        </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>

import dictionaryService from "@/service/common/dictionaryService.js";

export default {
  data() {
    return {
      proofId: "",
      loaninformationRep: {
        amountWithTax: "",
        amountNotWithTax: "",
        taxRate: "",
        taxAmount: "",
        stateInvoiceSerialNo: "",
        stateInvoiceNo:""
      },
      list: [],
      diction: {
        code: "creditProofAssetsState",
      },
      dictionList: [],
      invoice: {

      },
      settlementBillStatusCode: process.env.VUE_APP_ENUM_SETTLEMENT_BILL_STATUS,
      statusArr: [],
      settlementBillStateCode: process.env.VUE_APP_ENUM_SETTLEMENT_BILL_STATE,
      stateArr: [],
    };
  },
  async mounted () {
    this.invoice = JSON.parse(localStorage.getItem("invoice"));
    await this.loadState();
    await this.listInvoice();
  },
  methods: {
    async listInvoice(){
      await this.api.paymentPlanApi.listInvoice(this.invoice).then(res => {
        let data = res.data;
         if(data.code == 200){
            this.list = data.data;
         }else{
           this.$message.error('应付帐结算情况,记载失败！');
         }
         console.log(res);
      });
    },
    async loadState() {
      // 加载支付状态
      dictionaryService
        .findDictionariesItemList(this.settlementBillStatusCode)
        .then((res) => {
          this.statusArr = res;
        })
        .catch((err) => {
        });
      // 加载业务状态
      dictionaryService
        .findDictionariesItemList(this.settlementBillStateCode)
        .then((res) => {
          this.stateArr = res;
        })
        .catch((err) => {
        });
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;color:#000000;padding:0";
    },

    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-left: 30px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  margin-left: 30px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  padding-left: 20px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
</style>