<template>
  <div id="loanProcessingDetails">
    <div>
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>基本信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td>项目名称</td>
          <td style="width:10%">{{ order.proofName }}</td>
          <td>线路名称</td>
          <td style="width:10%">{{ order.contentData.routingName }}</td>
          <td>客户单号</td>
          <td style="width:10%">{{ order.clientBillNo }}</td>
        </tr>
      </table>
    </div>

    <div style="margin-top:15px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>托运费信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td>名称</td>
          <td style="width:10%">{{ order.orderorName }}</td>
          <td>电话</td>
          <td style="width:10%">{{ order.mobile }}</td>
          <td>证件号码</td>
          <td style="width:10%">{{ order.buyerIdno }}</td>
        </tr>
      </table>
    </div>

    <div style="margin-top:15px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>发货方信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td style="width:10%">名称</td>
          <td style="width:10%">{{ order.contentData.deliveryContact }}</td>
          <td style="width:10%">电话</td>
          <td style="width:10%">{{ order.contentData.deliveryContactPhone }}</td>
          <td style="width:10%">发货地址</td>
          <td style="width:10%">{{ order.contentData.deliveryProvince+order.contentData.deliveryCity+order.contentData.deliveryArea }}</td>
          <td style="width:10%">详细地址</td>
          <td style="width:10%">{{ order.contentData.deliveryDetailAddress }}</td>
        </tr>
      </table>
    </div>

    <div style="margin-top:15px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>收货方信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td style="width:10%">名称</td>
          <td style="width:10%">{{ order.contentData.acceptorContact }}</td>
          <td style="width:10%">电话</td>
          <td style="width:10%">{{ order.contentData.acceptorContactPhone }}</td>
          <td style="width:10%">收货地址</td>
          <td style="width:10%">{{ order.contentData.receivingProvince+order.contentData.receivingCity+order.contentData.receivingArea }}</td>
          <td style="width:10%">详细地址</td>
          <td style="width:10%">{{ order.contentData.receivingDetailAddress }}</td>
        </tr>
      </table>
    </div>

    <div style="margin-top:15px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>货物信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td style="width:10%">名称</td>
          <td style="width:10%">{{ order.contentData.cargoName }}</td>
          <td style="width:10%">类型</td>
          <td style="width:10%">
            <div
              v-for="(item, index) in cargoType"
              v-bind:key="index"
            >
              <div v-if="item.datavalue == order.contentData.cargoType">
                {{ item.name }}
              </div>
            </div>
          </td>
          <td style="width:10%">货物重量(吨)</td>
          <td style="width:10%">{{ order.contentData.cargoWeight }}</td>
          <td style="width:10%">货物体积(立方米)</td>
          <td style="width:10%">{{ order.contentData.cargoMeasurement }}</td>
        </tr>
        <tr>
          <td style="width:10%">货物件数</td>
          <td style="width:10%">{{order.contentData.cargoPieces}}</td>
        </tr>
      </table>
    </div>

    <div style="margin-top:15px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>备注</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td style="width:10%">备注</td>
          <td>{{ order.remark }}</td>
        </tr>
      </table>
    </div>

    <el-divider></el-divider>

<div>
  <el-table
        id="table"
        :data="carrierList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        style="position: relative;"
      >
        <el-table-column
          prop="name"
          label="承运人名称"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="承运人电话"
          show-overflow-tooltip
          align="center"
        ></el-table-column>

        <el-table-column
          prop="driverName"
          label="司机姓名"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="driverPhone"
          label="司机电话"
          show-overflow-tooltip
          align="center"
        >
      </el-table-column>

        <el-table-column
          prop="carNumber"
          label="车牌号码"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="bankName"
          label="银行开户名"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="bankcard"
          label="银行卡号"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="bankNo"
          label="开户银行"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="freightFee"
          label="总运费(元)"
          show-overflow-tooltip
          align="center"
        ><template slot-scope="scope">
            <span>{{accDiv(scope.row.freightFee , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="carrierFee"
          label="承运人运费(元)"
          show-overflow-tooltip
          align="center"
        ><template slot-scope="scope">
            <span>{{accDiv(scope.row.carrierFee , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="serviceFee"
          label="服务费(元)"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
            <span>{{accDiv(scope.row.serviceFee , 100)}}</span>
          </template>
      </el-table-column>

        <el-table-column
          prop="paymentPlan.fuelCost"
          label="油卡付"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.fuelCost , 100)}}</span>
          </template>
      </el-table-column>

        <el-table-column
          prop="paymentPlan.freightPrepaid"
          label="预付"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.freightPrepaid , 100)}}</span>
          </template>
      </el-table-column>

        <el-table-column
          prop="paymentPlan.freightCollect"
          label="到付"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.freightCollect , 100)}}</span>
          </template>
      </el-table-column>

        <el-table-column
          prop="paymentPlan.accountPayable"
          label="回单付"
          show-overflow-tooltip
          align="center"
        >
        <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.accountPayable , 100)}}</span>
          </template>
      </el-table-column>
      </el-table>
</div>
   
  </div>
</template>
<script>

export default {
  data() {
    return {
      order:{contentData:{}},
      cargoTypeDiction:{
        code:'cargoType'
      },
      cargoType:[],
      contentData:[],
      carrierList:[]
    };
   
  },
  created(){
    let params = {id:this.$route.query.id}
    this.api.orderApi.findOrderDetail(params).then(res=>{
      res = res.data
      this.order = res.data
      this.order.mobile = this.order.buyerPhone
      this.order.contentData = this.order.contentData ? JSON.parse(this.order.contentData):{}
      this.carrierList = this.order.contentData.carriers
    }) 
  },
  async mounted() {
    this.loadState()
  },
  methods: {
    loadState(){
      this.api.platformApi.findDictionariesItemList(this.cargoTypeDiction)
        .then((res)=>{
          this.cargoType = res.data.data;
        })
    },
    dictionary(arr,num){
      if(arr == null || arr[num] == null){
        return '';
      }
      return arr[num].name;
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0;overflow: visible;";
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px 30px;
  text-align: left;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  background-color: rgba(29, 144, 255, 0);
}
#loanProcessingDetails span {
  font-size: 15px;
}
#loanProcessingDetails .marginCss {
  margin-top: 35px;
}
#loanProcessingDetails .leftCss {
  margin-right: 27px;
}
#loanProcessingDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#loanProcessingDetails .fuInfo {
  margin-top: 5px;
}
.padding_class {
  margin-top: 15px;
}
>>> .el-dialog__body {
  border-top: 1px solid rgba(228, 228, 228, 1);
  border-bottom: 1px solid rgb(228, 228, 228, 1);
  /* padding-left: 0;
  padding-right: 0; */
}
>>> .el-table__body-wrapper{
  overflow: visible;
}
.main_class {
  /* border: solid;
  margin: 0 auto; */
  padding-top: 5px;
}
.margin_right {
  margin-right: 10px;
}
.dialogBox {
  text-align: left;
}

.overflow{
  overflow: visible;
}
.payDiv{
  position: absolute;
  margin-left: 35px;
  background-color: #FFF;
  z-index: 9;
  visibility: hidden;
  display: none;
}
.payFreight,.payFreight:hover{
  color: #BFBF00;
}
.payFreight:hover+.payDiv,.payDiv:hover{
  visibility: visible;
  display: block;
}
</style>