<template>
  <div>
        <el-divider></el-divider>
              <div id="button">
                <el-row>
                  <el-col :span="1">
                    <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                      >物流配送信息</el-button
                    >
                  </el-col>
                </el-row>
              </div>
              <div class="textTips">
                <el-divider class="el-divider"></el-divider>
              </div>
              <el-row>
                <el-form-item label="发货联系人" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryContact"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label=" 发货联系人电话" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.deliveryContactPhone"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="发货省" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryProvince"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="发货市" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.deliveryCity"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="发货区域" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.deliveryArea"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="发货详细地址" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.deliveryDetailAddress"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收货联系人" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.acceptorContact"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收货联系人电话" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.acceptorContactPhone"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收货省" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.receivingProvince"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收货市" style="display:inline-block">
                    <el-col :span="22">
                      <el-input  v-model="formData.receivingCity"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="收货区域" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.receivingArea"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收货详细地址" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.receivingDetailAddress"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="货物重量" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.cargoWeight"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="货物体积" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.cargoMeasurement"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="货物名称" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.cargoName"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="货物类型" style="display:inline-block">
                  <el-col :span="22">
                      <el-select placeholder="类型" v-model="formData.cargoType" clearable>
                        <el-option v-for="item in cargoType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
                <el-form-item label="货物件数" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.cargoPieces"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="包装名称" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.packageName"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="车辆牌照号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.carNumber"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="驾驶员名称" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.driverName"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="驾驶员手机号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.driverPhone"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收款方名称" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.payeeName"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收款方证件号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.payeeIdno"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="收款方手机号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.payeePhone"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收款方开户行" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="formData.payeeBankNo"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收款方银行卡号" style="display:inline-block">
                    <el-col :span="22">
                      <el-input v-model="formData.payeeBankcard"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="预计提货时间" style="display:inline-block">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="formData.estimatedPickUpTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="预计到达时间" style="display:inline-block;margin-left:-770px">
                    <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="formData.estimatedArrivalTime	"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>
  </div>
</template>>
<script>
export default {
  props: ['dataForm'],
  data(){
    return{
      formData:{},
      subjectDiction: {
        code: "subjectType",
      },
      subjectType:[],
      cargoTypeDiction:{
        code:'cargoType'
      },
      cargoType:[],
    }
  },
  watch:{
    dataForm(newVal,oldVal){
      console.log('沟子：',oldVal,newVal)
      this.formData = JSON.parse(newVal)
    }
  },
  created(){
    this.findDictionariesSubjectTypeList(this.subjectDiction)
    this.findDictionariesCargoTypeList(this.cargoTypeDiction)
    console.log('加载中...',this.dataForm)
    this.formData = this.dataForm ? JSON.parse(this.dataForm) : {}
  },
  mounted:function(){

  },
  methods: {
    async findDictionariesSubjectTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.subjectType = response.data.data;
		},
    async findDictionariesCargoTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.cargoType = response.data.data;
		},
  }
};
</script>>