<template>
  <div id="editUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form :model="ruleForm" label-width="120px" class="demo-ruleForm">
          <input type="hidden" v-model="ruleForms" />
          <el-form-item label="日利率：" prop="creditInterestRate">
            <el-input v-model="ruleForms.creditInterestRate"></el-input>
            <!-- <template slot-scope="scope">
              {{scope.row.creditInterestRate}}
            </template> -->
          </el-form-item>
          <el-form-item label="手续费率：" prop="serviceChargeRate">
            <el-input v-model="ruleForms.serviceChargeRate"></el-input>
          </el-form-item>
          <el-form-item label="最大授信额度：" prop="maxCreditAmount">
            <el-input v-model="ruleForms.maxCreditAmount"></el-input>
          </el-form-item>
          <el-form-item label="罚息率：" prop="penaltyInterestRate">
            <el-input v-model="ruleForms.penaltyInterestRate"></el-input>
          </el-form-item>
          <el-form-item label="风控评级：" prop="grade">
            <el-select v-model="ruleForms.grade" placeholder="请选择">
              <el-option
                v-for="item in Confluence"
                :key="item.grade"
                :label="item.label"
                :value="item.grade"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
    <el-row id="anniu22" type="flex">
      <el-col>
        <el-button type="success" @click="canceledit()">取消</el-button>
        <el-button type="primary" @click="updateCredit()">设置</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["ruleForm"],

  data() {
    return {
      
      ruleForms: {
        grade: null,
        creditInterestRate: null,
        maxCreditAmount: null,
        penaltyInterestRate: null,
        serviceChargeRate: null,
        creditSettingId: null,
        riskId: null,
        operator: null,
        thirdId: 1,
        operator: "系统操作者",
      },
    };
  },

  mounted: function () {
    this.Confluence();
    this.runCredit();
  },

  methods: {
    
    //调用接口
    async Confluence() {
      let response = await this.api.platformApi.Confluence();
      console.log("------", response.data.data);
      this.Confluence = response.data.data;
      console.log("-----------------调用接口", this.Confluence);
    },

    //跑授信
    async runCredit() {
      var params = {
        riskId: this.ruleForm.id,
        thirdId: this.ruleForms.thirdId,
        operator: this.ruleForms.operator,
        creditSettingId: this.ruleForm.creditSettingId,
      };
      console.log("-----------授信参数", params);
      let response = await this.api.platformApi.runCredit(params);
      this.ruleForms = response.data.data;
      this.ruleForms.creditInterestRate =
        this.ruleForms.creditInterestRate / 1000000;
      this.ruleForms.serviceChargeRate =
        this.ruleForms.serviceChargeRate / 1000000;
      this.ruleForms.maxCreditAmount = this.ruleForms.maxCreditAmount / 100;
      this.ruleForms.penaltyInterestRate =
        this.ruleForms.penaltyInterestRate / 1000000;
      console.log("-----------------跑授信", this.ruleForms);
    },

    //更新授信
    async updateCredit() {

      var params = {
        riskId: this.ruleForm.id,
        grade: this.ruleForms.grade,
        creditInterestRate: this.ruleForms.creditInterestRate * 1000000,
        maxCreditAmount: this.ruleForms.maxCreditAmount * 100,
        penaltyInterestRate: this.ruleForms.penaltyInterestRate * 1000000,
        serviceChargeRate: this.ruleForms.serviceChargeRate * 1000000,
        sessionId: this.getCookie("sessionId"),
        creditSettingId: this.ruleForm.creditSettingId,
      };

      console.log("-----------修改授信参数", params);
      let response = await this.api.platformApi.updateCredit(params);
      this.updateCredit = response.data;
      console.log(
        "-----------------更新授信",
        this.updateCredit,
        "----------",
        response
      );
      if (response.code == 200) {
        this.$message.error("修改失败");
      } else {
        this.$message.success("修改成功");
      }

      this.$emit("closeDialog");
      
      this.$emit("reload");
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    canceledit() {
      this.$emit("closeDialog");
    },
  },
};
</script>
<style scoped>
#editUser .el-cascader {
  width: 100%;
}

#anniu22 {
  text-align: right;
  border-top: 1px solid rgba(228, 228, 228, 1);
  padding-top: 10px;
  padding-right: 30px;
  border-radius: 5px;
}

#editUser .el-select {
  width: 100%;
}

#anniu22 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#editUser .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#editUser .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#editUser .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#editUser .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
