<template>
  <div id="supplierManagementList">
    <template>
      <div>
        <el-form :inline="true" :model="formInline" class="demo-form-inline">
          <el-row>
            <el-col :span="9" align="left">
              <!-- <div>
                <el-row>
                  <el-col :span="20"
                    ><div>
                      <el-row :gutter="0">-->

              <el-col :span="5">
                <div class="fontcss">统一社会信用代<br />码/身份证号码:</div>
              </el-col>
              <el-col :span="8">
                <el-form-item prop="account">
                  <el-input
                    v-model="formInline.idno"
                    class="el-select widcss"
                    ref="formInline"
                    placeholder="请输入查询"
                  >
                  </el-input>
                </el-form-item>
                <!--   </el-col>
                      </el-row></div  >
                
                </el-row>
              </div> -->
              </el-col>
            </el-col>
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="客户名字:">
                  <el-input
                    v-model="formInline.riskName"
                    class="el-select"
                    placeholder="请输入查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
        </el-form>

        <div class="marginCss">
          <el-form
            :inline="true"
            :model="formInline"
            class="demo-form-inline marcss"
          >
            <el-row>
              <el-col :span="12" align="left">
                <div class="grid-content bg-purple">
                  <el-form-item label="最近修改时间:">
                    <el-date-picker
                      v-model="formInline.latestModifyTimeStart"
                      type="date"
                      placeholder="起"
                      editable
                      pattern="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker
                    >-
                    <el-date-picker
                      type="date"
                      v-model="formInline.latestModifyTimeEnd"
                      placeholder="止"
                      editable
                      pattern="yyyy-MM-dd HH:mm:ss"
                      value-format="yyyy-MM-dd HH:mm:ss"
                    ></el-date-picker>
                  </el-form-item>
                </div>
              </el-col>

              <el-col :span="8" >
                <el-form-item id="anniu">
                  <el-button
                    type="primary"
                    icon="el-icon-search"
                    @click="query"
                    >查询</el-button
                  >
                  <el-button
                    type="primary"
                    @click="rest"
                    icon="el-icon-refresh-right"
                    >重置</el-button
                  >
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
        </div>
      </div>

      <el-table
        id="table"
        :data="list"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
         v-loading.lock="fullscreenLoading"
      >
        <el-table-column prop="id" v-if="show" />
        <el-table-column prop="legal" v-if="show" />
        <el-table-column prop="sss" label="序号" width="50" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
        <el-table-column
          prop="name"
          label="客户姓名"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="idno"
          label="统一社会信用代码/身份证号码"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="legalName"
          label="法人名称"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="creditAmount"
          label="授信额度"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template slot-scope="scope">
					{{scope.row.creditAmount/100}}
				</template>
        </el-table-column>
        <el-table-column
          prop="creditInterestRate"
          label="日利率"
          :show-overflow-tooltip="true"
          align="center"
        >
        <template slot-scope="scope">
					{{scope.row.creditInterestRate/1000000}}
				</template>
        </el-table-column>
        <el-table-column
          prop="serviceChargeRate"
          label="手续费率"
          :show-overflow-tooltip="true"
          align="center">
         <template slot-scope="scope">
					{{scope.row.serviceChargeRate/1000000}}
				</template>
        </el-table-column>
        <el-table-column
          prop="maxCreditAmount"
          label="最大授信额度"
          :show-overflow-tooltip="true"
          align="center">
         <template slot-scope="scope">
					{{scope.row.maxCreditAmount/100}}
				</template>
        </el-table-column>
        <el-table-column
          prop="penaltyInterestRate"
          label="罚息率"
          :show-overflow-tooltip="true"
          align="center">
         <template slot-scope="scope">
					{{scope.row.penaltyInterestRate/1000000}}
				</template>
        </el-table-column>
        <el-table-column
          prop="grade"
          label="风控评级"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="createTime"
          label="创建时间"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="latestModifyTime"
          label="最近修改时间"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column label="操作" align="center">
          <template slot-scope="scope">
            <el-col :span="10">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="detail(scope.row)"
                :underline="false"
                size="medium"
                >详情</el-link
              >
            </el-col>
            <el-col :span="10">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="Setting(scope.row)"
                :underline="false"
                size="medium"
                v-if="funArr.indexOf('设置') !== -1"
                >设置</el-link
              >
            </el-col>
          </template>
        </el-table-column>
      </el-table>
      <el-dialog
        @close="abc = false"
        id="anniu"
        class="dialogBox"
        :close-on-click-modal="false"
        title="设置"
        :visible.sync="dialogVisibledetails"
      >
        <setting
          v-if="abc"
          @closeDialog="canceledit"
          :ruleForm="details"
          @reload="loadlist"
          ref="listValue"
        ></setting>
      </el-dialog>

      <!-- :page-size="queryParams.pageSize"
          @size-change="handleSizeChange" -->
      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </template>
  </div>
</template>

<script>
//获取白名单列表
import Setting from "./Setting";
import { findWhiteList } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      funArr: [],
      fullscreenLoading: false,
      buttonFlag: false,
      formInline: {
        idno: "",
        riskName: "",
        latestModifyTimeStart: "",
        latestModifyTimeEnd: "",
      },
      abc: "",
      page: {
        pageNo: 1,
        pageSize: 10,
      },
      total: 0,
      list: [],
      show: false,

      dialogVisibledetails: false,
      details: "",
    };
  },
  mounted: function () {
    this.whiteList();
  },
      created:function(){
	
	this.menuPermission()
},
  methods: {
    query(){
      this.page.pageNo = 1;
      this.whiteList();
    },
 async menuPermission() {
		let name = "供应商管理";
		let params = {
			id: this.$getCookie("sessionId"),
			menuName: name
		}
		let response = await this.api.platformApi.menuPermission(params);

		console.log(name + "页面功能列表start---")
		console.log(response.data.data)
		console.log(name + "页面功能列表end---")
		this.funArr = response.data.data
		console.log(this.funArr)
	},
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    loadlist(){
        this.fullscreenLoading = true;
        setTimeout(()=>{
          this.fullscreenLoading = false;
          this.whiteList();
        },3000)
    },
    async whiteList() {
      console.log(this.formInline);
      var params = {
        idNo: this.formInline.idno,
        riskName: this.formInline.riskName,
        latestModifyTimeStart: this.formInline.latestModifyTimeStart,
        latestModifyTimeEnd: this.formInline.latestModifyTimeEnd,
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
      };
      let response = await this.api.platformApi.findWhiteList(params);
      console.log(response.data);
      this.list = response.data.data.records;
      console.log(this.list);
      this.total = response.data.data.total;
    },
    rest() {
      (this.formInline.idno = ""),
        (this.formInline.riskName = ""),
        (this.formInline.latestModifyTimeStart = ""),
        (this.formInline.latestModifyTimeEnd = "");
        this.query();
    },
    getMechanism() {},
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.whiteList();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.whiteList();
    },

    Setting(row) {
      this.details = row;
      this.dialogVisibledetails = true;
      this.abc = true;
    },
    canceldetails() {
      this.dialogVisibledetails = false;
    },

    canceledit() {
      this.dialogVisibledetails = false;
      this.accountsetting = false;
    },
    detail(row) {
      console.log(row);
      this.$router.push({ name: "supplierManagementDetails" });
      localStorage.setItem(
        "id",
        JSON.stringify({
          id: row.id,
          personId: row.legal,
        })
      );
    },
  },
  components: {
    Setting,
  },
};
</script>


<style scoped>
#supplierManagementList {
  background-color: white;
  padding: 15px 20px 100px;
}
#supplierManagementList .el-input > input {
  height: 32px;
  border-radius: 5px;
  border: 2px solid #e8e8e8;
  width: 200px;
}
#supplierManagementList .grid-anniu button {
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin-bottom: 11px;
}
#anniu {
  border: 0;
}

.elCloinput {
  margin-left: -21px;
}

.el-select {
  width: 220px;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}

#page {
  float: right;
  margin-top: 15px;
}
#supplierManagementList .fontcss {
  color: #606266;
  font-size: 15px;
}
#supplierManagementList .widcss {
  width: 220px;
}
#supplierManagementList .marginCss {
  margin-top: 20px;
}
</style>