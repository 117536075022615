import '@/plugins/axios';

/**
 * 订单查询
 * @param {*} params 
 * @returns 
 */
 export const findOrderList = params => axios.post("/order/orderList", params).then(data => data)

 /**
 * 查询订单详情
 * @param {*} params 
 * @returns 
 */
  export const findOrderDetail = params => axios.post("/order/orderDetail", params).then(data => data)

   /**
 * 修改订单
 * @param {*} params 
 * @returns 
 */
    export const updateOrder = params => axios.post("/order/updateOrder", params).then(data => data)

  /**
 * 创建订单
 * @param {*} params 
 * @returns 
 */
   export const createOrder = params => axios.post("/order/createOrder", params).then(data => data)   

  /**
 * 上传订单
 * @param {*} params 
 * @returns 
 */
   export const createBatchOrder = params => axios.post("/order/createBatchOrder", params).then(data => data)
   
  /**
 * 查询货主信息
 * @param {*} params 
 * @returns 
 */
   export const profileInfo = params => axios.post("/order/profileInfo", params).then(data => data)    