<template>
  <div id="addSysUserList">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="10" :md="12">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账户" prop="account">
            <el-input v-model="ruleForm.account"></el-input>
          </el-form-item>
          <!-- <el-form-item label="用户名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item> -->
          <el-form-item label="密码" prop="salted">
            <el-input v-model="ruleForm.salted"></el-input>
          </el-form-item>
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="ruleForm.email"></el-input>
          </el-form-item>
          <el-form-item label="身份证" prop="idno">
            <el-input v-model="ruleForm.idno"></el-input>
          </el-form-item>
          <el-form-item label="性别" prop="gender">
            <el-select v-model="ruleForm.gender" placeholder="请选择性别">
              <el-option
                v-for="item in optionsgender"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input v-model="ruleForm.mobile"></el-input>
          </el-form-item>

          <el-form-item label="所属部门" prop="depatments">
            <el-select v-model="ruleForm.depatments" placeholder="请选择">
              <el-option
                v-for="item in depss"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="所属角色" prop="role">
            <el-select
              v-model="roleId"
              placeholder="请选择"
              @change="changerole()"
              disabled
            >
              <el-option
                :key="roleId"
                :label="roleName"
                :value="roleId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["roleId", "roleName","parentNode"],
  mounted: function () {
    this.changerole();
  },
  data() {
    return {
      flag: false,
      ruleForm: {
        id: this.$route.params.id,
        account: "",
        name: "",
        gender: "",
        mobile: "",
        gname: "",
        rname: "",
        idno: "",
        email: "",
        salted: "",
        depatments:""
      },
      optionsgender: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      id: "",
      depatment: "",
      depatment1: "",
      depss: [],
      depss1: [],
      orole: "",
      oguid: "",
      rules: {
        account: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        // name: [
        //   {
        //     required: true,
        //     message: "用户名不能为空",
        //     trigger: "blur",
        //   },
        // ],
        salted: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        depatments: [
          {
            required: true,
            message: "所属部门不能为空",
            trigger: "blur",
          },
        ],
        // idno: [
        //   {
        //     required: true,
        //     message: "身份证不能为空",
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    async submitForm(formName) {
      this.flag = false;
      await this.$refs[formName].validate((valid) => {
        if (valid) {
          this.flag = true;
          this.ruleForm.name = this.ruleForm.account
          var params = {
            user: this.ruleForm,
            groups: [[this.ruleForm.depatments]],
            roles: [[this.ruleForm.depatments, this.roleId]],
            sessionId: this.$getCookie("sessionId"),
          };
          this.api.platformApi
            .addNewUser(params)
            .then((response) => {
              this.parentNode.loadlistuser()
              this.$message({
                message: "添加成功",
                type: "success",
              });
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    async changerole() {
      let data = {};
      var params = {
        role: this.roleId,
      };
      await this.api.platformApi
        .changerole(params)
        .then((response) => {
          console.log(response);
          this.depss = response.data.data;
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
<style scoped>
#addSysUserList .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#addSysUserList .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#addSysUserList .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#addSysUserList .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

#addSysUserList .el-select {
  width: 100%;
}
</style>
