<template>
  <div>
    <div style="margin-top:20px">
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>物流信息</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr>
          <td>发货省</td>
          <td style="width:10%">{{ contentData.deliveryProvince }}</td>
          <td>发货市</td>
          <td style="width:10%">{{ contentData.deliveryCity }}</td>
          <td>发货区域</td>
          <td style="width:10%">{{ contentData.deliveryArea }}</td>
          <td>收货省</td>
          <td style="width:10%">{{ contentData.receivingProvince }}</td>
        </tr>
        <tr>
          <td>收货市</td>
          <td>{{ contentData.receivingCity }}</td>
          <td>收货区域</td>
          <td>{{ contentData.receivingArea }}</td>
          <td>路线编码</td>
          <td>{{contentData.routingCode}}</td>
          <td>运输方式</td>
          <td>
            <div
                v-for="(item, index) in transportMode"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == contentData.transportModes">
                  {{ item.name }}
                </div>
              </div>
          </td>
        </tr>
        <tr>
          <td>车型</td>
          <td>
            <div
                v-for="(item, index) in model"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == contentData.carType">
                  {{ item.name }}
                </div>
              </div>
          </td>
          <td>车长</td>
          <td>{{contentData.carCaptain}}</td>
          <td>车厢温度</td>
          <td>
            <div
                v-for="(item, index) in carriageTemperature"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == contentData.carTemperature">
                  {{ item.name }}
                </div>
              </div>
          </td>
          <td>运输状态</td>
          <td>
            <div
                v-for="(item, index) in transportationStatus"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == contentData.transportState">
                  {{ item.name }}
                </div>
              </div>
          </td>
        </tr>
        <tr>
          <td>发货联系人</td>
          <td>{{ contentData.deliveryContact }}</td>
          <td>发货联系人电话</td>
          <td>{{contentData.deliveryContactPhone}}</td>
          <td>发货地址</td>
          <td>{{ contentData.deliveryAddress }}</td>
          <td>收货地址</td>
          <td>{{contentData.receivingAddress}}</td>
        </tr>
        <tr>
          <td>货物重量</td>
          <td>{{ contentData.cargoWeight }}</td>
          <td>货物体积</td>
          <td>{{contentData.cargoMeasurement}}</td>
          <td>货物件数</td>
          <td>{{contentData.cargoPieces}}</td>
          <td>车辆牌照号</td>
          <td>{{contentData.carNumber	}}</td>
        </tr>
        <tr>
          <td>保险单号</td>
          <td>{{ contentData.insuranceNo }}</td>
          <td>保险公司名称</td>
          <td>{{contentData.insuranceCorpName}}</td>
          <td>驾驶员名称</td>
          <td>{{contentData.driverName}}</td>
          <td>驾驶员身份证号</td>
          <td>{{contentData.driverIdno	}}</td>
        </tr>
        <tr>
          <td>驾驶员手机号</td>
          <td>{{ contentData.driverPhone }}</td>
          <td>IP地址</td>
          <td>{{contentData.ip}}</td>
          <td>MAC地址</td>
          <td>{{contentData.mac}}</td>
          <td>最短要求提货时间</td>
          <td>{{contentData.minRequiredPickUpPeriod	}}</td>
        </tr>
        <tr>
          <td>最长要求提货时间</td>
          <td>{{ contentData.maxRequiredPickUpPeriod }}</td>
          <td>最短预计到达时间</td>
          <td>{{contentData.minEstimatedArrivalPeriod}}</td>
          <td>最长预计到达时间</td>
          <td>{{contentData.maxEstimatedArrivalPeriod}}</td>
          <td>收货主体类型</td>
          <td>
            <div
                v-for="(item, index) in subjectType"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == contentData.acceptorType">
                  {{ item.name }}
                </div>
              </div>
          </td>
        </tr>
        <tr>
          <td>收货主体名称</td>
          <td>{{ contentData.acceptorName }}</td>
          <td>收货主体证件号</td>
          <td>{{contentData.acceptorIdno}}</td>
          <td>收货联系人</td>
          <td>{{contentData.acceptorContact}}</td>
          <td>收货联系人电话</td>
          <td>{{contentData.acceptorContactPhone}}</td>
        </tr>
        <tr>
          <td>发货时间</td>
          <td>{{ contentData.pickUpTime }}</td>
          <td>收货时间</td>
          <td>{{contentData.arrivalTime}}</td>
        </tr>

      </table>
    </div>

    <el-divider class="el-divider"></el-divider>

    <div>
      <div class="textTips">
        <el-row>
          <el-col :span="4" align="left">
            <span>GPS定位信息集</span>
          </el-col>
        </el-row>
      </div>
      <table id="table">
        <tr v-for="(item, index) in contentData.locateInfos" :key="index">
          <td>当前时间</td>
          <td style="width:10%">{{ item.currentTime }}</td>
          <td>经度</td>
          <td style="width:10%">{{ item.longitude }}</td>
          <td>纬度</td>
          <td style="width:10%">{{ item.latitude }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
  
export default {
  props:['formData'],
  data() {
    return {
      contentData:{},
      taskState:[],
      stateDiction: {
        code: "taskState",
      },
      subjectType:[],
      subjectDiction: {
        code: "subjectType",
      },
      taskType:[],
      typeDiction: {
        code: "taskType",
      },
      transportMode:[],
      modeDiction:{
        code:'transportMode'
      },
      model:[],
      modelDiction:{
        code:"model"
      },
      carriageTemperature:[],
      temperatureDiction:{
        code:'carriageTemperature'
      },
      transportationStatus:[],
      transportationStatusDiction:{
        code:"transportationStatus"
      },
    };
   
  },
  created(){
    this.loadState()
    console.log('加载中...',this.formData)
    this.contentData = this.formData ? this.formData : {}
  },
  async mounted() {
    
  },
  watch:{
    dataForm(newVal,oldVal){
      console.log('沟子：',oldVal,newVal)
      this.contentData = newVal
    }
  },
  methods: {
    loadState(){
      this.api.platformApi
        .findDictionariesItemList(this.stateDiction)
        .then((res) => {
          this.taskState = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.subjectDiction)
        .then((res)=>{
          this.subjectType = res.data.data;
        })
      this.api.platformApi.findDictionariesItemList(this.typeDiction)
        .then((res)=>{
          this.taskType = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.modeDiction)
        .then((res)=>{
          this.transportMode = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.modelDiction)
        .then((res)=>{
          this.model = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.temperatureDiction)
        .then((res)=>{
          this.carriageTemperature = res.data.data;
        })
        this.api.platformApi.findDictionariesItemList(this.transportationStatusDiction)
        .then((res)=>{
          this.transportationStatus = res.data.data;
        })
    },
  },
};
</script>
<style scoped>
#loanProcessingDetails {
  background-color: white;
  padding: 15px 0 100px 30px;
  text-align: left;
}
#loanProcessingDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
</style>