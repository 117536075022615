<template>
  <div id="platformRepaymentList">
    <!-- <div class="repaylist_page_class"> -->
    <template>
      <div>
        <el-form :inline="true" :model="repaymentReq" class="demo-form-inline">
          <el-row :gutter="0">
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="贷款企业:">
                  <el-input
                    v-model="repaymentReq.name"
                    class="el-select"
                    placeholder="请输入查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="10" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="还款计划到期日:">
                  <el-date-picker
                    type="date"
                    v-model="repaymentReq.timeStart"
                    placeholder="起"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker
                  >-
                  <el-date-picker
                    type="date"
                    v-model="repaymentReq.timeEnd"
                    placeholder="止"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                  ></el-date-picker>
                </el-form-item>
              </div>
            </el-col>
          </el-row>

          <el-row :gutter="0">
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="还款状态:">
                  <el-select
                    v-model="repaymentReq.repaymentPlanState"
                    placeholder="请选择"
                    class="el-select"
                    clearable
                  >
                    <el-option
                      v-for="item in dictionList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.datavalue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </div>
            </el-col>

            <el-col :span="5">
              <el-form-item id="anniu">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="query"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  @click="rest"
                  icon="el-icon-refresh-right"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <el-table
        id="table"
        :data="repaymentList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
        <el-table-column prop="sss" label="序号" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.$index + 1 + (page.pageNo - 1) * page.pageSize
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
        <el-table-column
          prop="assetsId"
          label="贷款订单号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="name"
          label="贷款企业"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="fundSide"
          label="资金方"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="loanAmount"
          label="应还总金额"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.loanAmount / 100 }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="principalAmount"
          label="应还本金"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.principalAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="interestAmount"
          label="应还利息"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.interestAmount / 100 }}</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="creditInterestRate"
          label="利率"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.creditInterestRate / 10000 }}%/日</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="repaymentInterval"
          label="贷款期限"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.repaymentInterval }}天</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="time"
          label="还款时间"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="repaymentPlanState"
          label="还款状态"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in dictionList" v-bind:key="index">
              <div v-if="item.datavalue == scope.row.repaymentPlanState">
                {{ item.name }}
              </div>
            </div>
          </template>
        </el-table-column>

        <el-table-column label="操作" width="130" align="center">
          <template slot-scope="scope">
            <el-row :gutter="5">
              <el-col :span="9" :pull="1">
                <el-link
                  type="primary"
                  icon="el-icon-fetchByIdSysUserList"
                  @click="details(scope.row)"
                  :underline="false"
                  size="medium"
                  >详情</el-link
                >
              </el-col>

              <div v-if="scope.row.repaymentPlanState == 0">
                <el-col :span="15">
                  <el-link
                    :style="scope.row.isAdvanceRepayment == 0 ? 'color: #cdcdcd' : 'color: #18c38f'"
                    type="primary"
                    icon="el-icon-fetchByIdSysUserList"
                    :disabled="scope.row.isAdvanceRepayment == 0"
                    @click="Prepayment(scope.row)"
                    :underline="false"
                    size="medium"
                    v-if="funArr.indexOf('提前还款') !== -1"
                    >提前还款</el-link
                  >
                </el-col>
              </div>

              <div v-if="scope.row.repaymentPlanState == 1">
                <el-col :span="15">
                  <el-link
                    type="primary"
                    icon="el-icon-fetchByIdSysUserList"
                    v-loading.fullscreen.lock="fullscreenLoading"
                    element-loading-text="加载中"
                    @click="repayment(scope.row)"
                    :underline="false"
                    size="medium"
                    v-if="funArr.indexOf('还款') !== -1"
                    >还款</el-link
                  >
                </el-col>
              </div>

              <div
                v-if="
                  scope.row.repaymentPlanState == 3 ||
                  scope.row.repaymentPlanState == 2
                "
              >
                <el-col :span="15">
                  <el-link
                    style="color: #cdcdcd"
                    type="primary"
                    icon="el-icon-fetchByIdSysUserList"
                    :underline="false"
                    size="medium"
                    >还款成功</el-link
                  >
                </el-col>
              </div>

              <div
                v-if="
                  scope.row.repaymentPlanState == 5 ||
                  scope.row.repaymentPlanState == 7
                "
              >
                <el-col :span="15">
                  <el-link
                    style="color: #cdcdcd"
                    type="primary"
                    icon="el-icon-fetchByIdSysUserList"
                    :underline="false"
                    size="medium"
					v-if="funArr.indexOf('还款') !== -1"
                    >还款</el-link
                  >
                </el-col>
              </div>
            </el-row>
          </template>
        </el-table-column>
      </el-table>

      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="page.pageSize"
        :current-page="page.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="page.total"
      >
      </el-pagination>

      <el-dialog
        id="anniu"
        class="dialogBox"
        :close-on-click-modal="false"
        :title="title"
        :visible.sync="dialogVisibledetails"
      >
        <repayment-page
          v-on:close="close"
          ref="repayment"
          :repaymentInfo1="repaymentDiaLogRep"
        ></repayment-page>
      </el-dialog>
    </template>
    <!-- </div> -->
  </div>
</template>
<script>
//获取白名单列表
import repaymentPage from "./platformPay";
import { findPlatRepaymentPayList } from "@/service/moudules/platformApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
import { findNameCorpAndRisk } from "@/service/moudules/platformApi";
import { findPersonAndRisk } from "@/service/moudules/platformApi";
import { findActiveRepaymentInterest } from "@/service/moudules/platformApi";
import { repayment } from "@/service/moudules/platformApi";
import { findCorpAndRisk } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      funArr: [],
      repaymentReq: {
        name: "",
        repaymentPlanState: "",
        timeStart: "",
        timeEnd: "",
      },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },

      title: "",
      repaymentDiaLogRep: {
        name: "",
        bankNum: "",
        mobile: "",
        amuont: "",
        assetsId: "",
        bankName: "",
        useamt: "",
        compensator: "",
        operator: "",
        risk: "",
        newAmount1:"",
      },
      diction: {
        code: "repaymentState",
      },
      fullscreenLoading: false,
      paymentAmount: {},
      assets: {},
      assetsId: "",
      dialogVisibledetails: false,
      repaymentList: [],
      dictionList: [],
      riskCorp: {},
      riskPerson: {
        name: "",
        idno: "",
        mobile: "",
        bankcard: "",
      },
      accountBalance: {},
      newAmount:"",
      
    };
  },

  created: function () {
    this.findPlatRepaymentList();
    this.findDictionariesItemList(this.diction);
    this.menuPermission();
  },
  methods: {
	  query(){
      this.page.pageNo = 1;
      this.findPlatRepaymentList();
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    rest() {
      this.repaymentReq.repaymentPlanState = "";
      this.repaymentReq.timeStart = "";
      this.repaymentReq.timeEnd = "";
      this.repaymentReq.name = "";
      this.query();
    },

    async menuPermission() {
      let name = "平台还款";
      let params = {
        id: this.$getCookie("sessionId"),
        menuName: name,
      };
      let response = await this.api.platformApi.menuPermission(params);

      console.log(name + "页面功能列表start---");
      console.log(response.data.data);
      console.log(name + "页面功能列表end---");
      this.funArr = response.data.data;
      console.log(this.funArr);
    },

    async findPlatRepaymentList() {
      var params = {
        name: this.repaymentReq.name,
        repaymentPlanState: this.repaymentReq.repaymentPlanState,
        timeStart: this.repaymentReq.timeStart,
        timeEnd: this.repaymentReq.timeEnd,
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
        repaymentPlanNotInStateList: [4, 6],
      };
      let response = await this.api.platformApi.findPlatRepaymentPayList(
        params
      );
      console.log("列表结果", response.data);
      this.repaymentList = response.data.data.records;

      this.page.total = response.data.data.total;
    },

    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async details(row) {
      var nameParam = {
        name: row.assetsId,
      };
      let applyInfo = await this.api.platformApi.findNameCorpAndRisk(nameParam);

      console.log("这是applyInfo返回结果", applyInfo.data);
      var idParam = {
        id: applyInfo.data.data.risk,
      };
      let corpResponse = await this.api.platformApi.findCorpAndRisk(idParam);      
      console.log("这是详情公司返回结果", corpResponse.data);
      localStorage.setItem(
        "id",
        JSON.stringify({
          assetsId: row.assetsId,
          corpInfo: corpResponse.data.data,
          applyId: applyInfo.data.data.id,
        })
      );
      this.$router.push({ name: "platformRepaymentDetails" });
    },
    //查询公司
    async findCorp(name) {
      var nameParam = {
        name: name,
      };
      let corpResponse = await this.api.platformApi.findNameCorpAndRisk(
        nameParam
      );
      console.log("公司返回结果", corpResponse.data);
      this.riskCorp = corpResponse.data.data;
      console.log("公司赋值结果", this.riskCorp);
    },
    //查询法人
    async findRerson(id) {
      var idParam = {
        id: id,
      };
      let personResponse = await this.api.platformApi.findPersonAndRisk(
        idParam
      );
      this.riskPerson = {...personResponse.data.data};
      console.log("法人返回结果", personResponse.data);
    },
    //查询支付金额
    async findAmount(id) {
      var assetsIdParam = {
        id: id,
      };
      let assetsResponse = await this.api.platformApi.findActiveRepaymentInterest(
        assetsIdParam
      );
      console.log("支付金额返回结果", assetsResponse.data);
      this.paymentAmount = assetsResponse.data.data;
    },

    //查询可用余额
    async findAccountBalance() {
      let response = await this.api.platformApi.getPayAccountInfo();
      console.log("可用金额返回结果", response.data.data);
      if(response.data.data){
          this.repaymentDiaLogRep.useamt = response.data.data.useamt / 100;
          this.repaymentDiaLogRep.name = response.data.data.name;
          this.repaymentDiaLogRep.bankNum = response.data.data.bankNum;
          this.repaymentDiaLogRep.bankName =  response.data.data.bankName;
      }
      this.loadUserInfo();
    },
       // 加载用户信息
    async loadUserInfo(){
      await this.api.platformApi.getUserInfo({}).then(res => {
        let data = res.data;
        if(data.code == 200){
          this.repaymentDiaLogRep.mobile = data.data.mobile;
          // this.userInfo = data.data;
        }
      }).catch(err => {
        this.$message.error("用户手机号加载失败");
      })
    },
    async findCreditProofAssetsOutlineInfoByAssetsId(id){
var fundParam = {
        id: id
      };
      let response = await this.api.platformApi.findCreditProofAssetsOutlineInfoByAssetsId(fundParam);
      if(response.data.code!=200){
         this.$message({
            message: response.data.msg,
            type: "warning",
          });
          return
      }
       if (response.data.data == null) {
        this.newAmount = 0;
      } else {
        this.newAmount = response.data.data.totalNotPaidAmount/100;
        this.repaymentDiaLogRep.newAmount1= response.data.data.totalNotPaidAmount
      }
    },
    //带入还款弹窗数据
   async toData(row) {
      this.repaymentDiaLogRep.assetsId = row.assetsId;

     
      await this.findCreditProofAssetsOutlineInfoByAssetsId(row.assetsId)
      this.repaymentDiaLogRep.amuont =this.newAmount
      console.log("repaymentRep", this.repaymentDiaLogRep);
      this.repaymentDiaLogRep.compensator = row.compensator;
      this.repaymentDiaLogRep.operator = this.getCookie("sessionId");
      this.repaymentDiaLogRep.risk = this.riskCorp.risk;
    },
    //还款
    async repayment(row) {
      this.fullscreenLoading = true;
      console.log("还款接收参数", row);
      this.assetsId = row.assetsId;
      await this.findCorp(row.assetsId);
      // console.log("法人Id:", this.riskCorp.legal);
      // await this.findRerson(this.riskCorp.legal);

      await this.findAmount(row.assetsId);
      await this.findAccountBalance();
      await this.toData(row);

      this.title = "平台还款";
      this.fullscreenLoading = false;
      this.dialogVisibledetails = true;
    },
    //提前还款
    async Prepayment(row) {
      this.fullscreenLoading = true;
      console.log("提前还款接收参数", row);
      this.assetsId = row.assetsId;

      await this.findCorp(row.assetsId);
      // console.log("法人Id:", this.riskCorp.legal);
      // await this.findRerson(this.riskCorp.legal);
      await this.findAmount(row.assetsId);
      await this.findAccountBalance();
      await this.toData(row);

      this.title = "提前还款";
      this.fullscreenLoading = false;
      this.dialogVisibledetails = true;
    },
    close() {
      this.dialogVisibledetails = false;
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.findPlatRepaymentList();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.findPlatRepaymentList();
    },
  },
  components: {
    repaymentPage,
  },
};
</script>
<style scoped>
/* .repaylist_page_class {
  width: 1200px;
  height: 100%;
} */
#platformRepaymentList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#platformRepaymentList .marginCss {
  margin-top: 20px;
  margin-left: 6px;
}
#platformRepaymentList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#platformRepaymentList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}
</style>