<template>
  <div id="sysuserDetails">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <div>
          <el-form
            :model="ruleForm"
            ref="ruleForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <input type="hidden" v-model="ruleForm.id" />
            <el-form-item label="*账户" prop="account">
              <el-input v-model="ruleForm.account" disabled></el-input>
            </el-form-item>
            <el-form-item label="*用户名" prop="name">
              <el-input v-model="ruleForm.name" disabled></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
              <el-select v-model="ruleForm.gender" disabled>
                <el-option label="男" value="1"></el-option>
                <el-option label="女" value="0"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="联系方式" prop="mobile">
              <el-input v-model="ruleForm.mobile" disabled></el-input>
            </el-form-item>
            <el-form-item label="所属部门" prop="dep">
              <el-select v-model="ruleForm.gname" disabled></el-select>
            </el-form-item>
            <el-form-item label="所属角色" prop="role">
              <el-select v-model="ruleForm.rname" disabled></el-select>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["ruleForm"],
};
</script>
<style scoped>
#sysuserDetails .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
#sysuserDetails .avatar-uploader .el-upload:hover {
  border-color: #1d90ff;
}
#sysuserDetails .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
#sysuserDetails .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
#sysuserDetails .el-input > input {
  height: 36px;
  border-radius: 5px;
}
#sysuserDetails .el-select {
  width: 100%;
}
</style>