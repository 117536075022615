<template>
  <div id="sysdistribution">
    <el-row>
      <el-col :span="16">
        <el-tree
          ref="tree"
          default-expand-all
          :data="data"
          highlight-current
          show-checkbox
          @check-change="handleCheckChange"
          check-on-click-node
          node-key="index"
          :props="defaultProps"
          :model="ruleForm"
          class="demo-ruleForm"
        >
        </el-tree>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="primary" @click="addpermission('ruleForm')"
          >保存</el-button>
      </el-col>
    </el-row>
  </div>
</template>


<script>
//查询对应用户的权限
import { getMyUserPermission } from "@/service/moudules/platformApi";
//权限树状图
import { permissionList } from "@/service/moudules/platformApi";
//新增权限
import { addUserPermission } from "@/service/moudules/platformApi";
export default {
  props: ["user","tenant","syncPermission"],
  data() {
    return {
      ruleForm: {},
      treeMap: {},
      originArr: [],
      permissionData: {},
      permissionChild: {},
      userpermission: {
        user: this.user,
        organization: 1,
        permissions: "",
      },
      permissionCheckout: [],
      data: [],
      defaultProps: {
        children: "childModule",
        label: "currentModule",
      },
    };
  },
  created() {
    this.loadData();
    // this.loadMyUserPermission(this.user);
  },
  watch: {
    user: {
      // immediate: true,
      handler(val) {
        this.userpermission.user = val;
        this.loadMyUserPermission(val);
      },
    },
  },
  mounted: function () {
    this.userId = this.user;
    console.log(this.user);
  },
  methods: {
    handleCheckChange(data, c1, c2) {
      console.log(data, c1, c2);
    },
    async userPermission(userId) {
      var params = {
        user: userId,
      };
      if(this.tenant){  //tenant 不等于 undefined
        params.tenant = this.tenant;
      }
      let response = await this.api.platformApi.getMyUserPermission(params);
      // console.log(JSON.stringify(response))
      let data = response.data.data;
      let arr = [];
      for (var index in data) {
        for (var key in this.treeMap) {
          if (this.treeMap[key] == data[index].permission) {
            arr.push(parseInt(key));
          }
        }
      }

      // console.log(arr)
      this.originArr = arr;
      this.$refs.tree.setCheckedKeys(arr);
    },
    loadMyUserPermission(userId) {
      this.userPermission(userId);
    },
    //递归循环出所有的权限
    loadTreeData(data, i) {
      for (var index in data) {
        i++;
        data[index].index = i;
        if (data[index].childModule) {
          i = this.loadTreeData(data[index].childModule, i);
        }
      }
      return i;
    },

    //从底层节点递归查找所有权限
    async treesSaveData(data, appendStr) {
      for (var index in data) {
        var dataChildren = data[index];
        if (dataChildren.childModule) {
          this.treesSaveData(
            dataChildren.childModule,
            appendStr + "/" + dataChildren.currentModule
          );
        } else {
          var str = appendStr + "/" + dataChildren.currentModule;
          str = str.substring(1, str.length);
          this.treeMap[dataChildren.index] = str;
        }
      }
      this.loadMyUserPermission(this.user);
    },
    async permssion(userId) {
      var params = {
        sessionId: this.$getCookie("sessionId"),
      };
      let response = await this.api.platformApi.permissionList(params);
      this.data = response.data.data;
      console.log(this.data);
      this.loadTreeData(this.data, 0);
      this.treesSaveData(this.data, "");
    },
    loadData() {
      this.permssion();
    },
    async add() {
      let permissions = [];
      var indexs = this.$refs.tree.getCheckedKeys();
      for (var i in indexs) {
        let permissionName = this.treeMap[indexs[i]];
        if (permissionName) {
          permissions.push(permissionName);
        }
      }

      this.userpermission.permissions = permissions;
      // 这里是租户管理时自己传租户ID
      if(this.tenant){  //tenant 不等于 undefined
        this.userpermission.tenant = this.tenant;
      }
      // 这里是租户管理时，要同步子用户权限
      if(this.syncPermission){
        this.userpermission.syncPermission = this.syncPermission;
      }
      let response = await this.api.platformApi.addUserPermission(
        this.userpermission
      );
      this.originArr = indexs;
      this.$message({
        message: "分配成功",
        type: "success",
      });
    },
    addpermission() {
      this.add();
    },

    updateChild: function (obj) {
      this.permissionChild = obj;
    },

    resetForm() {
      this.$refs.tree.setCheckedKeys(this.originArr);
    }
  },
};
</script>


<style scoped>
#sysdistribution .i {
  padding: 20px;
  border: 1px solid;
}

#a {
  top: 30px;
}

#sysdistribution .el-button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>
