<template>
<div id="about">
    <div>系统说明</div>
</div>
</template>

<script>

export default {
  
};
</script>

<style scoped>

</style>