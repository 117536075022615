/**
 * 添加cookie域数据
 * @param {键名} cname 
 * @param {值} cvalue 
 * @param {存在时间，单位：天} exdays 
 */
function setCookie(cname, cvalue, exdays) {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}
/**
 * 获取cookie域数据
 * @param {键名} cname 
 */
function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    // console.log("获取cookie,现在循环")
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      // console.log(c)
      while (c.charAt(0) === " ") c = c.substring(1);
      if (c.indexOf(name) !== -1) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}
/**
 * 格式化cookie域数据
 * @param {键值} cname 
 */
function clearCookie(cname) {
    this.setCookie(cname, "", -1);
}

export default{
    setCookie,
    getCookie,
    clearCookie
}