<template>
  <div id="statementBillList">
    <template>
      <div>
        <el-form :inline="true" :model="statementBillReq" class="demo-form-inline">
          <el-row :gutter="0">
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="对账单编号:">
                  <el-input
                    v-model="statementBillReq.thirdParty"
                    class="el-select"
                    placeholder="请输入查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
            <el-col :span="6" align="left">
              <div class="grid-content bg-purple">
                <el-form-item label="对账用户名称:">
                  <el-input
                    v-model="statementBillReq.riskName"
                    class="el-select"
                    placeholder="请输入查询"
                  ></el-input>
                </el-form-item>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="0">
            <el-col style="width:34%">
              <el-form-item label="起始时间">
                <el-date-picker
                  v-model="statementBillReq.time"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="5">
              <el-form-item id="anniu">
                <el-button
                  type="primary"
                  icon="el-icon-search"
                  @click="query"
                  >查询</el-button
                >
                <el-button
                  type="primary"
                  @click="rest"
                  icon="el-icon-refresh-right"
                  >重置</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <div style="text-align: initial; margin-bottom: 10px" class="buttonbox">
        <el-button type="primary" icon="el-icon-plus" @click="add()">添加</el-button>
      </div>
      
      <el-table
        id="table"
        :data="statementBillList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
      >
      <el-table-column type="index"  label="序号" align="center" />
        <el-table-column
          prop="thirdParty"
          label="对账单编号"
          :show-overflow-tooltip="true"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="type"
          label="类型"
          :show-overflow-tooltip="true"
          align="center"
        >
          <template slot-scope="scope">
            <div v-for="(item, index) in statementBillType" v-bind:key="index" >
              <div v-if="item.datavalue == scope.row.type">
                {{  item.name }}
              </div>
            </div>
            </template>
        </el-table-column>

        <el-table-column
          prop="riskName"
          label="对账用户名称"
          :show-overflow-tooltip="true"
          align="center"
        />

        <el-table-column
          prop="generateExpense"
          label="对账单总费用"
          :show-overflow-tooltip="true"
          align="center"
        >
         <template slot-scope="scope">
            <span>{{accDiv(scope.row.generateExpense , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="settlingCharge"
          label="已结费用"
          :show-overflow-tooltip="true"
          align="center"
        >
         <template slot-scope="scope">
            <span>{{accDiv(scope.row.settlingCharge , 100)}}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="createTime"
          label="录入时间"
          :show-overflow-tooltip="true"
          align="center"
        />
        <el-table-column
          prop="bzCreateTime"
          label="创建时间"
          :show-overflow-tooltip="true"
          align="center"
        >
        </el-table-column>

        <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small"  @click="detail(scope.row)" style="color: #FFAC1D">详情</el-button>
              <el-button type="text" size="small"  @click="edit(scope.row)" style="color: #FFAC1D">修改</el-button>
              <el-button type="text" size="small"  @click="addPaymentPlan(scope.row.thirdParty)" style="color: #FFAC1D">新增应付帐</el-button>
            </template>
          </el-table-column>

      </el-table>

      <el-pagination
        id="page"
        background
        @size-change="handleSizeChange"
        @current-change="currentChange"
        :page-sizes="[10, 15, 20]"
        :page-size="statementBillReq.pageSize"
        :current-page="statementBillReq.pageNo"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>

      <el-dialog title="保存对账单" top="6vh" :visible.sync="dialogVisible" width="85%" class="roll-dialog">
            <el-form :model="dataForm" ref="dataForm" label-width="180px">
                <div id="button">
                  <el-row>
                    <el-col :span="1">
                      <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                        >基本信息</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="textTips">
                  <el-divider class="el-divider"></el-divider>
                </div>
              <el-row>
                <el-form-item label="对账单编号" style="display:inline-block" required>
                    <el-col :span="22">
                      <el-input v-if="save" v-model="dataForm.thirdParty"></el-input>
                      <el-input v-else v-model="dataForm.thirdParty" disabled></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="对账方主体类型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="dataForm.clientType" clearable>
                        <el-option v-for="item in subjectType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="对账方第三方用户主键" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.userThirdParty"></el-input>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="对账方证件号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.idno"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="账单名称" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.name"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="对账单类型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="dataForm.type" clearable>
                        <el-option v-for="item in statementBillType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="账单内容描述" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.content"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="产生费用" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.generateExpense"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="对账单状态" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="状态" v-model="dataForm.state" clearable>
                        <el-option v-for="item in statementBillState" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="状态描述" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.stateDescription"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="备注" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="dataForm.remark"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="关联作业单号" style="display:inline-block">
                    <el-col :span="22">
                      <el-select v-model="dataForm.taskBillNos" multiple filterable allow-create default-first-option  placeholder="请选择关联作业单号">
                        <el-option v-for="item in taskOptions" :label="item" :value="item" :key="item"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="关联订单号" style="display:inline-block;margin-left: -790px;">
                    <el-col :span="22">
                      <el-select v-model="dataForm.orderNos" multiple filterable allow-create default-first-option placeholder="请选择关联订单号">
                        <el-option v-for="item in orderOptions" :label="item" :value="item" :key="item"></el-option>
                      </el-select>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row v-if="dataForm.type!='' && dataForm.type!=undefined">
                <detail :dataForm="dataForm.contentData" ref="detail"></detail>
              </el-row>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="submitForm()">确 定</el-button>
            </div>
        </el-dialog>

        <el-dialog title="新增应付帐" top="6vh" :visible.sync="paymentPlandialogFormVisible" width="1300px" >
            <el-form :model="paymentPlan" ref="dataForm" label-width="180px">
                <div id="button">
                  <el-row>
                    <el-col :span="1">
                      <el-button type="primary" align="left" icon="el-icon-plus" class="button_margin_top button_size"
                        >基本信息</el-button
                      >
                    </el-col>
                  </el-row>
                </div>
                <div class="textTips">
                  <el-divider class="el-divider"></el-divider>
                </div>
              <el-row>
                <el-form-item label="付款方主体类型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="paymentPlan.initiatorType" clearable>
                        <el-option v-for="item in subjectType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="付款方证件号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="paymentPlan.initiatorIdno"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="付款方第三方用户主键" style="display:inline-block">
                    <el-col :span="22">
                      <el-input  v-model="paymentPlan.initiatorThirdParty"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="收款方主体类型" style="display:inline-block">
                  <el-col :span="22">
                    <el-select placeholder="类型" v-model="paymentPlan.acceptorType" clearable>
                        <el-option v-for="item in subjectType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
                      </el-select>
                  </el-col>
                </el-form-item>
                <el-form-item label="收款方证件号" style="display:inline-block">
                  <el-col :span="22">
                    <el-input v-model="paymentPlan.acceptorIdno"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="收款方第三方用户主键" style="display:inline-block">
                    <el-col :span="22">
                      <el-input  v-model="paymentPlan.acceptorThirdParty"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="付款金额" style="display:inline-block;margin-left: -390px;">
                  <el-col :span="22">
                      <el-input  v-model="paymentPlan.amount"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="付款时间" style="display:inline-block">
                  <el-col :span="22">
                      <el-date-picker value-format="yyyy-MM-dd HH:mm:ss"
                        v-model="paymentPlan.paymentTime"
                        type="datetime"
                        placeholder="选择日期">
                      </el-date-picker>
                    </el-col>
                </el-form-item>
              </el-row>

            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="paymentPlandialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="onSumbit()">确 定</el-button>
            </div>
        </el-dialog>

    </template>
    <!-- </div> -->
  </div>
</template>
<script>
import detail from './detail'

export default {
  components:{
    detail
  },
  data() {
    return {
      statementBillReq: {
        thirdParty: "",
        riskName:'',
        time:[],
        pageNo: 1,
        pageSize: 10,
      },
      dataForm:{},
      total: 0,
      statementBillList:[{id: "4fb96caeff85194bea29012dc9cd4e98",blockId: "",type: "G545435437",generateExpense: 29,settlingCharge: 90000,contentData: {reconciliationStatus:"3"},lastModificationTime: "2022-07-18 17:40:28"
                          ,state: "3",status: "2",proofName: "对账单1test",tenantId: "17744230bb704e05bde6a8b4fccc42da",riskId: "ed550b2dc2a4fdad596068f2a458afb6",userThirdParty: "300198",content: "ex occaecat exercitation commodo tempor"
                          ,thirdParty: "20220325-statementbill-1",enabled: 1,createTime: "2022-05-18 14:19:40",bzCreateTime: "2022-05-18 14:19:40",provider: "c38d23b092c3f2d9fc86a160bfb3b1ba"}],
      stateDiction: {
        code: "statementBillState",
      },
      typeDiction: {
        code: "statementBillType",
      },
      subjectDiction: {
        code: "subjectType",
      },
      subjectType:[],
      statementBillType:[],
      statementBillState:[],
      dialogVisible:false,
      save:false,
      dialogFormVisible:false,
      taskOptions:['12513','781239','16781628'],
      orderOptions:['231125','51324','23123'],
      paymentPlan:{},
      paymentPlandialogFormVisible:false
    };
  },

  created: function () {
    this.findDictionariesStatementBillTypeList(this.typeDiction);
    this.findDictionariesStatementBillStateList(this.stateDiction);
    this.findDictionariesSubjectTypeList(this.subjectDiction)
  },
  mounted: function (){
    
  },
  methods: {
    query(){
      this.loadTable()
    },
    rest(){
      this.statementBillReq.thirdParty=''
      this.statementBillReq.riskName=''
      this.statementBillReq.time=[]
      this.loadTable()
    },
    loadTable(){

    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handleSizeChange(val) {
      this.statementBillReq.pageSize = val;
      this.loadTable()
    },
    currentChange(pageNo) {
      this.statementBillReq.pageNo = pageNo;
      this.loadTable()
    },
    //修改
    edit(row){
      this.save = false 
      this.dialogVisible = true
      this.dataForm = row
      console.log(this.dataForm);
    },
    submitForm(){
      this.dialogVisible = false
      console.log(this.dataForm);
    },
    //添加
    add(){
      this.dataForm = {}
      this.dialogVisible = true
      this.save = true 
    },
    async findDictionariesStatementBillTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.statementBillType = response.data.data;
		},
    async findDictionariesStatementBillStateList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典xxx", response);
				this.statementBillState = response.data.data;
		},
    async findDictionariesSubjectTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.subjectType = response.data.data;
		},
    detail(row){
      this.$router.push({path: '/statementBillDetail',query: {statementBill: row}})
    },
    addPaymentPlan(thirdParty){
      this.paymentPlan = {}
      this.paymentPlan.thirdParty = thirdParty
      this.paymentPlandialogFormVisible = true
    },
    //提交应付帐
    onSumbit(){
      this.paymentPlandialogFormVisible = false
      console.log(this.paymentPlan);
    }
  },
};
</script>
<style scoped>
#statementBillList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#statementBillList .marginCss {
  margin-top: 20px;
  margin-left: 6px;
}
#statementBillList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#orderList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}
.buttonbox button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.roll-dialog ::v-deep .el-dialog .el-dialog__body {
    padding: 3px 30px;
    overflow-y: scroll;
    height: 600px;
 }
</style>