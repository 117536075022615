<template>
  <div id="preliminaryReviewOfLoansDetails" v-loading="loading">
    <el-row :gutter="12">
      <el-col :pull="11">
        <span>借款信息</span>
      </el-col>
    </el-row>
    <div class="table">
      <div class="padding_class">
        <table>
          <tr>
            <td>贷款编号</td>
            <td>{{ loaninformationRep.assetsId }}</td>
          </tr>
          <tr>
            <td>申请时间</td>
            <td>{{ loaninformationRep.applicationTime }}</td>
          </tr>
          <tr>
            <td>放款时间</td>
            <td>{{ loaninformationRep.lendingTime }}</td>
          </tr>
          <tr>
            <td>到期时间</td>
            <td>{{ loaninformationRep.expectedEndDate }}</td>
          </tr>
          <tr>
            <td>贷款产品</td>
            <td>{{ loaninformationRep.productName }}</td>
          </tr>
          <tr>
            <td>贷款金额</td>
            <td>{{ accDiv(loaninformationRep.loanAmount ,100) }}</td>
          </tr>
          <tr>
            <td>贷款利率</td>
            <td>{{ accDiv(loaninformationRep.creditInterestRate , 10000) }}%/日</td>
          </tr>
          <tr>
            <td>手续费率</td>
            <td>{{ accDiv(loaninformationRep.serviceChargeRate , 10000) }}%</td>
          </tr>
          <tr>
            <td>手续费</td>
            <td>
              {{ accDiv(loaninformationRep.expectedServiceCharge , 100) }}
            </td>
          </tr>
          <tr>
            <td>贷款期限</td>
            <td>{{ loaninformationRep.repaymentInterval }}天</td>
          </tr>
          <tr>
            <td>预计利息</td>
            <td>
              {{ accDiv(loaninformationRep.expectedInterest , 100) }}
            </td>
          </tr>
          <tr>
            <td>已收利息</td>
            <td>{{ accDiv(loaninformationRep.interestReceived , 100) }}</td>
          </tr>
          <tr>
            <td>还款方式</td>
            <td>
              <div
                v-for="(item, index) in loanTypeDictionList"
                v-bind:key="index"
              >
                <div v-if="item.datavalue == loaninformationRep.loanType">
                  {{ item.name }}
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>通知确权书</td>
            <!-- <td></td> -->
            <td><a style="color: blue">查看</a></td>
          </tr>
          <tr>
            <td>借款合同</td>
            <!-- <td></td> -->
            <td><a style="color: blue">查看</a></td>
          </tr>
        </table>
      </div>
      <div class="marginCss">
        <el-row :gutter="12">
          <div>
            <el-col :pull="11">
              <div class="padding_main1_class">本订单可使用应收帐及额度</div>
            </el-col>
          </div>
        </el-row>
      </div>
      <div>
        <el-table
          class="personTable"  
          :data="OrderCollectionAndQuotaList"
          @selection-change="handleSelectionChange"
          :header-cell-style="tableHeader"
          ref="OrderCollectionAndQuotaList"
          border
          >
          <el-table-column type="selection" />
          <el-table-column
            label="序号"
            type="index"
            :show-overflow-tooltip="true"
            width="50"
           align="center">
          </el-table-column>
          <el-table-column
            prop="thirdParty"
            label="应收帐编号"
            :show-overflow-tooltip="true"
           align="center">
          </el-table-column>
          <el-table-column
            prop="initiatorName"
            label="付款方"
            :show-overflow-tooltip="true"
           align="center">
          </el-table-column>
          <el-table-column
            prop="acceptorName"
            label="收款方"
            :show-overflow-tooltip="true"
           align="center" />
           <el-table-column
            prop="amount"
            label="付款金额"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ accDiv(scope.row.amount , 100) }}</span>
           </template>
           </el-table-column>
           <el-table-column
            prop="lineCredit"
            label="授信额度"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ accDiv(scope.row.lineCredit , 100) }}</span>
           </template>
           </el-table-column>
           <el-table-column
            prop="residueCredit"
            label="剩余可用额度"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
             <span>{{ accDiv(scope.row.residueCredit , 100) }}</span>
           </template>
           </el-table-column>
           <el-table-column
            prop="address"
            label="确权额度"
            :show-overflow-tooltip="true"
           align="center" >
           <template slot-scope="scope">
            <el-input
              placeholder="确权金额"
              v-model="scope.row.authenticLight"
            ></el-input>
          </template>
           </el-table-column>
           <el-table-column
            prop="expireDate"
            label="付款时间"
            :show-overflow-tooltip="true"
           align="center" >
           <!-- <template slot-scope="scope">
            <el-date-picker
            v-model="scope.row.expireDate"
            type="date"
            value-format="yyyy-MM-dd"
            :disabled="scope.row.enabled != '1'"
            :picker-options="{
              disabledDate(time) {
                return (time.getTime() < Date.now()) || 
                (time.getTime() > new Date(loaninformationRep.expectedEndDate).getTime());
              }
            }"
            placeholder="选择日期">
          </el-date-picker>
          </template> -->
           </el-table-column>
        </el-table>
      </div>

      <div class="marginCss">
        <el-row :gutter="0">
          <el-col :pull="11">
            <span>供应商信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table">
        <div class="padding_class">
          <table>
            <tr>
              <td>供应商企业名称</td>
              <td>{{ corp.name }}</td>
            </tr>
            <tr>
              <td>法人姓名</td>
              <td>{{ corp.legalName }}</td>
            </tr>
            <tr>
              <td>社会统一信用代码</td>
              <td>{{ corp.idno }}</td>
            </tr>
            <tr>
              <td>经营地址</td>
              <td>{{ corp.businessAddress }}</td>
            </tr>
            <tr>
              <td>收款银行账号</td>
              <td>{{ corp.branchNo }}</td>
            </tr>
            <tr>
              <td>收款银行全称</td>
              <td>{{ corp.branchName }}</td>
            </tr>
            <tr>
              <td>开户银行省、市</td>
              <td>{{ corp.provinceOperation + corp.cityOperation }}</td>
            </tr>
            <tr>
              <td>实名认证</td>
              <td>已认证</td>
            </tr>
            <tr>
              <td>电子签章</td>
              <td>已开通</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="marginCss">
        <el-row :gutter="5">
          <el-col :pull="11">
            <span>法人信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="table">
        <div class="padding_class">
          <table>
            <tr>
              <td>法人姓名</td>
              <td>{{ riskPerson.name }}</td>
            </tr>
            <tr>
              <td>身份证号</td>
              <td>{{ riskPerson.idno }}</td>
            </tr>
            <tr>
              <td>手机号</td>
              <td>{{ riskPerson.mobile }}</td>
            </tr>
            <tr>
              <td>银行卡号</td>
              <td>{{ riskPerson.bankcard }}</td>
            </tr>
            <tr>
              <td>实名认证</td>
              <td>已认证</td>
            </tr>
            <tr>
              <td>电子签章</td>
              <td>已开通</td>
            </tr>
          </table>
        </div>
      </div>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :pull="11">
            <span>初审信息</span>
          </el-col>
        </el-row>
      </div>

      <div class="fuInfo">
        <el-row :gutter="12">
          <el-col :span="2">
            <span>初审结果:</span>
          </el-col>
          <el-col :span="1">
            <el-radio v-model="radio" label="1">通过</el-radio>
          </el-col>
          <el-col :span="1">
            <el-radio v-model="radio" label="2">驳回</el-radio>
          </el-col>
        </el-row>
      </div>

      <div class="marginCss">
        <el-row :gutter="12">
          <el-col :span="2">
            <span>初审意见:</span>
          </el-col>
          <el-col :span="8">
            <textarea
              class="el-textarea__inner"
              v-model="textarea"
              placeholder="请输入内容"
            >
            </textarea>
            <!-- <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              v-model="textarea"
            ></el-input> -->
          </el-col>
        </el-row>
      </div>

      <div class="marginCss">
        <el-row :gutter="30">
          <el-col :span="4">
            <div class="buttonCss">
              <el-button type="primary" @click="submit">提交</el-button>
              <el-button @click="close">关闭</el-button>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script>
import { forEach, number } from 'mathjs';

export default {
  data() {
    return {
      loading: false,
      value1: "",
      textarea: "",
      radio: "1",
      loaninformationRep: {
        assetsId: "",
        productName: "",
        loanAmount: 0,
        repaymentInterval: 0,
        creditInterestRate: 0,
        principalAmount: 0,
        createTime: "",
        lendingTime: "",
        time: "",
        loanType: 0,
        expectedInterest: 0,
        interestReceived: 0,
        expectedServiceCharge: 0,
        serviceChargeRate: 0,
      },
      corp: {
        name: "",
        legalName: "",
        idno: "",
        businessAddress: "",
        branchNo: "",
        branchName: "",
        provinceOperation: "",
        cityOperation: "",
      },
      useCreditProofParam: {
        ProofId: "",
        rightConfirmationAmount: 0,
      },
      modificationOfRepaymentDateReq: {},
      creditInvoiceInfo: [],
      repaymentPlan: [],
      accountsAmountList:[],
      
      multipleSelection: [],
      useCreditProofParams: [],
      modificationOfRepaymentDateReqs: [],
      diction: {
        code: "applyLogType",
      },
      loanTypeDiction: {
        code: "loanType",
      },
      loanTypeDictionList: [],
      riskPerson: {
        name: "",
        idno: "",
        mobile: "",
        bankcard: "",
      },
      dictionList: [],
      applyList: [],
      firstInstance: [],
      toInstance: [],
      loan: [],
      arr: [],
      applyId: "",
      OrderCollectionAndQuotaList: [],
      selectedOrderCollectionAndQuotaList: [],
    };
  },
  mounted: function () {
    console.log("sess", this.getCookie("sessionId"));
    let param = JSON.parse(localStorage.getItem("id"));
    console.log("接收参数", param);
    this.applyId = param.applyId;
    if (null != param.corpInfo) {
      this.corp = param.corpInfo;
    }
    if (param.id != null) {
      this.findLoaninformationRep(param.id);
    }

    if (null != param.corpInfo && null != param.corpInfo.id) {
      this.findCreditInvoiceInfo(param.corpInfo.id);
    }
    this.findLoanTypeDictionList(this.loanTypeDiction);
    // this.a(1);
    // this.findRepaymentPlan(param.assetsId);
    // this.findDictionariesItemList(this.diction);
    // this.findByApplicantApplyAndType(param.id);
    this.findRerson(param.corpInfo.legal);
  },
  methods: {
    // 查询应付帐及额度
   async getOrderCollectionAndQuota(){
      let param = {
        profileId: this.corp.id,
        applyId: this.applyId,
        endDate: this.loaninformationRep.expectedEndDate,
        loanAmount: this.loaninformationRep.loanAmount
      }
       await this.api.paymentPlanApi.getOrderCollectionAndQuota(param).then(res => {
         let data = res.data;
         if(data.code == 200 && data.data != null){
           this.OrderCollectionAndQuotaList = data.data;
           this.OrderCollectionAndQuotaList.forEach(row => {
             row.authenticLight = 0;
             if(row.equalingamount !=null){
               row.authenticLight = this.accDiv(Number(row.equalingamount) , 100);
             }
           })
         }else{
           this.OrderCollectionAndQuotaList = []
         }
        // if(this.OrderCollectionAndQuotaList !=null && this.OrderCollectionAndQuotaList.length >0){
        //   let ratio = this.OrderCollectionAndQuotaList[0].invoiceOccupyRate;
        //   this.equalingAmount(ratio);
        // }
         this.orderCollectionAndQuotaListSelecd();
       })
    },
    // 设置确权金额
    equalingAmount(ratio){
      let amount = this.loaninformationRep.loanAmount;
      amount = this.accDiv(this.accDiv(Number(amount) , (Number(ratio)) , 1000000));
      this.OrderCollectionAndQuotaList.forEach(row => {
        if(amount <= 0){
          row.authenticLight = 0
        }else{
          let authentic = Number(row.authenticLight);
          if(authentic>=amount){
            row.authenticLight = this.accDiv(amount , 100);
            amount = 0;
          }else{
            amount = amount - authentic; 
            row.authenticLight = this.accDiv(authentic , 100);
          }
        }

      })
    },
    // 应付帐选项变化
    handleSelectionChange(val){
      this.selectedOrderCollectionAndQuotaList = val;
    },
    
    orderCollectionAndQuotaListSelecd(){
      this.$nextTick(() => {
        this.OrderCollectionAndQuotaList.forEach(row => {
          if (Number(row.authenticLight) > 0) {
            this.$refs.OrderCollectionAndQuotaList.toggleRowSelection(
              row
            );
          }
        })
      });
    },
    //根据id查询申请
    async findApplyById(id) {
      console.log("查询申请参数:", id);
      var param = {
        id: id,
      };
      let response = await this.api.postLoanManagementApi.findApplyById(param);
      if (response.data.code === 200) {
        if (response.data.data.state === 0) {
          console.log("进入初审");
          if (this.radio == 1) {
            console.log("初审通过");
            this.passedThePreliminaryExamination();
          }
          if (this.radio == 2) {
            console.log("初审拒绝");
            this.rejectionOfPreliminaryTrial();
          }
          return;
        }
        this.$message.success("该比贷款已操作,请返回初审列表");
      }
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    //判断使用金额是否超出可用金额和判断总的使用金额是否等于贷款金额
    money() {
      //贷款金额
      var loanAmount = this.accDiv(this.loaninformationRep.loanAmount , 100);
      console.log("loanAmount", loanAmount);
      //使用额度
      var usageLimit = 0;
      for (var i = 0; i < this.multipleSelection.length; i++) {
        //剩余可使用金额
        var remainingAvailableAmount =
          (this.accDiv(this.accMul(this.multipleSelection[i].amountWithTax ,
            this.multipleSelection[i].invoiceOccupyRate)) ,
            100000000) -
          accDiv(this.multipleSelection[i].notPaidAmount , 100);
        if (remainingAvailableAmount < this.multipleSelection[i].amountUsed) {
          console.log("使用金额大于剩余可用金额");
          return 1;
        }
        usageLimit += this.multipleSelection[i].amountUsed;
        console.log("money", this.multipleSelection[i].amountUsed);
      }
      if (usageLimit != loanAmount) {
        console.log("使用金额总额不等于贷款金额");
        return 2;
      }
    },
    //日期范围
    dateRange(value, scope) {
      console.log("scope", scope);
      var remindTime = scope.row.finalPaymentDate;
      var str = remindTime.toString(); // toString
      str = str.replace("/-/g", "/"); //去空格字符等
      var oldTime = new Date(str).getTime(); //装date

      var remindTime2 = scope.row.lastRepaymentTime;
      var str2 = remindTime2.toString(); // toString
      str2 = str2.replace("/-/g", "/"); //去空格字符等
      var oldTime2 = new Date(str2).getTime(); //装date

      if (oldTime > oldTime2) {
        this.$message.error("大于最大还款日");
        console.log("大于最大还款日");
      }
    },
    //是否修改
    modifyOrNot(initialDate, finalDate) {
      var num = -1;
      var remindTime = initialDate;
      var str = remindTime.toString(); // toString
      str = str.replace("/-/g", "/"); //去空格字符等
      var oldTime = new Date(str).getTime(); //装date

      var remindTime2 = finalDate;
      var str2 = remindTime2.toString(); // toString
      str2 = str2.replace("/-/g", "/"); //去空格字符等
      var oldTime2 = new Date(str2).getTime(); //装date

      if (oldTime > oldTime2) {
        num = 1;
        console.log("initialDate大于finalDate");
      }
      if (oldTime == oldTime2) {
        num = 0;
        console.log("initialDate等于finalDate");
      }
      if (oldTime < oldTime2) {
        num = 2;
        console.log("initialDate小于finalDate");
      }
      return num;
    },
    submit() {
 
      // for (let row of this.multipleSelection) {
      //   let maxRightConfirmationAmount =
      //     (row.amountWithTax - row.notPaidAmount - row.paidAmount) / 100;
      //   console.log("最大确权金额", maxRightConfirmationAmount);
      //   console.log("转化当前确权金额");

      //   if (isNaN(row.rightConfirmationAmount)) {
      //     this.$message.error("请输入正确的确权金额");
      //     return;
      //   }

      //   row.rightConfirmationAmount = Number(row.rightConfirmationAmount);
      //   console.log("当前确权金额", row.rightConfirmationAmount);
      //   if (row.rightConfirmationAmount > maxRightConfirmationAmount) {
      //     this.$message.error("确权金额超过限制");
      //     return;
      //   }
      // }

      // console.log("this.multipleSelection", this.multipleSelection);

      this.findApplyById(this.applyId);
    },
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },

    selectionLineChangeHandle(val) {
      console.log("val====", val);
      this.multipleSelection = val;
    },
    //初审通过
    async passedThePreliminaryExamination() {
      // console.log("kan========", JSON.stringify(this.multipleSelection));
      // this.useCreditProofParams = [];
      // this.modificationOfRepaymentDateReqs = [];
      // console.log("ttt", this.multipleSelection);
      // for (var i = 0; i < this.multipleSelection.length; i++) {
      //   var initialDate = this.multipleSelection[i].paymentDate; //初始日期
      //   var finalDate = this.multipleSelection[i].finalPaymentDate; //最终日期

      //   this.useCreditProofParam = {};
      //   this.useCreditProofParam.proofId = this.multipleSelection[i].id;
      //   this.useCreditProofParam.rightConfirmationAmount = Number(
      //     math.format(
      //       this.multipleSelection[i].rightConfirmationAmount * 100,
      //       14
      //     )
      //   );
      //   console.log("(this.useCreditProofParam", this.useCreditProofParam);
      //   this.useCreditProofParams.push(this.useCreditProofParam);

      //   var num = this.modifyOrNot(initialDate, finalDate);
      //   console.log("num", num);
      //   if (num != 0) {
      //     console.log("进来了");
      //     this.modificationOfRepaymentDateReq = {};
      //     this.modificationOfRepaymentDateReq.id = this.multipleSelection[i].id;
      //     this.modificationOfRepaymentDateReq.paymentDate =
      //       this.multipleSelection[i].finalPaymentDate;
      //     this.modificationOfRepaymentDateReqs.push(
      //       this.modificationOfRepaymentDateReq
      //     );
      //   }
      //   console.log(
      //     "useCreditProofParams:" + JSON.stringify(this.useCreditProofParams)
      //   );
      //   console.log(
      //     "modificationOfRepaymentDateReqs:" +
      //       JSON.stringify(this.modificationOfRepaymentDateReqs)
      //   );
      // }
      // var param = {
      //   useCreditProofParams: this.useCreditProofParams,
      //   modificationOfRepaymentDateReqs: this.modificationOfRepaymentDateReqs,
      //   assets: JSON.parse(localStorage.getItem("id")).id,
      //   risk: JSON.parse(localStorage.getItem("id")).corpInfo.id,
      //   reviewer: this.getCookie("sessionId"),
      //   opinion: this.textarea,
      // };

      // let response =
      //   await this.api.postLoanManagementApi.passedThePreliminaryExamination(
      //     param
      //   );

           // 验证确权金额是否大于剩余的确权金额
      if(this.selectedOrderCollectionAndQuotaList.length <= 0){
        this.$message.error("请选择应付帐及额度");
        return;
      }
      let arr = new Array();
       for (let row of this.selectedOrderCollectionAndQuotaList) {
        if(isNaN(row.authenticLight)){
          this.$message.error("请输入正确的确权金额");
          return;
        }
        let item = {
          proofId: row.proof,
          rightConfirmationAmount: this.accMul(Number(row.authenticLight),100),
          // paymentDate: row.enabled != '1'? '' : row.expireDate,
          creditProofId: row.creditProofId
        }
        arr.push(item);
      }
      this.loading = true;
      let param = {
        assetsId: JSON.parse(localStorage.getItem("id")).id,
        opinion: this.textarea,
        useProofParams: arr
      }
      let response =
        await this.api.paymentPlanApi.passThePreliminaryReviewReq(
          param
        );
      if (response.data.code !== 200) {
        this.$message.error(response.data.msg);
        this.loading = false;
        this.$router.push({ name: "preliminaryReviewOfLoansList" });
        return;
      }else{
         this.loading = false;
        this.$router.push({ name: "loanReviewList" });
      }
    },
    //初审拒绝
    async rejectionOfPreliminaryTrial() {
      var param = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        reviewer: this.getCookie("sessionId"),
        opinion: this.textarea,
      };
      let response =
        await this.api.postLoanManagementApi.rejectionOfPreliminaryTrial(param);
      console.log("初审拒绝返回结果", response.data);
      if (
        response.data.code !== 200 &&
        response.data.msg === "正在处理初审拒绝中,请稍后再尝试"
      ) {
        this.$message.success(response.data.msg);
        return;
      }
      if (response.data.data == true || response.data.data == false) {
        this.loading = false;
        this.$router.push({ name: "preliminaryReviewOfLoansList" });
      }
    },

    async findLoaninformationRep(assetsId) {
      var param = {
        assets: assetsId,
      };
      let response =
        await this.api.postLoanManagementApi.findLoaninformationRep(param);
      console.log("资产还款返回结果", response.data);
      if (null != response.data.data) {
        this.loaninformationRep = response.data.data;
        this.getOrderCollectionAndQuota();
      }

      console.log("赋值结果:", this.loaninformationRep);
    },
    async findRerson(id) {
      var idParam = {
        id: id,
      };
      let personResponse = await this.api.platformApi.findPersonAndRisk(
        idParam
      );
      if (null != personResponse.data.data) {
        this.riskPerson = {...personResponse.data.data};
      }
      console.log("法人返回结果", personResponse.data);
    },
    async findCreditInvoiceInfo(riskId) {
      var param = {
        riskId: riskId,
      };
      let response = await this.api.platformApi.findCreditInvoiceInfo(param);
      console.log("发票返回结果", response.data);
      this.creditInvoiceInfo = response.data.data;
      console.log("发票赋值集合", this.creditInvoiceInfo);
    },
    async findRepaymentPlan(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.postLoanManagementApi.findRepaymentPlan(
        param
      );
      console.log("还款计划返回结果", response.data);
      this.repaymentPlan = response.data.data;
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典", response);
      this.dictionList = response.data.data;
    },
    async findLoanTypeDictionList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("还款状态数据字典", response);
      this.loanTypeDictionList = response.data.data;
    },
    async findByApplicantApplyAndType(assetsId) {
      var param = {
        id: assetsId,
      };
      let response = await this.api.platformApi.findByApplicantApplyAndType(
        param
      );
      if (response.data.data == null) {
        return;
      }
      console.log("初审复审结果", response);
      this.applyList = response.data.data;
      for (var i = 0; i < this.applyList.length; i++) {
        console.log("类型", this.applyList[i].type);
        if (this.applyList[i].type == 1) {
          this.firstInstance.push(this.applyList[i]);
          console.log("初审结果", this.firstInstance);
        }
        if (this.applyList[i].type == 2) {
          this.toInstance.push(this.applyList[i]);
          console.log("复审结果", this.toInstance);
        }
        if (this.applyList[i].type == 3) {
          this.loan.push(this.applyList[i]);
          console.log("放款结果", this.loan);
        }
      }
    },
    async updateStateById() {
      // console.log(this.formInline)
      // debugger;
      var params = {
        assets: JSON.parse(localStorage.getItem("id")).id,
        applyState: this.radio,
        reviewer: this.getCookie("sessionId"),
        opinion: this.textarea,
      };
      let response = await this.api.postLoanManagementApi.updateStateById(
        params
      );
      console.log("==========", JSON.stringify(response.data));
      console.log(response.data);
    },
    async guarantorConfirm() {
      var params = {
        id: JSON.parse(localStorage.getItem("id")).id,
      };
      let response = await this.api.postLoanManagementApi.guarantorConfirm(
        params
      );
      console.log("==========", JSON.stringify(response.data));
      console.log(response.data);
      this.$router.go(-1);
    },
    close() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
#preliminaryReviewOfLoansDetails .fuInfo {
  margin-top: 5px;
}

#preliminaryReviewOfLoansDetails {
  background-color: white;
  padding: 15px 0 100px;
}
#preliminaryReviewOfLoansDetails table,
td {
  border: 1px solid #e8e8e8;
  border-collapse: collapse;
  line-height: 35px;
  margin-left: 30px;
  margin-top: 3px;
}
table {
  width: 90%;
}
td:nth-child(odd) {
  background: #fafafa;
  width: 13%;
  text-align: center;
  font-size: 13px;
}
td:nth-child(even) {
  text-align: left;
  color: #7c7c7c;
  font-size: 13px;
}
#personTable {
  width: 90%;
  margin-top: 15px;
  margin-left: 30px;
  background-color: rgba(29, 144, 255, 0);
}
.personTable {
  width: 90%;
  margin-top: 15px;
  margin-left: 30px;
  background-color: rgba(29, 144, 255, 0);
}
#preliminaryReviewOfLoansDetails span {
  font-size: 15px;
}
#preliminaryReviewOfLoansDetails .marginCss {
  margin-top: 35px;
}
#preliminaryReviewOfLoansDetails .leftCss {
  margin-right: 27px;
}
#preliminaryReviewOfLoansDetails .buttonCss {
  border-radius: 5px;
}
.buttonCss button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
.padding_class {
  margin-top: 15px;
}
.padding_main1_class {
  padding-left: 110px;
  /* float: left; */
}
</style>