<template>
	<div class="login-container">
		<el-form :model="ruleForm2" :rules="rules" status-icon ref="ruleForm2" label-position="left" label-width="0px"
			class="demo-ruleForm login-page">
			<h3 class="title">系统登录</h3>
			<el-form-item prop="account">
				<el-input type="text" v-model="ruleForm2.account" auto-complete="off" placeholder="请输入账号/邮箱/手机号/身份证">
				</el-input>
			</el-form-item>
			<el-form-item prop="salted">
				<el-input type="password" v-model="ruleForm2.salted" auto-complete="off" placeholder="密码"></el-input>
			</el-form-item>
			<el-form-item>
				<el-button type="primary" style="width: 100%" @click="submitForm('ruleForm2')">登录</el-button>
			</el-form-item>
		</el-form>
	</div>
</template>

<script>
	export default {
		name: "login",
		data() {
			return {
				url: "",
				ruleForm2: {
					account: "",
					salted: "",
					tenantId: "",
				},
				rules: {
					account: [{
						required: true,
						message: "请输入账号/邮箱/手机号/身份证",
						trigger: "blur",
					}, ],
					salted: [{
						required: true,
						message: "请输入密码",
						trigger: "change",
					}, ],
				},
			};
		},
		created() {
			this.cacheTenantId();
			this.clearCookie("sessionId");
			this.clearCookie("name");
			var that = this;
			document.onkeydown = function(e) {
				var key = window.event.keyCode;
				if (key === 13) {
					that.submitForm('ruleForm2'); // 触发事件
				}
			};
		},
		activated(){
			this.cacheTenantId();
		},
		methods: {
			cacheTenantId(){
				localStorage.clear();
				let tenantId = this.$route.params.tenantId;
				localStorage.setItem("tenantId",tenantId);
				// 清空cookie （sessionId）
				document.cookie = '';
			},
			async submitForm(formName) {
				await this.$refs[formName].validate((valid) => {
					if (valid) {
						this.userloginApi();
					} else {
						return false;
					}
				});
			},
			async userloginApi() {

				this.ruleForm2.tenantId = localStorage.getItem('tenantId');
				let response = await this.api.platformApi.userlogin(this.ruleForm2);
				let data = response.data;
				console.log(data.code != 200);
				if(data==null || data.code != 200){
					this.$message.error(data==null?"登录失败请检查帐号密码":data.msg);
				}else{
				var s = JSON.stringify(response.data.data);
				// if (response.data.data[0] == "false") {
				// 	this.$message.error("登录失败请检查帐号密码!");
				// } else if (response.data.data[0] == "disable") {
				// 	this.$message.error("对不起，该用户已被停用!");
				// } 
				// else {
					window.localStorage.setItem("platform", response.data.data[2]);

					this.setCookie("sessionId", response.data.data[0], 1);
					this.setCookie("name", response.data.data[1], 1);
					this.$message({
						message: "登录成功",
						type: "success",
					});
					this.$router.replace({
						path: "/menutree",
					});
				}
			},
			resetForm(formName) {
				this.$refs[formName].resetFields();
			},
			setCookie: function(cname, cvalue, exdays) {
				let d = new Date();
				d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
				let expires = "expires=" + d.toUTCString();
				document.cookie = cname + "=" + cvalue + "; " + expires;
			},
			getCookie: function(cname) {
				let name = cname + "=";
				let ca = document.cookie.split(";");
				// console.log("获取cookie,现在循环")
				for (let i = 0; i < ca.length; i++) {
					let c = ca[i];
					// console.log(c)
					while (c.charAt(0) === " ") c = c.substring(1);
					if (c.indexOf(name) !== -1) {
						return c.substring(name.length, c.length);
					}
				}
				return "";
			},
			clearCookie: function(cname) {
				this.setCookie(cname, "", -1);
			},
		},
	};
</script>

<style scoped>
	.login-container {
		width: 100%;
		height: 100%;
	}

	.login-page {
		-webkit-border-radius: 5px;
		border-radius: 5px;
		margin: 180px auto;
		width: 350px;
		padding: 35px 35px 15px;
		background: #fff;
		border: 1px solid #eaeaea;
		box-shadow: 0 0 25px #cac6c6;
	}

	label.el-checkbox.rememberme {
		margin: 0px 0px 15px;
		text-align: left;
	}
</style>
