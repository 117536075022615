<template>
	<div class="search">
		<div class="quanju">
		<template>
			<el-form :inline="true" :model="formInline" class="demo-form-inline">
				<el-row :gutter="24">
					<el-col :span="8" align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="信用代码:">
								<el-input v-model="formInline.code" placeholder="请输入信用代码查询"></el-input>
							</el-form-item>
						</div>
						</el-col>

						<el-col :span="12" align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="创建时间:">
								<el-date-picker v-model="formInline.createTimeStart" type="date" placeholder="起" editable pattern="yyyy-MM-dd"
								 value-format="yyyy-MM-dd"></el-date-picker>-
								<el-date-picker v-model="formInline.createTimeEnd" type="date" placeholder="止" editable pattern="yyyy-MM-dd"
								 value-format="yyyy-MM-dd"></el-date-picker>
							</el-form-item>
						</div>
						
						</el-col>

					
					 
					
				</el-row>
				<el-row :gutter="24">
                       <el-col :span="8" align="left">
						<div class="username">
							<el-form-item label="用户名称:">
								<el-input v-model="formInline.oname" placeholder="请输入用户名称查询"></el-input>
							</el-form-item>
						</div>
					</el-col>
						<el-col :span="10" align="left">
						<div class="grid-content bg-purple">
							<el-form-item label="账户类型:">
								<el-select v-model="formInline.walletType" clearable placeholder="请选择" >
								<el-option
											v-for="item in itemList"
											:key="item.id"
											:label="item.name"
											:value="item.datavalue">
											</el-option>
								</el-select>
							</el-form-item>
						</div>
					</el-col>
					<el-col :span="6">
						<div>
							<el-form-item class="buttonbox">
								<el-button type="primary" @click="query" icon="el-icon-search">查询</el-button>
								<el-button type="primary" @click="rest" icon="el-icon-refresh-right">重置</el-button>
							</el-form-item>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</template>
		</div>

		<el-table id="table" :data="list" :header-cell-style="tableHeader" :cell-style="tableCell" :default-sort="{prop: 'timestamp', order: 'descending'}"
		 border>

			<el-table-column prop="id" align="center" width="50" label="序号">
				<template slot-scope="scope">
				<span>{{ scope.$index + 1 + (page.pageNo - 1) * page.pageSize }}</span>
				</template>
			</el-table-column>
			<el-table-column prop="oname" label="用户名称" :show-overflow-tooltip="true" align="center" width="210"></el-table-column>
			<el-table-column prop="code" label="信用代码/身份证" :show-overflow-tooltip="true" align="center"></el-table-column>
			<el-table-column  label="银行电子账户/电子账簿" :show-overflow-tooltip="true" align="center" width="230">
					<template slot-scope="scope">
						{{scope.row.channel == 'sn' ? scope.row.walletComment.rel_acct_no : 
						scope.row.channel == 'zj'? scope.row.walletComment.bnkEid : ''}}
					</template>
			</el-table-column>
			<el-table-column  label="银行名称" :show-overflow-tooltip="true" align="center">郑州银行长椿路支行
				<template slot-scope="scope">
						{{scope.row.channel == 'sn' ? '江苏苏宁银行' : 
						scope.row.channel == 'zj'? '郑州银行长椿路支行' : ''}}
					</template>
			</el-table-column>
			<el-table-column prop="createTime" label="电子账户开户时间" :show-overflow-tooltip="true" align="center" width="140"/>
			<el-table-column prop="aomunt" label="电子账户余额" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
					{{scope.row.aomunt}}
				</template>
			</el-table-column>
			<el-table-column prop="channel" label="通道" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
				<div v-for="(item, index) in itemLists" v-bind:key="index" >
					<div v-if="item.datavalue == scope.row.channel">
						{{  item.name }}
					</div>
				</div>
			</template>
			</el-table-column>
			<el-table-column prop="platform" label="所属平台" :show-overflow-tooltip="true" align="center">一站网</el-table-column>
            <el-table-column prop="walletType" label="账户类型" :show-overflow-tooltip="true" align="center">
				<template slot-scope="scope">
				<div v-for="(item, index) in itemList" v-bind:key="index" >
					<div v-if="item.datavalue == scope.row.walletType">
						{{  item.name }}
					</div>
				</div>
			</template>
			</el-table-column>
            <el-table-column label="操作" width="120" align="center">
            <template slot-scope="scope">
            
            <el-col :span="10">
                <div class="grid-content bg-purple">
                    <el-link
						type="primary"
						icon="el-icon-fetchByIdSysUserList"
						@click="accountdetails(scope.row)"
						:underline="false"
						v-if="funArr.indexOf('明细') !== -1"
						size="medium">明细
						
					</el-link>
                </div>
            </el-col>
            <el-col :span="10">
                <div class="grid-content bg-purple">
                    <el-link
						type="primary"
						icon="el-icon-fetchByIdSysUserList"
						@click="Withdrawal(scope.row)"
						:underline="false"
						v-if="funArr.indexOf('提现') !== -1"
						size="medium">提现
					</el-link>
                </div>
            </el-col>
            <!-- <el-col :span="10">
                <div class="grid-content bg-purple">
                    <el-link
						type="primary"
						icon="el-icon-fetchByIdSysUserList"
						@click="WithdrawalSetting(scope.row)"
						:underline="false"
						size="medium">提现设置
					</el-link>
                </div>
            </el-col> -->
           
            </template>
            </el-table-column>
		</el-table>
		<el-dialog
			id="anniu"
			class="dialogBox"
			:close-on-click-modal="false"
			title="余额提现"
			:visible.sync="dialogVisibledetails">
			<withdrawal @closeDialog="canceledit" :ruleForm="details"></withdrawal>
		</el-dialog>

		<!-- <el-dialog
			id="anniu"
			class="dialogBox"
			:close-on-click-modal="false"
			title="提现账户设置"
			:visible.sync="accountsetting">
			<withdrawal-setting @closeDialog="canceledit" :ruleForm="details"></withdrawal-setting>
		</el-dialog> -->

		<el-pagination
          id="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[5, 10, 20]"
          :page-size="page.pageSize"
					:current-page="page.pageNo"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>

		
	</div>
</template>
<script>
import { findDictionariesItemList } from "@/service/moudules/platformApi";
import Withdrawal from "./Withdrawal";
import WithdrawalSetting from "./WithdrawalSetting";
	export default {
		data() {
			return {
				formInline:{
					oname:null,
					code:null,
					walletType:null,
					createTimeStart:null,
					createTimeEnd:null,
					
				},
				page:{
					pageNo:1,
					pageSize:10,
				},
				channel:{
					zj:"zj",
					sn:"sn",
				},
				diction: {
					code: "walletType",
				},
				dictions: {
					code: "channel",
				},
				total:0,
				list:[],
				itemList: [],
				itemLists:[],
				show: false,
				
				dialogVisibledetails: false,
				accountsetting: false,
				details: '',
				funArr:[],
				isPlatform:false,
			};
		},
		created:function(){
			this.isPlatformTenant();
			this.menuPermission()		
		},
		mounted: function(){
				//this.onSubmit();
				this.fundAccountList();
				let param = JSON.parse(localStorage.getItem("code"));
				this.findDictionariesItemList(this.diction);
				this.channelPay(this.dictions)
		},
		watch:{
			isPlatform(newValue){
				this.fundAccountList();
			},
		},
		methods: {
			async isPlatformTenant(){
				let response = await this.api.preLoanManagementApi.determineIfThisIsAPlatformTenant();
				if(response){
					this.isPlatform = response.data.data
				}
			},
			query(){
				this.page.pageNo = 1;
				this.fundAccountList();
			},
			async menuPermission() {
				let name = "资金账号";
				let params = {
					id: this.$getCookie("sessionId"),
					menuName: name
				}
				let response = await this.api.platformApi.menuPermission(params);
			
				console.log(name + "页面功能列表start---")
				console.log(response.data.data)
				console.log(name + "页面功能列表end---")
				this.funArr = response.data.data
				console.log(this.funArr)
			},
			async fundAccountList(){
				var params = {
					
					oname:this.formInline.oname,
					code:this.formInline.code,
					walletType:this.formInline.walletType,
					createTimeStart:this.formInline.createTimeStart,
					createTimeEnd:this.formInline.createTimeEnd,
					pageSize: this.page.pageSize,
					pageNo: this.page.pageNo,
      			};
			  	let response = this.isPlatform ? await this.api.platformApi.fundAccountList(params) : await this.api.platformApi.listScfFundAccount(params);
				console.log("-----",response.data.data)
				this.list = response.data.data.records;
				this.list.map( item => {
					item.walletComment = JSON.parse(item.walletComment)
					item.aomunt = item.aomunt/100
					return item;
				})
				console.log("-----------------list",this.list);
				this.total = response.data.data.total;
			},

			 async findDictionariesItemList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.itemList = response.data.data;
			},
			async channelPay(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典2", response);
				this.itemLists = response.data.data;
			},



            rest(){
				this.formInline.createTimeStart="",
				this.formInline.createTimeEnd="",
				this.formInline.walletType="",
				this.formInline.oname="",
				this.formInline.code=""
				this.query();
    		},

			handleSizeChange(val) {
			this.page.pageSize = val;
			this.fundAccountList();
			},
			currentChange(pageNo) {
			this.page.pageNo = pageNo;
			this.fundAccountList();
			},
			Withdrawal(row) {
				this.details = row;
				 this.details.oname=this.details.oname.trim()
				this.dialogVisibledetails = true;
			},
			canceldetails() {
				this.dialogVisibledetails = false;
			},

			WithdrawalSetting(row) {
				this.details = row;
				this.accountsetting = true;
			},
			canceldetails() {
				this.accountsetting = false;
			},

            tableHeader(column) {
				return 'height:36px;background-color:#fafafa;color:#333333;padding:0';
			},
			tableCell(column) {
				return 'height:40px;background-color:#fafafa;color:#000000;padding:0';
			},
			canceledit() {
			this.dialogVisibledetails = false;
			this.accountsetting = false;
			},


			accountdetails(row){
				if(row.channel==this.channel.zj){
					this.$router.push({path: '/Detailed',name: 'Detailed',
					params: {subNo: row.walletComment.subNo,id:row.id,channel:row.channel}},);
						localStorage.setItem(
							"id",
							JSON.stringify({
							id: row.id,
							personId:row.legal,
						})
					);
				}else if(row.channel==this.channel.sn){
					this.$router.push({path: '/orderpayment',name: 'orderpayment',
					params: {channel:row.channel,channelId:row.channelId,id:row.id,oname:row.oname.trim(),balanceAcctId: row.walletComment.balance_acct_id}},);
						localStorage.setItem(
							"id",
							JSON.stringify({
							id: row.id,
							personId:row.legal,
						})
					);
				}else{
					console.log("未找到通道类型!")
				}
			},
			
			
		},
		 components: {
				 Withdrawal,
				 WithdrawalSetting,
			},
		
	};
</script>
<style scoped>
		.tabbox {
		text-align: left;
	}



	#table {
		margin-top: 15px;
		background-color: rgba(29, 144, 255, 0);
	}

	.el-dialog { 
		text-align: left;
	}

	>>>.el-dialog__body {
		border-top: 1px solid rgba(228, 228, 228, 1);
		border-bottom: 1px solid rgba(228, 228, 228, 1);
		padding-left: 0;
		padding-right: 0;
	}

	.el-dialog button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}

	.search {
		background-color: white;
		padding: 15px 20px 100px 20px;
		overflow: hidden;
	}
	.quanju{
		width: 1200px;
		height: 100%;
	}

	#page{
		float: right;
		margin-top: 15px;
	}

	#grid {
		margin-right: 15px;
		padding: 0px;
		border-radius: 5px;
	}

	.sx {
		float: right;
		margin-right: 70px;
		margin-top: 15px
	}

	.buttonbox button {
		margin-right: 15px;
		padding: 0px;
		width: 80px;
		height: 32px;
		border-radius: 5px;
	}
</style>