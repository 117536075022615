import '@/plugins/axios';

/**
 * 应付帐查询
 * @param {*} params 
 * @returns 
 */
export const getProofAndPaymentPlan = params => axios.post("/accountPayable/getProofAndPaymentPlan", params).then(data => data)

/**
 * 根据凭证标识查询应收帐
 * @param {*} params 
 * @returns 
 */
export const getProofAndPaymentPlanByProofId = params => axios.post("/accountPayable/getProofAndPaymentPlanByProofId", params).then(data => data)

/**
 * 应付帐导出
 * @param {*} params 
 * @returns 
 */
export const exportProofAndPaymentPlan = params => axios({method: 'post',url: '/accountPayable/exportProofAndPaymentPlan',responseType: 'blob',data: params}).then(data => data)

/**
 * 应付帐导出
 * @param {*} params 
 * @returns 
 */
export const exportInvoice = params => axios({method: 'post',url: '/invoice/exportInvoice',responseType: 'blob',data: params}).then(data => data)

/**
 * 持有发票信息
 * @param {*} params 
 * @returns 
 */
export const possessInvoice = params => axios.post("/accountPayable/possessInvoice", params).then(data => data)

/**
 * 应付帐结算情况
 * @param {*} params 
 * @returns 
 */
export const billPayableList = params => axios.post("/accountPayable/billPayableList", params).then(data => data)

/**
 * 应付帐结算情况
 * @param {*} params 
 * @returns 
 */
export const listAccountsPayables = params => axios.post("/accountPayable/listAccountsPayables", params).then(data => data)

/**
 * 应付帐结算情况
 * @param {*} params 
 * @returns 
 */
export const listInvoice = params => axios.post("/accountPayable/listInvoice", params).then(data => data)

/**
 * 确权金额
 * @param {*} params 
 * @returns 
 */
export const addCreditProof = params => axios.post("/accountPayable/addCreditProof", params).then(data => data)

/**
 * 获取支付账户信息
 */
 export const getPayAccountInfo = params => axios.post("/accountPayable/getPayAccountInfo",params).then(data => data)

/**
 * 初审应收帐及额度
 * @param {*} params 
 * @returns 
 */
export const getOrderCollectionAndQuota = params => axios.post("/accountPayable/getOrderCollectionAndQuota", params).then(data => data)

/**
 * 复审应收帐及额度
 * @param {*} params 
 * @returns 
 */
export const listOrderCollectionAndQuotaRecheck = params => axios.post("/accountPayable/listOrderCollectionAndQuotaRecheck", params).then(data => data)

/**
 * 贷款初审-通过
 * @param {*} params 
 * @returns 
 */
export const passThePreliminaryReviewReq = params => axios.post("/applicationReview/passThePreliminaryReviewReq", params).then(data => data)

/**
 * 发票列表
 * @param {*} params 
 * @returns 
 */
export const pageInvoice = params => axios.post("/invoice/pageInvoice", params).then(data => data)

/**
 * 批量线上支付-付款计划
 * @param {*} params 
 * @returns 
 */
export const batchOnlinePayPaymentPlan = params => axios.post("/pay/batchOnlinePayPaymentPlan", params).then(data => data)

/**
 * 线上支付-付款计划
 * @param {*} params 
 * @returns 
 */
export const onlinePayPaymentPlan = params => axios.post("/pay/onlinePayPaymentPlan", params).then(data => data)

/**
 * 批量线下支付-付款计划
 * @param {*} params 
 * @returns 
 */
export const batchOfflinePayPaymentPlan = params => axios.post("/pay/batchOfflinePayPaymentPlan", params).then(data => data)

/**
 * 线下支付-付款计划
 * @param {*} params 
 * @returns 
 */
export const offlinePayPaymentPlan = params => axios.post("/pay/offlinePayPaymentPlan", params).then(data => data)

/**
 * 应付帐详情额度使用信息
 * @param {*} params 
 * @returns 
 */
export const lisPaymentUsage = params => axios.post("/accountPayable/lisPaymentUsage", params).then(data => data)


/**
 * 账单确权-确权记录
 * @param {*} params 
 * @returns 
 */
export const getConfirmationRecord = params => axios.post("/accountPayable/getConfirmationRecord", params).then(data => data)

/**
 * 所属对账单
 * @param {*} params 
 * @returns 
 */
export const statement = params => axios.post("/accountPayable/statement", params).then(data => data)

/**
 * 下载凭证
 * @param {*} params 
 * @returns 
 */
export const downloadVoucher = params => axios.post("/accountPayable/downloadVoucher", params).then(data => data)

/**
 * 当前可确权金额
 * @param {*} params 
 * @returns 
 */
export const currentConfirmatoryAmount = params => axios.post("/accountPayable/currentConfirmatoryAmount", params).then(data => data)

/**
 * 线下支付-授信凭证
 * @param {*} params 
 * @returns 
 */
export const offlinePayCreditProof = params => axios.post("/pay/offlinePayCreditProof", params).then(data => data)

/**
 * 线上支付-授信凭证
 * @param {*} params 
 * @returns 
 */
export const onlinePayCreditProof = params => axios.post("/pay/onlinePayCreditProof", params).then(data => data)

/**
 * 线上支付-授信凭证
 * @param {*} params 
 * @returns 
 */
export const thisPayAmount = params => axios.post("/accountPayable/thisPayAmount", params).then(data => data)

