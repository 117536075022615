<template>
  <div>
    <el-row>
      <el-col :span="16">
        <el-tree
          ref="tree"
          default-expand-all
          :data="data"
          highlight-current
          show-checkbox
          check-on-click-node
          node-key="index"
          :props="defaultProps"
        >
        </el-tree>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
        <el-button type="primary" @click="addRolePermission('ruleForm')"
          >保存</el-button
        >
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  props: ["roleId"],
  mounted() {
    this.loadData();
  },
  updated() {
    console.log(this.data);
  },
  watch: {
    roleId(val) {
      this.loadMyRolePermission(val);
    },
  },

  data() {
    return {
      checkData: "",
      treeMap: {},
      originArr: [],
      data: [
        {
          id: 1,
          label: "一级 1",
          children: [
            {
              id: 4,
              label: "二级 1-1",
              children: [
                {
                  id: 9,
                  label: "三级 1-1-1",
                },
                {
                  id: 10,
                  label: "三级 1-1-2",
                },
              ],
            },
          ],
        },
        {
          id: 2,
          label: "一级 2",
          children: [
            {
              id: 5,
              label: "二级 2-1",
            },
            {
              id: 6,
              label: "二级 2-2",
            },
          ],
        },
        {
          id: 3,
          label: "一级 3",
          children: [
            {
              id: 7,
              label: "二级 3-1",
            },
            {
              id: 8,
              label: "二级 3-2",
            },
          ],
        },
      ],
      defaultProps: {
        children: "childModule",
        label: "currentModule",
      },
    };
  },

  methods: {
    handleCheckChange(data) {
      // console.log(data);
    },

    //从底层节点递归查找所有权限
    treesSaveData(data, appendStr) {
      for (var index in data) {
        var dataChildren = data[index];
        if (dataChildren.childModule) {
          this.treesSaveData(
            dataChildren.childModule,
            appendStr + "/" + dataChildren.currentModule
          );
        } else {
          var str = appendStr + "/" + dataChildren.currentModule;
          str = str.substring(1, str.length);
          this.treeMap[dataChildren.index] = str;
        }
      }
    },
    async loadMyRolePermission(roleId) {
      // axios
      //   .post("http://localhost:8000/SysRolePermission/getMyRolePermission", {
      //     role: roleId,
      //   })
      //   .then((response) => {
      //     // console.log(JSON.stringify(response))
      //     let data = response.data.data;
      //     let arr = [];
      //     for (var index in data) {
      //       for (var key in this.treeMap) {
      //         if (this.treeMap[key] == data[index].permission) {
      //           arr.push(parseInt(key));
      //         }
      //       }
      //     }

      //     // console.log(arr)
      //     this.$refs.tree.setCheckedKeys(arr);
      //   });
      var params = {
        role: roleId,
      };
      let response = await this.api.platformApi.getMyRolePermission(params);
      var me = this;
      let data = response.data.data;
      let arr = [];
      for (var index in data) {
        for (var key in this.treeMap) {
          if (this.treeMap[key] == data[index].permission) {
            arr.push(parseInt(key));
          }
        }
      }

      // console.log(arr)
      this.originArr = arr;
      this.$refs.tree.setCheckedKeys(arr);
    },
    async loadData() {
      var params = {
        sessionId: this.$getCookie("sessionId"),
      };

      let response = await this.api.platformApi.PermissionList(params);
      var me = this;
      me.data = response.data.data;
      this.loadTreeData(me.data, 0);
      this.treesSaveData(me.data, "");
    },

    //递归循环出所有的权限
    loadTreeData(data, i) {
      for (var index in data) {
        i++;
        data[index].index = i;
        if (data[index].childModule) {
          i = this.loadTreeData(data[index].childModule, i);
        }
      }
      return i;
    },
    async addRolePermission() {
      let permissions = [];
      var indexs = this.$refs.tree.getCheckedKeys();
      for (var i in indexs) {
        let permissionName = this.treeMap[indexs[i]];
        if (permissionName) {
          permissions.push(permissionName);
        }
      }
      var list = this;
      let roleJson = {
        permission: permissions,
        role: list.roleId,
      };
      // axios.post("http://localhost:8000/SysRolePermission/addrolepermission",roleJson)
      // .then((response)=>{
      //   this.$message({
      //       message: '添加成功',
      //       type: 'success'
      //     });
      // });
      let response = await this.api.platformApi.addrolepermission(roleJson);
      if (response.data.code == 200) {
        this.originArr = indexs;
        this.$message({
          message: "添加成功",
          type: "success",
        });
      }
    },
    setCookie: function (cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
    resetForm(formName) {
      this.$refs.tree.setCheckedKeys(this.originArr);
    },
  },
};
</script>
<style  scoped>
.el-button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
</style>




