<template>
  <div id="editUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form
          id="from"
          :model="ruleForm"
          ref="ruleForm"
          label-width="120px"
          class="demo-ruleForm">
          <el-form-item label="银行账户户名:" prop="oname">
            <el-input v-model="ruleForm.oname" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="银行账户账号:" prop="account">
            <el-input v-model="ruleForm.account" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="开户行:" prop="bankName">
            <el-input v-model="ruleForm.bankName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="开户行支行名称:" prop="branchName">
            <el-input v-model="ruleForm.branchName" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="可用提现余额:" prop="aomunt">
            <el-input  v-model="ruleForm.aomunt"  :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="本次提现金额" prop="money" style="text-align: right;margin-bottom: 0px;" 
          :rules="[
            { required: true, message: '提现金额不能为空'},
          ]">
            <el-input v-model.number="ruleForm.money" placeholder="请输入提现金额" autocomplete="off" @change="moneyChange"></el-input>
            <span :hidden='serviceCharge == null || serviceCharge == undefined' style="font-size: 12px;">支付手续费：{{serviceCharge / 100}}元</span>
          </el-form-item>
          <el-form-item label="备注" prop="mobile">
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入备注"
              v-model="textarea">
            </el-input>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>
    <el-row id="anniu22" type="flex">
      <el-col>
        <el-button type="success" @click="canceledit()">取消</el-button>
        <el-button type="primary" @click="paymentApplication()"
    v-loading.fullscreen.lock="loading" element-loading-text="数据拼命加载中..."
		>确认提现</el-button>
      </el-col>
    </el-row>
    
  </div>
</template>
<script>
export default {
  props: ["ruleForm"],
 
 
  data() {
    return {
      loading: false,
      ruleFormparam:{
            name:null,
            account:null,
            bankName:null,
            branchName:null,
            balFlag:"S0",
            subNo:{},
            walletComment:null,
            account: null,
            money : null,
            feeAmt:"0",

            outRequestNo:null,
            password:null,
            amount:null,
            balanceAcctId:null,
            bankAcctNo:null,
            

        },
      serviceCharge:0,
      input: '',
      textarea: ''
    };
  },
  watch:{
    ruleForm:async function(newVal){
      let money = newVal.money;
      if(money == null || money == undefined || money == 0){
        // console.log('加载新数据===')
        this.serviceCharge = 0;
      }else{
        // console.log('加载旧数据===')
        this.serviceCharge = Math.floor(await this.withdrawalServiceCharge(money * 100))
      }
    }
  },
   mounted: function(){
      //this.findAmountOfPayment();

		},


  methods: {
    //查询可提现余额
    async findAmountOfPayment(){
      var params = {
            cltNm:this.ruleForm.name,
            subNo: this.ruleForm.walletComment.subNo,
          }
      let response = await this.api.platformApi.findAmount(params);
      this.amount = response.data.data;
      this.$set(this.ruleForm, 'toneAmtCta', this.amount.toneAmtCta)
      console.log("-----------amount",this.amount);
    },
    async moneyChange(money){
      this.serviceCharge = Math.floor(await this.withdrawalServiceCharge(money * 100))
      console.log('serviceCharge:',this.serviceCharge)
    },


     //提现
      async paymentApplication(){
        console.log(11111)
        if(this.ruleForm.money<this.serviceCharge/100){
          this.$message.warning('提现金额不能小于手续费')
          return
        }
        this.loading = true;
        	var params = {
            channel:this.ruleForm.channel,
            cltNm:this.ruleForm.name,
            subNo:this.ruleForm.walletComment.subNo,
            balFlag:this.ruleFormparam.balFlag,
            accNo:this.ruleForm.account,
            accNm:this.ruleForm.name,
            aclAmt:this.ruleForm.money * 100,
            feeAmt:this.ruleFormparam.feeAmt,
            tAmt:this.ruleFormparam.money,
            walletId:this.ruleForm.walletId,

            outRequestNo:this.ruleFormparam.outRequestNo,
            password:this.ruleFormparam.password,
            amount:this.ruleForm.money * 100,
            balanceAcctId:this.ruleForm.walletComment.balance_acct_id,
            bankAcctNo:this.ruleForm.account,
            name:this.ruleForm.name,
          }
          console.log("---------------txparams",params)
			  let response = await this.api.platformApi.paymentApplication(params);
        this.Withdrawal = response.data.data;
        console.log("-----------result",this.Withdrawal);
          this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 5000);
        if(response.data.code != 200){
            this.$message.error('提现金额不能大于账户可提现余额！');
        }else{
            this.$message.success('交易处理中,请耐心等待！');
        }
        
        this.$emit("closeDialog");
        
        
			},
      canceledit() {
          this.$emit("closeDialog");
      },
      async withdrawalServiceCharge(amount){
        var params = {
          "amount":amount
        }
        let response = await this.api.platformApi.getWithdrawalCharge(params);
        if(response.data.code != 200){
          this.$message.error(response.data.msg);
          return 0
        }else{
          return response.data.data
        }
      }
    
  },
};
</script>
<style scoped>
#editUser .el-cascader {
  width: 100%;
}

#anniu22 {
  text-align: right;
  border-top: 1px solid rgba(228, 228, 228, 1);
  padding-top: 10px;
  padding-right: 30px;
  border-radius: 5px;
}

#editUser .el-select {
  width: 100%;
}

#anniu22 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#editUser .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#editUser .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#editUser .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#editUser .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
