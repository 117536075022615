<template>
  <div id="editUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form id="from" label-width="150px" class="demo-ruleForm">
          <el-form-item label="应付时间:" prop="payDate">
            <div class="block">
              <!-- <span class="demonstration">默认</span> -->
              <el-date-picker
                v-model="payDate"
                type="date"
                placeholder="请选择日期时间"
                pattern="yyyy-MM-dd "
                value-format="yyyy-MM-dd"
              ></el-date-picker>
            </div>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { dynamicUpdateInvoiceById } from "@/service/moudules/postLoanManagementApi";
import { updateCreditProofInfo } from "@/service/moudules/postLoanManagementApi";
export default {
  created() {},
  props: ["updateInvoice"],

  mounted: function () {
    this.proofId = this.updateInvoice.proofIdUpdate;
    this.payDate = this.updateInvoice.payDate;
    // alert("proofId:" + this.proofId + "+payDate:" + this.payDate);
    // this.getDep();
  },
  data() {
    return {
      //proofId
      proofId: "",
      //应付时间
      payDate: "",
    };
  },
  methods: {
    async submitForm() {
      // alert(this.payDate);
      var params = {
        id: this.proofId,
        paymentDate: this.payDate,
      };
      let response = await this.api.postLoanManagementApi.dynamicUpdateInvoiceById(
        params
      );
      if (response.data.code != 200) {
        this.$message({
          message: response.data.msg,
          type: "warning",
        });
      } else {
        this.updateCreditProofInfo();
      }
      //  this.updateCreditProofInfo();
    },
    async updateCreditProofInfo() {
      var params = {
        proofId: this.proofId,
        expireDate: this.payDate,
        session: this.$getCookie("sessionId"),
      };
      let response = await this.api.postLoanManagementApi.updateCreditProofInfo(
        params
      );
      if (response.data.code != 200) {
        this.$message({
          message: response.data.msg,
          type: "warning",
        });
      } else {
        this.$message({
          message: "修改成功",
          type: "success",
        });
      }

      // alert(this.$getCookie("sessionId"))
    },
    //=================cookie
    setCookie: function (cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
  },
};
</script>
<style scoped>
#editUser .el-cascader {
  width: 100%;
}

#anniu22 {
  text-align: right;
  border-top: 1px solid rgba(228, 228, 228, 1);
  padding-top: 10px;
  padding-right: 30px;
  border-radius: 5px;
}

#editUser .el-select {
  width: 100%;
}

#anniu22 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#editUser .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#editUser .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#editUser .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#editUser .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
