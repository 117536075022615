<template>
  <div id="loanList">
    <div>
      <template>
        <div class="fixed">
          <el-form
            :inline="true"
            :model="formInline"
            ref="formData"
            class="demo-form-inline"
          >
            <el-row>
              <el-col :span="7" align="left">
                <el-form-item label="贷款编号:" prop="assets">
                  <el-input
                    placeholder="请输入查询"
                    v-model="formInline.assets"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="10" align="left">
                <el-form-item label="申请时间:" prop="applicationTime">
                  <el-date-picker
                    type="date"
                    placeholder="起"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="formInline.applicationTimeStart"
                  ></el-date-picker
                  >-
                  <el-date-picker
                    type="date"
                    placeholder="止"
                    editable
                    pattern="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    v-model="formInline.applicationTimeEnd"
                  ></el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="7" align="left">
                <el-form-item label="贷款状态:" prop="applyState">
                  <el-select
                    v-model="formInline.applyState"
                    placeholder="请选择"
                    class="select_width"
                    clearable
                    
                  >
                    <el-option
                      v-for="item in dictionList"
                      :key="item.id"
                      :label="item.name"
                      :value="item.datavalue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>

            <div>
              <el-row>
                <el-col :span="7" align="left">
                  <el-form-item label="贷款企业:" prop="name">
                    <el-input
                      placeholder="请输入查询"
                      v-model="formInline.name"
                    ></el-input>
                  </el-form-item>
                </el-col>

                <el-col :span="10" align="left">
                  <el-form-item label="结束时间:" prop="endTime">
                    <el-date-picker
                      type="date"
                      placeholder="起"
                      editable
                      pattern="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      v-model="formInline.endTimeStart"
                    ></el-date-picker
                    >-
                    <el-date-picker
                      type="date"
                      placeholder="止"
                      editable
                      pattern="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      v-model="formInline.endTimeEnd"
                    ></el-date-picker>
                  </el-form-item>
                </el-col>

                <el-col :span="6" align="left">
                  <el-form-item id="anniu">
                    <el-button
                      type="primary"
                      icon="el-icon-search"
                      @click="submit"
                      >查询</el-button
                    >
                    <el-button
                      type="primary"
                      @click="reset"
                      icon="el-icon-refresh-right"
                      >重置</el-button
                    >
                  </el-form-item>
                </el-col>
              </el-row>
            </div>
          </el-form>
        </div>
        <el-table
          :data="list"
          id="table"
          :header-cell-style="tableHeader"
          :cell-style="tableCell"
          border
        >
          <el-table-column prop="sss" label="序号" align="center">
            <template slot-scope="scope">
              <span>{{
                scope.$index + 1 + (page.pageNo - 1) * page.pageSize
              }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="id" label="编号" :show-overflow-tooltip="true" align="center"></el-table-column> -->
          <el-table-column
            prop="assets"
            label="贷款编号"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="name"
            label="贷款企业"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="productName"
            label="贷款产品"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="loanAmount"
            label="贷款金额"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.loanAmount / 100 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="expectedInterest"
            label="预计利息"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.expectedInterest / 100 }}
            </template>
          </el-table-column>
          <el-table-column
            prop="repaymentInterval"
            label="贷款期限"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="creditInterestRate"
            label="利率"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.creditInterestRate / 10000 }}%
            </template>
          </el-table-column>

          <el-table-column
            prop="applyState"
            label="审批状态"
            :show-overflow-tooltip="true"
            align="center"
          >
            <template slot-scope="scope">
              <div v-if="scope.row.applyState != 11">
                <div v-for="(item, index) in dictionList" v-bind:key="index">
                  <div v-if="item.datavalue == scope.row.applyState">
                    {{ item.name }}
                  </div>
                </div>
              </div>
              <div v-if="scope.row.applyState == 11">
                <div
                  v-for="(item, index) in assetsDictionList"
                  v-bind:key="index"
                >
                  <div v-if="item.datavalue == scope.row.assetsState">
                    {{ item.name }}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="applicationTime"
            label="申请时间"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="lendingTime"
            label="放款时间"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="expectedEndDate"
            label="预计还款时间"
            :show-overflow-tooltip="true"
            align="center"
          ></el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <el-link
                type="primary"
                icon="el-icon-fetchByIdSysUserList"
                @click="detail(scope.row)"
                :underline="false"
                size="medium"
                >详情</el-link
              >
            </template>
          </el-table-column>
        </el-table>

        <el-pagination
          id="page"
          background
          @size-change="handleSizeChange"
          @current-change="currentChange"
          :page-sizes="[10, 15, 20]"
          :page-size="1"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total"
          :current-page="page.pageNo"
        >
        </el-pagination>
      </template>
    </div>
  </div>
</template>
<script>
import { findLoanTrackingList } from "@/service/moudules/postLoanManagementApi";
import { findDictionariesItemList } from "@/service/moudules/platformApi";
export default {
  data() {
    return {
      formInline: {
        assets: "",
        name: "",
        applyState: "",
        applicationTimeStart: "",
        applicationTimeEnd: "",
        endTimeStart: "",
        endTimeEnd: "",
      },
      // diction: {
      //   code: "repaymentState",
      // },
      page: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
      },
      diction: {
        code: "applyState",
      },
      assetsDiction: {
        code: "assetsState",
      },
      dictionList: [],
      assetsDictionList: [],
      list: [],
    };
  },
  created: function () {},
  mounted: function () {
    this.applyList();
    this.findDictionariesItemList(this.diction);
    this.findAssetsDictionList(this.assetsDiction);
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    submit() {
      this.page.pageNo = 1
      this.applyList();
    },
    reset() {
      this.$refs.formData.resetFields();
      this.formInline.applicationTimeStart = "";
      this.formInline.applicationTimeEnd = "";
      this.formInline.endTimeStart = "";
      this.formInline.endTimeEnd = "";
      this.applyList();
    },
    async applyList() {
      console.log("formInline" + this.formInline);
      var params = {
        assets: this.formInline.assets,
        name: this.formInline.name,
        applyState: this.formInline.applyState,
        applicationTimeStart: this.formInline.applicationTimeStart,
        applicationTimeEnd: this.formInline.applicationTimeEnd,
        endTimeStart: this.formInline.endTimeStart,
        endTimeEnd: this.formInline.endTimeEnd,
        applyStateList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11,12,13],
        pageSize: this.page.pageSize,
        pageNo: this.page.pageNo,
      };
      let response = await this.api.postLoanManagementApi.findApplyList(params);
      console.log("==========", JSON.stringify(response.data));
      this.list = response.data.data.records;
      // console.log(JSON.stringify(this.list));
      console.log("total" + response.data.data.total);
      this.page.total = response.data.data.total;
    },
    handleSizeChange(val) {
      this.page.pageSize = val;
      this.applyList();
    },
    currentChange(pageNo) {
      this.page.pageNo = pageNo;
      this.applyList();
    },
    async findDictionariesItemList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.dictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async findAssetsDictionList(param) {
      let response = await this.api.platformApi.findDictionariesItemList(param);
      console.log("数据字典返回结果", response);
      this.assetsDictionList = response.data.data;
      console.log("数据字典集合", this.dictionList);
    },
    async detail(row) {
      console.log("=====row====" + JSON.stringify(row));
      var param = {
        id: row.risk,
      };
      let corpResponse = await this.api.postLoanManagementApi.findCorpAndRisk(
        param
      );
      console.log(
        "==localStorage===",
        localStorage,
        "======corpResponse:",
        corpResponse
      );
      localStorage.setItem(
        "id",
        JSON.stringify({
          id: row.assets,
          corpInfo: corpResponse.data.data,
          applyId: row.applyId,
        })
      );

      console.log("=====id=====", localStorage.getItem("id"));
      this.$router.push({ name: "loanListDetails" });
    },
  },
};
</script>
<style scoped>
#loanList {
  background-color: white;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 100px;
}
#loanList .marginCss {
  margin-top: 20px;
}
#loanList .grid-anniu button {
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
  margin: 10px;
}
#anniu {
  /* padding-left: 50px; */
  border: 0;
}

#anniu button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}
#table {
  width: 100%;
  background-color: rgba(29, 144, 255, 0);
}
#loanList .dialogBox {
  text-align: left;
}
#page {
  float: right;
  margin-top: 15px;
}

.fixed {
  width: 1400px;
  height: 100%;
  overflow: auto;
}
.select_width{
  width: 50%;
}
</style>