<template>
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="10" :md="12">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>
        <el-form-item label="数据值" prop="datavalue">
          <el-input v-model="ruleForm.datavalue"></el-input>
        </el-form-item>
        <el-form-item label="描述" prop="details">
          <el-input v-model="ruleForm.details"></el-input>
        </el-form-item>
        <el-form-item label="排序值" prop="sort">
          <el-input v-model="ruleForm.sort"></el-input>
        </el-form-item>
        <el-form-item label="是否启用:" prop="flag">
          <el-radio v-model="ruleForm.flag" :label="0">是</el-radio>
          <el-radio v-model="ruleForm.flag" :label="1">否</el-radio>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>

<script>
export default {
  created() {
    console.log(this.ruleForm);
  },
  props: ["ruleForm"],
  watch: {
    ruleForm(val) {
      this.ruleForm = val;
    },
  },
  mounted: function () {},

  data() {
    return {
      rules: {
        name: [{ required: true, message: "账号不能为空", trigger: "blur" }],
        datavalue: [
          { required: true, message: "用户名不能为空", trigger: "blur" },
        ],
        details: [{ required: true, message: "密码不能为空", trigger: "blur" }],
        sort: [{ required: true, message: "身份证不能为空", trigger: "blur" }],
      },
    };
  },

  methods: {
    async submitForm() {
      let response = await this.api.platformApi
        .editDictionariesItem(this.ruleForm)
        if (response.data.data == true) {
        this.$message({
          message: "修改成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "修改失败",
          type: "warning",
        });
      }
       this.$emit("closeDialog");
    },
  },
};
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>