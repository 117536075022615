<template>
  <div id="editUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form
          id="from"
          :model="ruleForm"
          ref="ruleForm"
          label-width="150px"
          class="demo-ruleForm">
          <input type="hidden" v-model="ruleForm" />
          <el-form-item label="银行账户户名:" prop="name">
            <el-input v-model="ruleForm.name" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="银行账户账号:" prop="account">
            <el-input v-model="ruleForm.account" ></el-input>
          </el-form-item>
          <el-form-item label="开户行:" prop="bankName">
            <el-autocomplete
            class="inline-input"
            v-model="ruleForm.bankName"
            :fetch-suggestions="querySearch"
            placeholder="请输入内容"
            @select="handleSelect"></el-autocomplete>
          </el-form-item>
           <el-form-item label="开户行支行名称:" prop="branchName">
            <el-autocomplete
            class="inline-input"
            v-model="ruleForm.branchName"
            :fetch-suggestions="branchSearch"
            placeholder="请输入内容"
            @select="handleSelect"></el-autocomplete>
          </el-form-item>

        </el-form>
      </el-col>
    </el-row>
    <el-row id="anniu22" type="flex">
      <el-col>
        <el-button type="success" @click="canceledit()">取消</el-button>
        <el-button type="primary" @click="withdrawalSetting()">确认设置</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  props: ["ruleForm"],

 
 
  data() {
    return {
        ruleFormParam:{
            name:null,
            account:null,
            bankName:null,
            branchName:null,
            queryNum:999,
            bankNo:null,

            
        },
      bankList:[],
      branchList:[],
    };
  },

  mounted: function(){
			//	this.withdrawalSetting();
      this.findBank();
      this.paybankinfo();

		},


  methods: {
    async withdrawalSetting(){
				var params = {
              name: this.ruleForm.name,
              bankNo: this.ruleFormParam.bankNo,
              account: this.ruleForm.account,
              bankName: this.ruleForm.bankName,
              accTp: this.ruleFormParam.accTp,
              crdTp: this.ruleFormParam.crdTp,
              idno: this.ruleFormParam.idno,
              crsMk: this.ruleFormParam.crsMk,
              cdTp: this.ruleFormParam.cdTp,
              fcFlag: this.ruleFormParam.fcFlag,
      			};
			  let response = await this.api.platformApi.withdrawalSetting(params);
        this.bankList = response.data.data;
			},
      handleSelect(bankkkkk){
        this.ruleFormParam.bankNo=bankkkkk.bankNo
        this.paybankinfo();
      },


      //查询开户行
      async findBank(){
			  let response = await this.api.platformApi.findBank();
        this.bankList = response.data.data;
        console.log("-----------a",this.bankList);
			},


      querySearch(queryString, cb) {
        var bankList = this.bankList;
         for(var i=0;i<bankList.length;i++){
            bankList[i].value = bankList[i].bankName;   
         }
        console.log('queryString',queryString)
        var results = queryString ? bankList.filter(this.createFilter(queryString)) : bankList;
        
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (bankList) => {
          return (bankList.bankName.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },

      //查询开户支行
      async paybankinfo(){
        	var params = {
                bankNo: this.ruleFormParam.bankNo,
               openBkNm: this.ruleForm.bankName,
               queryNum: this.ruleFormParam.queryNum,
       		};
			  let response = await this.api.platformApi.paybankinfo(params);
				this.branchList = response.data.data.list;
        console.log("-----------b",this.branchList);
			},

       branchSearch(queryString, cb) {
        var branchList = this.branchList;
         for(var i=0;i<branchList.length;i++){
            branchList[i].value = branchList[i].openbknm;   
         }
        console.log('queryString',queryString)
        var results = queryString ? branchList.filter(this.branchString(queryString)) : branchList;
        
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      branchString(queryString) {
        return (branchList) => {
          return (branchList.openbknm.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },


   
    canceledit() {
      this.$emit("closeDialog");
    },
   
    
  },
};
</script>
<style scoped>
#editUser .el-cascader {
  width: 100%;
}

#anniu22 {
  text-align: right;
  border-top: 1px solid rgba(228, 228, 228, 1);
  padding-top: 10px;
  padding-right: 30px;
  border-radius: 5px;
}

#editUser .el-select {
  width: 100%;
}

#anniu22 button {
  margin-right: 15px;
  padding: 0px;
  width: 80px;
  height: 32px;
  border-radius: 5px;
}

#editUser .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#editUser .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#editUser .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#editUser .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
