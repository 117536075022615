<template>
<div id="addOrganization">
  <el-row type="flex" justify="center">
    <el-col :xs="24" :sm="16" :md="12">
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <input type="hidden" v-model="ruleForm.id" />
        <el-form-item label="机构编号:">
          <el-input v-model="ruleForm.code"></el-input>
        </el-form-item>
        <el-form-item label="机构名称:">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>

        <el-form-item label="机构简称:">
          <el-input v-model="ruleForm.abbreviation"></el-input>
        </el-form-item>

        <el-form-item label="机构地址:">
          <el-input v-model="ruleForm.address"></el-input>
        </el-form-item>

        <el-form-item label="所属公司:">
          <el-input v-model="ruleForm.company"></el-input>
        </el-form-item>
        <el-form-item label="是否启用:">
          <el-radio v-model="flagtype" label="1">是</el-radio>
          <el-radio v-model="flagtype" label="0">否</el-radio>
        </el-form-item>
        <el-form-item label="机构描述:">
          <el-input
            type="textarea"
            :rows="2"
            placeholder="请输入描述"
            v-model="ruleForm.content"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</div>
</template>

<script>
import { organizationCreate } from "@/service/moudules/platformApi";
export default {
  props: ["addOrganization"],
  mounted: function () {},

  data() {
    return {
      textarea: "",

      flagtype: "1",

      ruleForm: {
        name: "",
        code: "",
        abbreviation: "",
        address: "",
        state: 0,
        content: "",
        company: "",
      },
    };
  },

  methods: {
    submitForm() {
      this.organizationCreateApi();
    },
    async organizationCreateApi() {
      this.ruleForm.state = parseInt(this.flagtype);
      let response = await this.api.platformApi.organizationCreate(
        this.ruleForm
      );
      if (response.data.data == true) {
        this.$message({
          message: "新增成功",
          type: "success",
        });
      } else {
        this.$message({
          message: "新增失败",
          type: "warning",
        });
      }
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>

<style scope>
#addOrganization .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
#addOrganization .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
#addOrganization .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
#addOrganization .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
#addOrganization .buttomWidth {
  width: 100%;
}
</style>