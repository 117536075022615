<template>
  <div id="uploadTask">
<!-- 内层 -->
      <el-dialog :visible.sync="dialogFormVisible">
        <el-dialog
          width="70%"
          title="上传作业单" v-if="this.type=='G545435437'"
          :visible.sync="innerVisible" append-to-body>
          <el-table :data="parseTable.tableData.slice((parseTable.pageNo - 1) * parseTable.pageSize,parseTable.pageSize * parseTable.pageNo)" :header-cell-style="tableHeader" :cell-style="tableCell" height="440" :key="'tableData-'+tableDataKey" border style="width: 100%">
            <el-table-column prop="index" label="序号" width="50px">
              <template slot-scope="scope">
                <span>{{(scope.$index+1) + ((parseTable.pageNo-1) * parseTable.pageSize)}}</span>
              </template>
            </el-table-column>
            <el-table-column prop="thirdParty" label="作业单编号"/>
            <el-table-column prop="taskorType" label="供应商主体类型"/>
            <el-table-column prop="taskorThirdParty" label="供应商第三方用户主键"/>
            <el-table-column prop="taskorIdno" label="购买方证件号"/>
            <el-table-column prop="name" label="供应方名称"/>
            <el-table-column prop="type" label="供应方类型"/>
            <el-table-column prop="content" label="内容描述"/>
            <el-table-column prop="clientBillNo" label="客户单号"/>
            <el-table-column prop="expectedEndTime" label="预计结束时间"/>
            <el-table-column prop="amount" label="运费"/>
            <el-table-column prop="taskTrackNos" label="关联任务清单号"/>
            <el-table-column prop="deliveryContact" label="发货联系人"/>
            <el-table-column prop="deliveryContactPhone" label="发货联系人电话"/>
            <el-table-column prop="deliveryProvince" label="发货省"/>
            <el-table-column prop="deliveryCity" label="发货市"/>
            <el-table-column prop="deliveryArea" label="发货区域"/>
            <el-table-column prop="deliveryAddress" label="发货地址"/>
            <el-table-column prop="receivingProvince" label="收货省"/>
            <el-table-column prop="receivingCity" label="收货市"/>
            <el-table-column prop="receivingArea" label="收货区域"/>
            <el-table-column prop="routingCode" label="路线编码"/>
            <el-table-column prop="transportModes" label="运输方式"/>
            <el-table-column prop="carType" label="车型"/>
            <el-table-column prop="carCaptain" label="车长"/>
            <el-table-column prop="carTemperature" label="车厢温度"/>
            <el-table-column prop="cargoWeight" label="货物重量"/>
            <el-table-column prop="cargoMeasurement" label="货物体积"/>
            <el-table-column prop="cargoType" label="货物类型"/>
            <el-table-column prop="cargoPieces" label="货物件数"/>
            <el-table-column prop="carNumber" label="车辆牌照号"/>
            <el-table-column prop="insuranceNo" label="保险单号"/>
            <el-table-column prop="insuranceCorpName" label="保险公司名称"/>
            <el-table-column prop="driverName" label="驾驶员名称"/>
            <el-table-column prop="driverIdno" label="驾驶员身份证号"/>
            <el-table-column prop="driverPhone" label="驾驶员手机号"/>
            <el-table-column prop="ip" label="IP地址"/>
            <el-table-column prop="mac" label="MAC地址"/>
            <el-table-column prop="locateInfos" label="GPS定位信息集"/>
            <el-table-column prop="minRequiredPickUpPeriod" label="最短要求提货时间" :formatter="dateFormat"/>
            <el-table-column prop="maxRequiredPickUpPeriod" label="最长要求提货时间" :formatter="dateFormat"/>
            <el-table-column prop="minEstimatedArrivalPeriod" label="最短预计到达时间" :formatter="dateFormat"/>
            <el-table-column prop="maxEstimatedArrivalPeriod" label="最长预计到达时间" :formatter="dateFormat"/>
            <el-table-column prop="acceptorType" label="收货主体类型"/>
            <el-table-column prop="acceptorName" label="收货主体名称"/>
            <el-table-column prop="acceptorIdno" label="收获主题证件号"/>
            <el-table-column prop="acceptorContact" label="收货联系人"/>
            <el-table-column prop="acceptorContactPhone" label="收货联系人电话"/>
            <el-table-column prop="transportState" label="运输状态"/>
            <el-table-column prop="pickUpTime" label="发货时间" :formatter="dateFormat"/>
            <el-table-column prop="arrivalTime" label="收货时间" :formatter="dateFormat"/>
            <el-table-column prop="remark" label="备注"/>
            <el-table-column prop="createTime" label="创建时间" :formatter="dateFormat"/>
          </el-table>
             <el-pagination id="page" background  @current-change="currentChange"
                :page-size="parseTable.pageSize"
                layout="total, prev, pager, next, jumper"
                :total="parseTable.total"
              >
              </el-pagination>
          <div slot="footer" class="dialog-footer">
            <el-button type="info" @click="innerVisible=false">关闭预览</el-button>
            <el-button type="primary" @click="onUpload()">确认上传</el-button>
          </div>
        </el-dialog>

        <el-form :model="uploadForm" :label-position="'left'" :rules="rules" ref="uploadForm">
          <el-form-item label="请选择类型">
            <el-select placeholder="类型" v-model="type" clearable>
                <el-option v-for="item in orderType"  :label="item.name" :value="item.sort" :key="item.sort"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传作业单" prop="uploadJson.fileName">
            <el-upload
              class="upload-demo"
              ref="upload"
              action=""
              :on-preview="handlePreview"
              :on-remove="handleRemove"
              :on-success="handleSuccess"
              :on-error="handleError"
              :on-change="handleChange"
              :file-list="uploadForm.fileList"
              :limit="uploadConfig.limit"
              :accept="uploadConfig.accept"
              :data="uploadForm.uploadJson"
              :auto-upload="false">
              <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
              <div slot="tip" class="el-upload__tip">只能上传xls/xlsx文件。</div>
            </el-upload>
          </el-form-item>
        </el-form>
      </el-dialog>
  </div>
</template>
<script>
import XLSX from "xlsx"
import objectUtils from '@/util/objectUtils'
export default{
  data(){
    return{
      uploadConfig:{
        limit:1,            //最大允许上传个数
        accept:'.xls,.xlsx',//接受上传的文件类型
      },
      dialogFormVisible:false,
      //预览弹框
      innerVisible:false,
      //预览数据表key，用于重新渲染数据
      tableDataKey:false,
      //上传参数
      uploadForm:{
        fileList:[],
        sessionId:this.$getCookie("sessionId"),
        uploadUrl:process.env.VUE_APP_UPLOAD_URL,
        uploadJson:{
           type: process.env.VUE_APP_UPLOAD_TYPE,
           fileName:"",
           json:"",
           queue:process.env.VUE_APP_UPLOAD_PAYABLE_SALARY_QUENCE_NAME,
        }
      },
      type:"",
      orderType:[],
      TypeDiction: {
        code: "orderType",
      },
      rules: {
         ["uploadJson.fileName"]: [{ required: true, message: '请上传作业单', trigger: 'change' }]
      },
      parseTable:{
        tableData:[],
        pageNo:1,
        pageSize:10,
        total:0
      },
      //列名
      columnNames:{
        thirdParty:0,
        taskorType:1,
        taskorThirdParty:2,
        taskorIdno:3,
        name:4,
        type:5,
        content:6,
        clientBillNo:7,
        expectedEndTime:8,
        amount:9,
        taskTrackNos:10,
        deliveryContact:11,
        deliveryContactPhone:12,
        deliveryProvince:13,
        deliveryCity:14,
        deliveryArea:15,
        deliveryAddress:16,
        receivingProvince:17,
        receivingCity:18,
        receivingArea:19,
        receivingAddress:20,
        routingCode:21,
        transportModes:22,
        carType:23,
        carCaptain:24,
        carTemperature:25,
        cargoWeight:26,
        cargoMeasurement:27,
        cargoType:28,
        cargoPieces:29,
        carNumber:30,
        insuranceNo:31,
        insuranceCorpName:32,
        driverName:33,
        driverIdno:34,
        driverPhone:35,
        ip:36,
        mac:37,
        locateInfos:38,
        minRequiredPickUpPeriod:39,
        maxRequiredPickUpPeriod:40,
        minEstimatedArrivalPeriod:41,
        maxEstimatedArrivalPeriod:42,
        acceptorType:43,
        acceptorName:44,
        acceptorIdno:45,
        acceptorContact:46,
        acceptorContactPhone:47,
        transportState:48,
        pickUpTime:49,
        arrivalTime:50,
        remark:51,
        createTime:52
      }
    }
  },
  created: function () {

  },
  mounted:function (){
    this.findDictionariesOrderTypeList(this.TypeDiction);
  },
  methods: {
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0";
    },
    handlePreview(file){
      console.log('handlePreview,',file)
    },
    handleRemove(file, fileList){
      console.log('handleRemove',file,fileList)
    },
    handleSuccess(res, file){
      console.log('handleSuccess',res,file)
      this.$message.success('上传成功');
    },
    handleError(err,file,fileList){
      this.resetUpload();
      this.$message.error('上传失败,原因：'+err.message);
    },
    handleChange(file, fileList){
      console.log('handlechange',file,fileList);
      //重置
      if(this.type==''){
        this.$message.warning("请选择类型！")
        this.$refs.upload.clearFiles();
        return
      }
      this.parseTable={tableData:[],pageNo:1,pageSize:10,total:0};
      //加载成功，则处理
      if(file.status == 'ready'){
        this.uploadForm.uploadJson.fileName = file.name;
        const fileReader = new FileReader();
        //自定义加载文件方法
        if(this.type == 'G545435437'){
          fileReader.onload = this.logistics
        }
        fileReader.readAsBinaryString(file.raw);
      }
    },
    //重置上传数据
    resetUpload(){
      this.parseTable={tableData:[],pageNo:1,pageSize:10,total:0};
      this.uploadForm.uploadJson.fileName = "";
      this.$refs.upload.clearFiles();
    },
    logistics(e){
      const data = e.target.result;
      const workbook = XLSX.read(data, {
        type: "binary",
        cellDates: true
      });
      const wsname = workbook.SheetNames[0]; //取第一张表
      const excelTableData = workbook.Sheets[wsname];
      console.log(excelTableData)
      let exl = XLSX.utils.sheet_to_json(excelTableData,{header:1}); // 生成json表格内容,{header:1},表示第一行不为标题

      //列名
      let titles = exl[1];

      let index = 0;
      let columnNames = this.columnNames;
      let tableData = this.parseTable.tableData;
      for(let j = 1;j<exl.length;j++){
        let thirdParty = exl[j][columnNames.thirdParty]
        if(objectUtils.isEmpty(thirdParty)){
          this.$message.error("不要填空数据！")
          this.$refs.upload.clearFiles();
          return;
        }

        //创建新对象
        tableData[index] = {}
        //计薪时间天数+1，原因:excel转换后，丢失几秒，XLSX插件固有bug
        // calcSalaryTime.setDate(calcSalaryTime.getDate()+1);
        tableData[index].thirdParty = thirdParty;
        tableData[index].taskorType = exl[j][columnNames.taskorType];
        tableData[index].taskorThirdParty = exl[j][columnNames.taskorThirdParty];
        tableData[index].taskorIdno = exl[j][columnNames.taskorIdno];
        tableData[index].name = exl[j][columnNames.name];
        tableData[index].type = exl[j][columnNames.type];
        tableData[index].content = exl[j][columnNames.content];
        tableData[index].clientBillNo = exl[j][columnNames.clientBillNo];
        tableData[index].expectedEndTime = exl[j][columnNames.expectedEndTime];
        tableData[index].amount = exl[j][columnNames.amount];
        tableData[index].taskTrackNos = exl[j][columnNames.taskTrackNos];
        tableData[index].deliveryContact = exl[j][columnNames.deliveryContact];
        tableData[index].deliveryContactPhone = exl[j][columnNames.deliveryContactPhone];
        tableData[index].deliveryProvince = exl[j][columnNames.deliveryProvince];
        tableData[index].deliveryCity = exl[j][columnNames.deliveryCity];
        tableData[index].deliveryArea = exl[j][columnNames.deliveryArea];
        tableData[index].deliveryAddress = exl[j][columnNames.deliveryAddress];
        tableData[index].receivingProvince = exl[j][columnNames.receivingProvince];
        tableData[index].receivingCity = exl[j][columnNames.receivingCity];
        tableData[index].receivingArea = exl[j][columnNames.receivingArea];
        tableData[index].receivingAddress = exl[j][columnNames.receivingAddress];
        tableData[index].routingCode = exl[j][columnNames.routingCode];
        tableData[index].transportModes = exl[j][columnNames.transportModes];
        tableData[index].carType = exl[j][columnNames.carType];
        tableData[index].carCaptain = exl[j][columnNames.carCaptain];
        tableData[index].carTemperature = exl[j][columnNames.carTemperature];
        tableData[index].cargoWeight = exl[j][columnNames.cargoWeight];
        tableData[index].cargoMeasurement = exl[j][columnNames.cargoMeasurement];
        tableData[index].cargoType = exl[j][columnNames.cargoType];
        tableData[index].cargoPieces = exl[j][columnNames.cargoPieces];
        tableData[index].carNumber = exl[j][columnNames.carNumber];
        tableData[index].insuranceNo = exl[j][columnNames.insuranceNo];
        tableData[index].insuranceCorpName = exl[j][columnNames.insuranceCorpName];
        tableData[index].driverName = exl[j][columnNames.driverName];
        tableData[index].driverIdno = exl[j][columnNames.driverIdno];
        tableData[index].driverPhone = exl[j][columnNames.driverPhone];
        tableData[index].ip = exl[j][columnNames.ip];
        tableData[index].mac = exl[j][columnNames.mac];
        tableData[index].locateInfos = exl[j][columnNames.locateInfos];
        tableData[index].minRequiredPickUpPeriod = exl[j][columnNames.minRequiredPickUpPeriod];
        tableData[index].maxRequiredPickUpPeriod = exl[j][columnNames.maxRequiredPickUpPeriod];
        tableData[index].minEstimatedArrivalPeriod = exl[j][columnNames.minEstimatedArrivalPeriod];
        tableData[index].maxEstimatedArrivalPeriod = exl[j][columnNames.maxEstimatedArrivalPeriod];
        tableData[index].acceptorType = exl[j][columnNames.acceptorType];
        tableData[index].acceptorName = exl[j][columnNames.acceptorName];
        tableData[index].acceptorIdno = exl[j][columnNames.acceptorIdno];
        tableData[index].acceptorContact = exl[j][columnNames.acceptorContact];
        tableData[index].acceptorContactPhone = exl[j][columnNames.acceptorContactPhone];
        tableData[index].transportState = exl[j][columnNames.transportState];
        tableData[index].pickUpTime = exl[j][columnNames.pickUpTime];
        tableData[index].arrivalTime = exl[j][columnNames.arrivalTime];
        tableData[index].remark = exl[j][columnNames.remark];
        tableData[index].createTime = exl[j][columnNames.createTime];
        index += 1;
      }
      console.log(this.parseTable.tableData);
      //打开预览
      this.parseTable.total = tableData.length;
      this.innerVisible = true;
      this.tableDataKey = !this.tableDataKey
      this.$refs.upload.clearFiles();
      this.dialogFormVisible = false
    },
    //格式化时间
    dateFormat(row, column, cellValue) {
      return cellValue ? this.$moment(cellValue).format("yyyy-MM-DD") : '';
    },
    openUpload(){
      this.dialogFormVisible = true
    },
    currentChange(pageNo){
      this.parseTable.pageNo = pageNo;
    },
    async findDictionariesOrderTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.orderType = response.data.data;
		},
    //确认上传
    onUpload(){
      this.innerVisible=false;
      //上传TODO
      this.$emit("uploadData", this.parseTable.tableData);
    }
  }
}
</script>

<style scoped>
#uploadTask{
padding-left: 20px;
}
#page {
float: right;
margin-top: 15px;
}
.dialog-footer{
text-align: right;
margin-top: 20px;
}
</style>