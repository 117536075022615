<template>
  <div id="addUser">
    <el-row type="flex" justify="center">
      <el-col :xs="24" :sm="16" :md="12">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="100px"
          class="demo-ruleForm"
        >
          <el-form-item label="账户" prop="account">
            <el-input
              placeholder="请输入登录账号"
              v-model="ruleForm.account"
              onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="salted">
            <el-input
              placeholder="请输入登录密码"
              v-model="ruleForm.salted"
              onkeyup="value=value.replace(/[^\w\.\/]/ig,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属部门" prop="depatments">
            <!-- <el-select v-model="depatment" placeholder="请选择" @change="changerole()">
			  <el-option
			    v-for="item in depss"
			    :key="item.id"
			    :label="item.name"
			    :value="item.id"
			  ></el-option>
			</el-select> -->

            <el-cascader
              placeholder="请选择所属部门"
              @change="updateDepatment"
              v-model="ruleForm.depatments"
              :options="groupTree"
              :props="{ multiple: true, checkStrictly: true }"
              clearable
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="所属角色" prop="selectedRoles">
            <!-- <el-select v-model="depatment1" multiple @change="xx" placeholder="请选择">
			  <el-option 
			    v-for="item in depss1"
			    :key="item.role"
			    :label="item.name"
			    :value="item.role">
			  </el-option>
			  
			</el-select> -->

            <el-cascader
              placeholder="请选择所属部门下的角色"
              v-model="ruleForm.selectedRoles"
              :options="roleTree"
              :props="{ multiple: true, checkStrictly: true }"
              clearable
            ></el-cascader>
          </el-form-item>

          <el-form-item label="邮箱" prop="email">
            <el-input
              type="email"
              v-model="ruleForm.email"
              placeholder="请输入邮箱"
            ></el-input>
          </el-form-item>
          <el-form-item label="身份证" prop="idno">
            <el-input
              v-model="ruleForm.idno"
              placeholder="请输入身份证号码"
            ></el-input>
          </el-form-item>

          <!--          <el-form-item label="用户名" prop="name">
            <el-input v-model="ruleForm.name"></el-input>
          </el-form-item> -->

          <el-form-item label="性别" prop="gender">
            <el-select v-model="ruleForm.gender" placeholder="请选择性别">
              <el-option
                v-for="item in optionsgender"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系方式" prop="mobile">
            <el-input
              v-model="ruleForm.mobile"
              placeholder="请输入联系方式"
            ></el-input>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </div>
</template>
<script>
//查询所有部门树
import { groupTree } from "@/service/moudules/platformApi";
//根据所选部门找对应的角色
import { groupRoleTree } from "@/service/moudules/platformApi";
//新增用户
import { addSysUser } from "@/service/moudules/platformApi";
export default {
  props: ["add"],
  mounted: function () {
    this.loadCgy();
    this.getGroupTree();
    // this.getDep();
  },
  data() {
    var checkPhone = (rule, value, callback) => {
      const phoneReg = /^1[3|4|5|7|8|9][0-9]{9}$/;
      if (!value) {
        return callback(new Error("电话号码不能为空"));
      }
      setTimeout(() => {
        // Number.isInteger是es6验证数字是否为整数的方法,但是我实际用的时候输入的数字总是识别成字符串
        // 所以我就在前面加了一个+实现隐式转换

        if (!Number.isInteger(+value)) {
          callback(new Error("请输入数字值"));
        } else {
          if (phoneReg.test(value)) {
            callback();
          } else {
            // callback(new Error("电话号码格式不正确"));
          }
        }
      }, 100);
    };

    var checkEmail = (rule, value, callback) => {
      const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/;
      if (!value) {
        return callback(new Error("邮箱不能为空"));
      }
      setTimeout(() => {
        if (mailReg.test(value)) {
          callback();
        } else {
          // callback(new Error("请输入正确的邮箱格式"));
        }
      }, 100);
    };

    return {
      flag: false,
      optionsgender: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "0",
          label: "女",
        },
      ],
      ruleForm: {
        id: "",
        account: "",
        name: "",
        gender: "",
        mobile: "",
        gname: "",
        rname: "",
        salted: "",
        idno: "",
        email: "",
        depatments: [],
        selectedRoles: [],
      },
      id: "",
      depatment: "",
      depatment1: "",
      depatments: [],
      depss: [],
      depss1: [],
      orole: "",
      oguid: "",
      selectedRoles: [],
      groupTree: [],
      roleTree: [],
      rules: {
        depatments: [
          {
            required: true,
            message: "所属部门不能为空",
            trigger: "blur",
          },
        ],
        selectedRoles: [
          {
            required: true,
            message: "所属角色不能为空",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "账号不能为空",
            trigger: "blur",
          },
        ],
        salted: [
          {
            required: true,
            message: "密码不能为空",
            trigger: "blur",
          },
        ],
        // name: [
        //   {
        //     required: true,
        //     message: "用户名不能为空",
        //     trigger: "blur",
        //   },
        // ],

        // idno: [
        //   {
        //     required: true,
        //     message: "身份证不能为空",
        //     trigger: "blur",
        //   },
        // ],
        // mobile: [
        //   {
        //     required: true,
        //     validator: checkPhone,
        //     trigger: "blur",
        //   },
        // ],
        // email: [
        //   {
        //     validator: checkEmail,
        //     trigger: "blur",
        //   },
        // ],
      },
    };
  },
  methods: {
    loadCgy: function () {
      var temp = this.$route.params;
      this.ruleForm.id = temp.id;
      this.ruleForm.account = temp.account;
      this.ruleForm.name = temp.name;
      this.ruleForm.gender = temp.gender;
      this.ruleForm.mobile = temp.mobile;
      this.ruleForm.gname = temp.gname;
      this.ruleForm.rname = temp.rname;
      this.orole = temp.rid;
      this.oguid = temp.guid;
    },
    async addSysUser() {
      var that = this;
      var params = {
        sessionId: this.$getCookie("sessionId"),
        user: {
          id: that.$route.params.id,
          account: that.ruleForm.account,
          name: that.ruleForm.account,
          gender: that.ruleForm.gender,
          mobile: that.ruleForm.mobile,
          salted: that.ruleForm.salted,
          idno: that.ruleForm.idno,
          email: that.ruleForm.email,
        },
        groups: that.ruleForm.depatments,
        roles: that.ruleForm.selectedRoles,
      };
      let response = await this.api.platformApi.addSysUser(params);
      if (response.data.data != true) {
        that.$message({
          message: response.data.msg,
          type: "warning",
        });
        this.resetForm("ruleForm");
        return;
      } else {
        that.$message({
          message: "新增成功",
          type: "success",
        });
        this.$emit("success");
      }
    },
    async submitForm(formName) {
      var that = this;
      this.flag = false;
      await this.$refs.ruleForm.validate((valid) => {
        if(valid){
          if (that.ruleForm.depatments.length === 0) {
            that.$message.error("请选择部门");
            return false;
          } else if (that.ruleForm.selectedRoles.length === 0) {
            that.$message.error("请选择角色");
            return false;
          } else if (that.ruleForm.account== undefined || that.ruleForm.account.length === 0  ) {
            that.$message.error("请填写账户");
            return false;
          } else if (that.ruleForm.salted==undefined || that.ruleForm.salted.length === 0  ) {
            that.$message.error("请填写密码");
            return false;
          } else {
            this.flag = true;
            that.addSysUser();
          }
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    // getDep: function () {
    //   let data = {};
    //   axios
    //     .get("/sysUser/findgroup", {})
    //     .then((response) => {
    //       this.depss = response.data.data;
    //     })
    //     .catch(function (error) {
    //       this.$message.error("部门显示错误");
    //     });
    // },
    async findGroupTree(userId) {
      var params = {};

      let response = await this.api.platformApi.groupTree(params);
      if (response.data.code == 200) {
        this.groupTree = response.data.data;
      } else {
        this.$message.error(response.data.msg);
      }
    },
    async findGroupRoleTree(userId) {
      var params = {
        checkedGroups: this.ruleForm.depatments,
      };
      let response = await this.api.platformApi.groupRoleTree(params);
      // console.log(response)
      if (response.data.code == 200) {
        // console.log(response.data.data)
        this.roleTree = response.data.data;
      } else {
        this.$message.error(response.data.msg);
      }
    },

    //查询全部的部门树
    getGroupTree() {
      this.findGroupTree();
    },
    changeRoleTree() {
      this.findGroupRoleTree();
    },

    // changerole() {
    //   let data = {};
    //   var params = {
    //     group: this.depatment,
    //   };
    //   axios
    //     .get("/sysUser/findgrouprole", params)
    //     .then((response) => {
    //       this.depss1 = response.data.data;
    //     })
    //     .catch(function (error) {
    //       this.$message.error("角色显示错误");
    //     });
    // },
    setCookie: function (cname, cvalue, exdays) {
      let d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      let expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    getCookie: function (cname) {
      let name = cname + "=";
      let ca = document.cookie.split(";");
      // console.log("获取cookie,现在循环")
      for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        // console.log(c)
        while (c.charAt(0) === " ") c = c.substring(1);
        if (c.indexOf(name) !== -1) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },
    clearCookie: function (cname) {
      this.setCookie(cname, "", -1);
    },
    updateDepatment(old) {
      this.changeRoleTree();
    },
  },
};
</script>
<style scoped>
#addUser .el-cascader {
  width: 100%;
}

#addUser .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

#addUser .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

#addUser .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

#addUser .avatar {
  width: 178px;
  height: 178px;
  display: block;
}

#addUser .el-select {
  width: 100%;
}
</style>
