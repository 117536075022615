"use strict";

import Vue from 'vue';
import axios from "axios";
import cookieUtils from '@/util/cookieUtils'
import objectUtils from '@/util/objectUtils'

let baseURL;
 
if (process.env.NODE_ENV === "development") {
  baseURL = process.env.baseURL || process.env.apiUrl || "http://47.113.200.136:8080/adminApi"
}else {
  baseURL = process.env.baseURL || process.env.apiUrl 
}


// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = process.env.baseURL || process.env.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
let config = {
  baseURL: process.env.VUE_APP_BASE_URL
  //timeout: 60 * 1000, // Timeout
  //withCredentials: true, // Check cross-site Access-Control
};

const _axios = axios.create(config);

_axios.interceptors.request.use(
  function(config) {
    let sessionId = cookieUtils.getCookie("sessionId");
    //没有就初始化
    if(config.data == undefined){config.data = {}}
    //body存储sessionId
    if(!objectUtils.isEmpty(sessionId) && config.data != undefined){
      Vue.set(config.data,'sessionId',sessionId)
    }    
    // Do something before request is sent
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function(response) {
    // Do something with response data
	
	// console.log(111)
	console.log(response)
    return response;
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

Plugin.install = function(Vue, options) {
  Vue.axios = _axios;
  window.axios = _axios;
  Object.defineProperties(Vue.prototype, {
    axios: {
      get() {
        return _axios;
      }
    },
    $axios: {
      get() {
        return _axios;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;
