<template>
  <div id="quotaReviewList" v-loading.fullscreen.lock="fullscreenLoading">
    <div id="basic">
      <el-row>
        <el-col :span="1.5">
          订单新增
        </el-col>
      </el-row>
      <el-form
        :model="addForm"
        :inline="true"
        style="float:left;margin-top: 15px;"
      >
        <el-form-item label="项目名称">
          <el-col :span="22">
            <el-input v-model="addForm.name" style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="路线名称">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.routingName" style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="客户单号">
          <el-col :span="22">
            <el-input v-model="addForm.clientBillNo" style="width:250px"></el-input>
          </el-col>
        </el-form-item>
    </el-form>
  </div>

  <el-divider></el-divider>

  <div id="consignment">
      <el-row>
        <el-col :span="1.5">
          托运费信息
        </el-col>
      </el-row>
      <el-form
        :model="addForm" 
        :inline="true"
        style="float:left;margin-top: 15px;"
      >
        <el-form-item label="名称" required style="margin-left:30px">
          <el-col :span="22">
            <el-input v-model="addForm.orderorName" style="width:250px" disabled></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="电话" required>
          <el-col :span="22">
            <el-input v-model="addForm.mobile" style="width:250px" disabled></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="证件号码" required>
          <el-col :span="22">
            <el-input v-model="addForm.buyerIdno" style="width:250px" disabled></el-input>
          </el-col>
        </el-form-item>
    </el-form>
  </div>

  <el-divider></el-divider>

<div id="main">
    <div id="shipper">
      <el-row>
        <el-col :span="1.5">
          发货方信息
        </el-col>
      </el-row>
      <el-form
        :model="addForm"
        :inline="true" :rules="addFormRules" ref="deliverForm"
        style="float:left;margin-top: 15px"
      >
        <el-form-item label="名称" prop="contentData.deliveryContact">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.deliveryContact" style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="电话" prop="contentData.deliveryContactPhone">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.deliveryContactPhone" show-word-limit style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item prop="deliveryAddress" label="发货地址" style="margin-left: -30px;">
          <el-col :span="22">
            <el-cascader v-model="addForm.deliveryAddress" :options="areaOptions" clearable :props="areaProps" placeholder="请选择发货地址" style="width:560px"></el-cascader>
          </el-col>
        </el-form-item>
        <el-form-item label="详细地址" prop="contentData.deliveryDetailAddress" style="margin-left: -30px;">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.deliveryDetailAddress" style="width:560px"></el-input>
          </el-col>
        </el-form-item>
    </el-form>
  </div>
  
  <div id="receive">
      <el-row>
        <el-col :span="1.5">
          收货方信息
        </el-col>
      </el-row>
      <el-form
        :model="addForm"
        :inline="true" :rules="addFormRules" ref="acceptorForm"
        style="float:left;margin-top: 15px"
      >
        <el-form-item label="名称" prop="contentData.acceptorContact">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.acceptorContact" style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="电话" prop="contentData.acceptorContactPhone">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.acceptorContactPhone" show-word-limit  style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item prop="receivingAddress" label="收货地址" style="margin-left: -30px;">
          <el-col :span="22">
            <el-cascader v-model="addForm.receivingAddress" :options="areaOptions" ref="test" clearable :props="areaProps" placeholder="请选择收货地址" style="width:560px"></el-cascader>
          </el-col>
        </el-form-item>
        <el-form-item label="详细地址" prop="contentData.receivingDetailAddress" style="margin-left: -30px;">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.receivingDetailAddress" style="width:560px"></el-input>
          </el-col>
        </el-form-item>
    </el-form>
  </div>
</div>

<el-divider></el-divider>

<div id="goods">
    <el-row>
      <el-col :span="1.5">
        货物信息
      </el-col>
    </el-row>
    <el-form
      :model="addForm"
      :inline="true" :rules="addFormRules" ref="cargoForm"
      style="float:left;margin-top: 15px"
    >
      <el-row>
        <el-form-item label="名称" prop="contentData.cargoName" style="margin-left:-290px">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.cargoName" style="width:250px"></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="类型" prop="contentData.cargoType">
          <el-col :span="22">
            <el-select placeholder="类型" v-model="addForm.contentData.cargoType" clearable style="width:250px">
              <el-option v-for="item in cargoType" :label="item.name" :value="item.sort" :key="item.sort"></el-option>
            </el-select>
          </el-col>
        </el-form-item>
      </el-row>
      <el-row>
        <el-form-item label="重量" prop="contentData.cargoWeight" style="margin-left:20px">
          <el-col :span="22">
            <el-input v-model="addForm.contentData.cargoWeight" onkeyup='this.value=this.value.replace(/[^0-9.]/g,"")' style="width:250px"><template slot="append"> 吨</template></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="体积" >
          <el-col :span="22">
            <el-input v-model="addForm.contentData.cargoMeasurement" onkeyup='this.value=this.value.replace(/[^0-9.]/g,"")' style="width:250px"><template slot="append"> 方</template></el-input>
          </el-col>
        </el-form-item>
        <el-form-item label="件数" >
          <el-col :span="22">
            <el-input v-model="addForm.contentData.cargoPieces" onkeyup="this.value=this.value.replace(/\D/g,'')" onafterpaste="this.value=this.value.replace(/\D/g,'')" style="width:250px"><template slot="append"> 件</template></el-input>
          </el-col>
        </el-form-item>
      </el-row>
  </el-form>
</div>

<el-divider></el-divider>

<div>
    <el-row>
      <el-col :span="1.5">
        备注
      </el-col>
    </el-row>
    <el-row style="margin-top:20px">
      <el-input
          resize="none"
          type="textarea"
          placeholder="请输入"
          v-model="addForm.remark"
          maxlength="150"
          show-word-limit
        >
        </el-input>
    </el-row>
</div>

<el-divider></el-divider>

<div style="border:1px solid #DCDFE6">
  <el-table
        id="table"
        :data="carrierList"
        :header-cell-style="tableHeader"
        :cell-style="tableCell"
        border
        style="position: relative;"
      >
        <el-table-column
          prop="name"
          label="承运人名称"
          show-overflow-tooltip
          align="center"
        ></el-table-column>
        <el-table-column
          prop="phone"
          label="承运人电话"
          show-overflow-tooltip
          align="center"
        ></el-table-column>

        <el-table-column
          prop="driverName"
          label="司机姓名"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="driverPhone"
          label="司机电话"
          show-overflow-tooltip
          align="center"
        >
      </el-table-column>

        <el-table-column
          prop="carNumber"
          label="车牌号码"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="bankName"
          label="银行开户名"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="bankcard"
          label="银行卡号"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="bankNo"
          label="开户银行"
          show-overflow-tooltip
          align="center"
        />

        <el-table-column
          prop="freightFee"
          label="总运费(元)"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.freightFee , 100)}}</span>
          </template> -->
        </el-table-column>

        <el-table-column
          prop="carrierFee"
          label="承运人运费(元)"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.carrierFee , 100)}}</span>
          </template> -->
        </el-table-column>

        <el-table-column
          prop="serviceFee"
          label="服务费(元)"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.serviceFee , 100)}}</span>
          </template> -->
      </el-table-column>

        <el-table-column
          prop="paymentPlan.fuelCost"
          label="油卡付"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.fuelCost , 100)}}</span>
          </template> -->
      </el-table-column>

        <el-table-column
          prop="paymentPlan.freightPrepaid"
          label="预付"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.freightPrepaid , 100)}}</span>
          </template> -->
      </el-table-column>

        <el-table-column
          prop="paymentPlan.freightCollect"
          label="到付"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.freightCollect , 100)}}</span>
          </template> -->
      </el-table-column>

        <el-table-column
          prop="paymentPlan.accountPayable"
          label="回单付"
          show-overflow-tooltip
          align="center"
        >
        <!-- <template slot-scope="scope">
            <span>{{accDiv(scope.row.paymentPlan.accountPayable , 100)}}</span>
          </template> -->
      </el-table-column>

      <el-table-column label="操作" fixed="right" >
            <template slot-scope="scope">
              <el-button type="text" size="small" @click="del(scope.$index)" style="color: #FFAC1D">删除</el-button>
              <el-button type="text" size="small" @click="eidt(scope.row,scope.$index)"  style="color: #FFAC1D">修改</el-button>
            </template>
          </el-table-column>

      </el-table>
      <el-button type="text" style="width:1000px;background-color: #DCDFE6;position: relative;" @click="dialogVisible=true">添加承运人</el-button>
</div>

    <el-row :gutter="20" style="margin-top:10px">
        <el-col :span="2" :offset="10"><div><el-button @click="$router.push(`/orderList`)">取消</el-button></div></el-col>
        <el-col :span="2"><div><el-button type="primary" @click="save()">确定</el-button></div></el-col>
    </el-row>

    <el-dialog
      title="添加" :visible.sync="dialogVisible" width="45%" top="4vh" @close="close()">
        <div>
          <el-form :model="carriers" :rules="carriersRule" ref="carriers" :inline="true" style="margin-top:20px">
            <div class="textTips">
              <el-divider class="el-divider"></el-divider>
            </div>
              <el-row>
                <el-col :span="3">
                  <span style="font-size:16px;font-weight:500;margin-bottom: 10px;">承运方信息</span>
                </el-col>
              </el-row>
              <el-row>
                <el-form-item label="承运人名称" style="margin-left:-100px">
                  <el-col :span="22">
                    <el-input v-model="carriers.name" style="width:250px" size="small"></el-input>
                  </el-col>
                </el-form-item>
                <el-form-item label="承运人电话">
                    <el-col :span="22">
                      <el-input v-model="carriers.phone" show-word-limit  style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="司机名称" style="margin-left:-100px" prop="driverName">
                    <el-col :span="22">
                      <el-input v-model="carriers.driverName" style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="司机电话" prop="driverPhone">
                    <el-col :span="22">
                      <el-input v-model="carriers.driverPhone" style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="车牌号码" style="margin-left:-440px" prop="carNumber">
                    <el-col :span="22">
                      <el-input v-model="carriers.carNumber" style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="银行开户名" style="margin-left:-120px">
                    <el-col :span="22">
                      <el-input v-model="carriers.bankName" style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
                <el-form-item label="银行卡号">
                    <el-col :span="22">
                      <el-input v-model="carriers.bankcard" style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <el-row>
                <el-form-item label="开户银行" style="margin-left:-440px">
                    <el-col :span="22">
                      <el-input v-model="carriers.bankNo" style="width:250px" size="small"></el-input>
                    </el-col>
                </el-form-item>
              </el-row>
              <div class="textTips">
                <el-divider class="el-divider"></el-divider>
              </div>
                <el-row>
                  <el-col :span="3">
                    <span style="font-size:16px;font-weight:500;margin-bottom: 10px;">运费信息</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="总运费" style="margin-left:-440px">
                      <el-col :span="22">
                        <el-input v-model="carriers.freightFee" style="width:200px" size="small" disabled><template slot="append"> 元</template></el-input>
                      </el-col>
                  </el-form-item>
              </el-row>
              <el-row>
                  <el-form-item label="承运人运费" style="margin-left:-90px;margin-right: 20px;">
                      <el-col :span="22">
                        <el-input v-model="carriers.carrierFee" style="width:200px" size="small" onkeyup='this.value=this.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "")' @input="acc()"><template slot="append"> 元</template></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item class="item" style="margin-right: 20px;">
                    <el-button type="text" @click="show()">{{label}}</el-button>
                  </el-form-item>
                  <el-form-item label="服务费">
                    <el-col :span="24">
                      <el-input v-model="carriers.serviceFee" style="width:200px" size="small" onkeyup='this.value=this.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "")' @input="acc()"><template slot="append"> 元</template></el-input>
                    </el-col>
                  </el-form-item>
              </el-row>
              <div v-if="showHidden" style="background:#EBEEF5">
                <el-row>
                  <el-col :span="2">
                    <span style="font-size:13px;font-weight:400;margin-bottom: 10px;line-height: 30px;">支付明细</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-form-item label="油卡付">
                      <el-col :span="22">
                        <el-input v-model="carriers.paymentPlan.fuelCost" placeholder="请输入" style="width:140px;vertical-align:middle" size="small" onkeyup='this.value=this.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "")'><template slot="append"> 元</template></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="预付">
                      <el-col :span="22">
                        <el-input v-model="carriers.paymentPlan.freightPrepaid" placeholder="请输入" style="width:140px;vertical-align:middle" size="small" onkeyup='this.value=this.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "")'><template slot="append"> 元</template></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="到付">
                      <el-col :span="22">
                        <el-input v-model="carriers.paymentPlan.freightCollect" placeholder="请输入" style="width:140px;vertical-align:middle" size="small" onkeyup='this.value=this.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "")'><template slot="append"> 元</template></el-input>
                      </el-col>
                  </el-form-item>
                  <el-form-item label="回单付">
                      <el-col :span="22">
                        <el-input v-model="carriers.paymentPlan.accountPayable" placeholder="请输入" style="width:140px;vertical-align:middle" size="small" onkeyup='this.value=this.value.replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "")'><template slot="append"> 元</template></el-input>
                      </el-col>
                  </el-form-item>
              </el-row>
              </div>
          </el-form>
        </div>
            <div class="textTips">
              <el-divider class="el-divider"></el-divider>
            </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCarrier()">确 定</el-button>
      </span>
    </el-dialog>

  </div>
</template>
<script>

import validateUtils from "@/util/validateUtils";

export default {
  data() {
    return {
      id:null,
      addForm:{contentData:{}},
      typeDiction: {
        code: "cargoType",
      },
      cargoType:[],
      carrierList:[],
      dialogVisible:false,
      carriers:{paymentPlan:{}},
      showHidden:false,
      label:'支付计划∨',
      areaOptions: [],
      index:undefined,
      areaProps: {
        label: "area",
        value: "area",
      },
      profileInfo:{},
      fullscreenLoading: false,
      addFormRules: {
        'contentData.deliveryContact':[
          { required: true, message: '发货方名称不能为空', trigger: 'change'}
        ],
        'contentData.deliveryContactPhone':[
          { required: true, message: '发货方电话不能为空', trigger: 'change'}
        ],
        deliveryAddress:[
          {required: true, message: '发货地址不能为空', trigger: 'change'},
          {validator: this.validateDelivery,trigger:'blur'}
        ],
        'contentData.deliveryDetailAddress':[
          { required: true, message: '发货方详细地址不能为空', trigger: 'change'}
        ],
        'contentData.acceptorContact':[
          { required: true, message: '收货方名称不能为空', trigger: 'change'}
        ],
        'contentData.acceptorContactPhone':[
          { required: true, message: '收货方电话不能为空', trigger: 'change'}
        ],
        receivingAddress:[
          {required: true, message: '收货地址不能为空', trigger: 'change'},
          {validator: this.validateReceiving,trigger:'blur'}
        ],
        'contentData.receivingDetailAddress':[
          { required: true, message: '收货方详细地址不能为空', trigger: 'change'}
        ],
        'contentData.cargoName':[
          { required: true, message: '货物名称不能为空', trigger: 'change'}
        ],
        'contentData.cargoType':[
          { required: true, message: '货物类型不能为空', trigger: 'change'}
        ],
        'contentData.cargoWeight':[
          { required: true, message: '货物重量不能为空', trigger: 'change'}
        ]
      },
      carriersRule: {
        driverName:[
          { required: true, message: '司机名称不能为空', trigger: 'change'}
        ],
        driverPhone:[
          { required: true, message: '司机电话不能为空', trigger: 'change'}
        ],
        carNumber:[
          { required: true, message: '车牌号码不能为空', trigger: 'change'}
        ]
      }
    };
  },
  watch:{
    areaOptions(newVal,oldVal){
      this.addForm.deliveryAddress[0] = this.addForm.contentData.deliveryProvince;
      this.addForm.deliveryAddress[1] = this.addForm.contentData.deliveryCity;
      this.addForm.deliveryAddress[2] = this.addForm.contentData.deliveryArea;
      this.addForm.receivingAddress[0] = this.addForm.contentData.receivingProvince
      this.addForm.receivingAddress[1] = this.addForm.contentData.receivingCity
      this.addForm.receivingAddress[2] = this.addForm.contentData.receivingArea
      this.$forceUpdate();
    }
  },
  // 实例创建完成后调用
   created() {
    this.api.platformApi.getTreeDicArea({}).then((res) => {
      res = res.data;
      if (res.code == 200) {
        this.areaOptions = res.data;
      } else {
        this.$message.error("级联地址加载失败，刷新重试！");
      }
    }); 
    this.findDictionariesCargoTypeList(this.typeDiction);
    this.id = this.$route.query.id 
    if(this.$route.query.id == null){
      this.addForm = {contentData:{}}
      this.api.orderApi.profileInfo({}).then(res=>{
        res = res.data
        this.addForm.mobile = res.data.mobile
        this.addForm.buyerIdno = res.data.buyerIdno
        this.addForm.orderorName = res.data.orderorName
      }) 
      this.addForm.deliveryAddress = [null,null,null]
      this.addForm.receivingAddress = [null,null,null]
    }else{
      let params = {id:this.id}
      this.api.orderApi.findOrderDetail(params).then(res=>{
        res = res.data
        this.addForm = res.data
        this.addForm.name = this.addForm.proofName
        this.addForm.contentData = this.addForm.contentData ? JSON.parse(this.addForm.contentData):{}
        this.carrierList = this.addForm.contentData.carriers
        this.addForm.mobile = this.addForm.buyerPhone
        for (let index = 0; index < this.carrierList.length; index++) {
          this.carrierList[index].freightFee = this.accDiv(this.carrierList[index].freightFee,100)
          this.carrierList[index].carrierFee = this.accDiv(this.carrierList[index].carrierFee,100)
          this.carrierList[index].serviceFee = this.accDiv(this.carrierList[index].serviceFee,100)
          this.carrierList[index].paymentPlan.fuelCost = this.accDiv(this.carrierList[index].paymentPlan.fuelCost,100)
          this.carrierList[index].paymentPlan.freightPrepaid = this.accDiv(this.carrierList[index].paymentPlan.freightPrepaid,100)
          this.carrierList[index].paymentPlan.freightCollect = this.accDiv(this.carrierList[index].paymentPlan.freightCollect,100)
          this.carrierList[index].paymentPlan.accountPayable = this.accDiv(this.carrierList[index].paymentPlan.accountPayable,100)
        }
        this.addForm.amount = 0
        this.addForm.deliveryAddress = [this.addForm.contentData.deliveryProvince,this.addForm.contentData.deliveryCity,this.addForm.contentData.deliveryArea]
        this.addForm.receivingAddress = [this.addForm.contentData.receivingProvince,this.addForm.contentData.receivingCity,this.addForm.contentData.receivingArea]
      })   
    }

  },
  methods: {
    async findDictionariesCargoTypeList(param) {
				let response = await this.api.platformApi.findDictionariesItemList(param);
				console.log("数据字典", response);
				this.cargoType = response.data.data;
		},
    tableHeader(column) {
      return "height:36px;background-color:#fafafa;color:#333333;padding:0";
    },
    tableCell(column) {
      return "height:40px;background-color:#fafafa;color:#000000;padding:0;overflow: visible;";
    },
    validateDelivery(rule, value, callback){
      if(this.addForm.deliveryAddress[0]==undefined || this.addForm.deliveryAddress[1]==undefined || this.addForm.deliveryAddress[2]==undefined){
        callback(new Error("发货地址不能为空"));
      }
      callback();
    },
    validateReceiving(rule, value, callback){
      if(this.addForm.receivingAddress[0]==undefined || this.addForm.receivingAddress[1]==undefined || this.addForm.receivingAddress[2]==undefined){
        callback(new Error("发货地址不能为空"));
      }
      callback();
    },
    show(){
      this.showHidden = !this.showHidden
      if(this.showHidden){
        this.label = '支付计划∧'
      }else{
        this.label = '支付计划∨'
      }
    },
    addCarrier(){
      this.$refs['carriers'].validate((valid) => {
          if (valid) {
            let carriers = JSON.parse(JSON.stringify(this.carriers));
            // carriers.freightFee = this.accMul(carriers.freightFee,100)
            // carriers.carrierFee = this.accMul(carriers.carrierFee,100)
            // carriers.serviceFee = this.accMul(carriers.serviceFee,100)
            // carriers.paymentPlan.fuelCost = this.accMul(carriers.paymentPlan.fuelCost,100)
            // carriers.paymentPlan.freightPrepaid = this.accMul(carriers.paymentPlan.freightPrepaid,100)
            // carriers.paymentPlan.freightCollect = this.accMul(carriers.paymentPlan.freightCollect,100)
            // carriers.paymentPlan.accountPayable = this.accMul(carriers.paymentPlan.accountPayable,100)
            this.carriers = carriers;
            // this.addForm.amount = this.accAdd(this.addForm.amount,this.carriers.freightFee)
            if(this.index!=undefined){
              this.$set(this.carrierList, this.index, this.carriers)
              this.index=undefined
            }else{
              // this.addForm.amount = this.accAdd(this.addForm.amount,this.carriers.freightFee)
              this.$set(this.carrierList, this.carrierList.length, this.carriers)
            }
            this.carriers = {paymentPlan:{}}
            this.dialogVisible = false
          } else {
            return false;
          }
        });
    },
    del(index){
      this.carrierList.splice(index, 1);
    },
    eidt(row,index){
      this.carriers = row
      this.index = index
      // this.carriers.freightFee = this.accDiv(this.carriers.freightFee,100)
      // this.carriers.carrierFee = this.accDiv(this.carriers.carrierFee,100)
      // this.carriers.serviceFee = this.accDiv(this.carriers.serviceFee,100)
      // this.carriers.paymentPlan.fuelCost = this.accDiv(this.carriers.paymentPlan.fuelCost,100)
      // this.carriers.paymentPlan.freightPrepaid = this.accDiv(this.carriers.paymentPlan.freightPrepaid,100)
      // this.carriers.paymentPlan.freightCollect = this.accDiv(this.carriers.paymentPlan.freightCollect,100)
      // this.carriers.paymentPlan.accountPayable = this.accDiv(this.carriers.paymentPlan.accountPayable,100)
      this.dialogVisible = true
    },
    close(){
      this.$refs['carriers'].resetFields()
    },
    save(){
      this.$refs['deliverForm'].validate((valid) => {
          if (valid) {
            this.$refs['acceptorForm'].validate((valid) => {
              if (valid) {
                this.$refs['cargoForm'].validate((valid) => {
                  if (valid) {
                    this.addForm.contentData.deliveryProvince = this.addForm.deliveryAddress[0]
                    this.addForm.contentData.deliveryCity = this.addForm.deliveryAddress[1]
                    this.addForm.contentData.deliveryArea = this.addForm.deliveryAddress[2]
                    this.addForm.contentData.receivingProvince = this.addForm.receivingAddress[0]
                    this.addForm.contentData.receivingCity = this.addForm.receivingAddress[1]
                    this.addForm.contentData.receivingArea = this.addForm.receivingAddress[2]
                    for (let index = 0; index < this.carrierList.length; index++) {
                      this.carrierList[index].freightFee = this.accMul(this.carrierList[index].freightFee,100)
                      this.carrierList[index].carrierFee = this.accMul(this.carrierList[index].carrierFee,100)
                      this.carrierList[index].serviceFee = this.accMul(this.carrierList[index].serviceFee,100)
                      this.carrierList[index].paymentPlan.fuelCost = this.accMul(this.carrierList[index].paymentPlan.fuelCost,100)
                      this.carrierList[index].paymentPlan.freightPrepaid = this.accMul(this.carrierList[index].paymentPlan.freightPrepaid,100)
                      this.carrierList[index].paymentPlan.freightCollect = this.accMul(this.carrierList[index].paymentPlan.freightCollect,100)
                      this.carrierList[index].paymentPlan.accountPayable = this.accMul(this.carrierList[index].paymentPlan.accountPayable,100)
                      this.addForm.amount = this.accAdd(this.addForm.amount,this.carrierList[index].freightFee)
                    }
                    this.addForm.contentData.carriers = this.carrierList;
                    // this.addForm.contentData.carriers.paymentPlan = JSON.stringify(this.addForm.contentData.carriers.paymentPlan);
                    // this.addForm.contentData = JSON.stringify(this.addForm.contentData);
                    this.addForm.type = 'G545435437'
                    let param = null
                    if(this.id==null){//添加
                      param = JSON.parse(JSON.stringify(this.addForm))
                      param.contentData.carriers.paymentPlan =JSON.stringify(param.contentData.carriers.paymentPlan)
                      param.contentData = JSON.stringify(param.contentData);
                      this.api.orderApi.createOrder(param).then(res=>{
                        res = res.data
                        if(res.code == 200){
                          this.fullscreenLoading = true;
                            setTimeout(() => {
                              this.fullscreenLoading = false;
                              this.$router.push({path: '/orderList'})
                          }, 3000);
                        }else{
                          this.$message.error("添加失败");
                        }
                      })
                    }else{//修改
                      param = JSON.parse(JSON.stringify(this.addForm))
                      param.contentData.carriers.paymentPlan =JSON.stringify(param.contentData.carriers.paymentPlan)
                      param.contentData = JSON.stringify(param.contentData);
                      this.api.orderApi.updateOrder(param).then(res=>{
                        res = res.data
                        if(res.code == 200){
                          this.fullscreenLoading = true;
                            setTimeout(() => {
                              this.fullscreenLoading = false;
                              this.$router.push({path: '/orderList'})
                          }, 3000);
                        }else{
                            this.$message.error("修改失败");
                        }
                      })
                    }
                  } else {
                    return false;
                  }
                });
              } else {
                return false;
              }
            });
          } else {
            return false;
          }
        });
    },
    acc(){//求和
      this.carriers.carrierFee = this.carriers.carrierFee ? this.carriers.carrierFee.toString().replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "") : this.carriers.carrierFee
      this.carriers.serviceFee = this.carriers.serviceFee ? this.carriers.serviceFee.toString().replace(/[^\d.]/g, "").replace(/\.{2,}/g, ".").replace(".", "$#$").replace(/\./g, "").replace("$#$", ".").replace(/^(\-)*(\d+)\.(\d\d).*$/, "$1$2.$3").replace(/^\./g, "") : this.carriers.serviceFee
      this.carriers.freightFee = this.accAdd(this.carriers.carrierFee,this.carriers.serviceFee) 
      this.$forceUpdate();
    },
    accAdd(arg1, arg2) {
      if(arg1==undefined && arg2==undefined){
        return null
      }
      if(arg1==undefined){
        return arg2
      }
      if(arg2==undefined){
        return arg1
      }
      var r1, r2, m, c;
      try {
        r1 = arg1.toString().split(".")[1].length;
      }
      catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      }
      catch (e) {
        r2 = 0;
      }
      c = Math.abs(r1 - r2);
      m = Math.pow(10, Math.max(r1, r2));
      if (c > 0) {
        var cm = Math.pow(10, c);
        if (r1 > r2) {
          arg1 = Number(arg1.toString().replace(".", ""));
          arg2 = Number(arg2.toString().replace(".", "")) * cm;
        } else {
          arg1 = Number(arg1.toString().replace(".", "")) * cm;
          arg2 = Number(arg2.toString().replace(".", ""));
        }
      } else {
        arg1 = Number(arg1.toString().replace(".", ""));
        arg2 = Number(arg2.toString().replace(".", ""));
      }
      return (arg1 + arg2) / m;
    }
  }
};
</script>

<style scoped>
>>> .el-descriptions-item__cell {
  padding: 2px 5px !important;
}
>>> .el-descriptions-item__label {
  width: 250px;
  text-align: end !important;
}

/*  */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
>>> .avatar-uploader {
  display: inline-block;
  margin-left: 10px;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 18px;
  color: #8c939d;
  width: 130px;
  height: 30px;
  line-height: 30px;
  text-align: center;
}
.avatar {
  width: 130px;
  height: 30px;
  display: block;
}

#quotaReviewList {
  overflow: hidden;
  background-color: white;
  padding: 15px 20px 100px;
}
#basic{
  overflow:hidden
}
#consignment{
  overflow:hidden
}
#main{
  overflow:hidden;
}
#shipper{
  width: 680px;
  overflow:hidden;
  float: left;
}
#receive{
  width: 680px;
  overflow:hidden;
  float: left;
}
#goods{
  overflow:hidden;
}
>>> .item .el-form-item__label{
    padding: 0;
    color: #409EFF;
  }
</style>


